import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./layouts/Layout";
import './i18n'

import {
  adminRoutes,
  publicRoutes,
  shopRoutes,
} from "./constants/routes.components";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Context } from ".";
import AdminLayout from "./layouts/AdminLayout";
import ShopLayout from "./layouts/ShopLayout";
import { Toast, ToastContainer } from "react-bootstrap";
import { fetchGetCurrency } from "./data/models/good/good-api";
import { getCurrentDate } from "./helpers/getCurrentDate";

function App() {
  document.addEventListener("click", function (event) {
    if (
      event.target.tagName.toLowerCase() === "a" ||
      event.target.closest("a")
    ) {
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }
  });

  const { store } = useContext(Context);
  store.refreshLogin();

  useEffect(() => {
    if (!store.currency) {
      fetchGetCurrency(getCurrentDate()).then((response) => {
        store.setCurrency(response.data)
      })
    }

    if (store.language == "kz" || store.language == "ch") {
      document.body.style.fontFamily = 'Segoe UI';
    } else {
      document.body.style.fontFamily = 'Manrope'
    }

  })

  return (
    <>

      <ToastContainer>
        {
          store.toasts.length > 0 && store.toasts.map((toast) => {
            return <Toast key={toast.goodId} onClose={() => store.unSetToast(toast.goodId, toast.type)} autohide delay={5000}>
              <Toast.Header>
                <strong className="me-auto">{toast.title}</strong>
              </Toast.Header>
              <Toast.Body>{toast.text}</Toast.Body>
            </Toast>
          })
        }
      </ToastContainer>

      <BrowserRouter>
        <Routes>
          {/* public pages */}
          <Route path="/" element={<Layout />}>
            {publicRoutes.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Route>

          {/* admin pages */}
          <Route path="/admin/" element={<AdminLayout />}>
            {adminRoutes.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Route>

          {/* shop pages */}
          <Route path="/shop/" element={<ShopLayout />}>
            {shopRoutes.map((route, index) => {
              return (
                <Route key={index} path={route.path} element={route.element} />
              );
            })}
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default observer(App);
