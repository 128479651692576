import React from "react";
import { priceToString } from "../../../helpers/priceToString";

function OrdersList({ report }) {
  return (
    <>
      <div className="report-total">
        <div className="orders-count">
          <img src="/images/report-orders.png" alt="" />
          <div>
            <h5>Заказы</h5>
            <h3>{report.orders.length}</h3>
          </div>
        </div>
        <div className="orders-sum">
          <img src="/images/report-sales.png" alt="" />
          <div>
            <h5>Выручка</h5>
            <h4>{priceToString(report.totalSum)} тг</h4>
            <h4>{priceToString(Math.ceil(report.totalSum*0.97))} тг с уч комм</h4>
          </div>
        </div>
        <div className="orders-track">
          <img src="/images/report-track.png" alt="" />
          <div>
            <h5>Тенденция</h5>
            <h3 className="good">{'+ 67.5 %'}</h3>
          </div>
        </div>
        <div className="orders-popular">
          <img src="/images/report-best.png" alt="" />
          <div>
            <h5>Популярное</h5>
            <h4 className="fw-bold">{report.mostPopularCategory.name}</h4>
          </div>
        </div>
      </div>

      <table className="table table-striped">
        <thead>
          <tr>
            <th style={{ width: 120 }}>Номер заказа</th>
            <th style={{ width: 150 }}>Статус заказа</th>
            <th style={{ width: 120 }}>Вид оплаты</th>
            <th style={{ width: 150 }}>Сумма чека</th>
            <th style={{ width: 150 }}>Сумма с учетом комиссии</th>
            <th style={{ width: 620 }}>Состав заказа</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {report.orders.map((item) => {
            return (
              <tr>
                <td>{item.id}</td>
                <td>{item.status.name}</td>
                <td>{item.paymentType.name}</td>
                <td>
                  {priceToString(
                    item.goods.reduce((acc, obj) => acc + obj.price, 0)
                  )}{" "}
                  тг
                </td>
                <td>
                  {priceToString(
                    Math.ceil(
                      item.goods.reduce((acc, obj) => acc + obj.price * 0.97, 0)
                    )
                  )}{" "}
                  тг
                </td>
                <td>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ width: 120 }}>Артикул</th>
                        <th>Наименование</th>
                        <th style={{ width: 120 }}>Цена</th>
                        <th style={{ width: 120 }}>Цена с учетом комиссии</th>
                      </tr>
                    </thead>
                    <tbody>
                      {item.goods.map((good) => {
                        return (
                          <tr key={good.id}>
                            <td>{good.article}</td>
                            <td>{good.name}</td>
                            <td>{priceToString(good.price)} тг</td>
                            <td>
                              {priceToString(Math.ceil(good.price * 0.97))} тг
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </td>
                <td></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
}

export default OrdersList;
