import { makeAutoObservable } from "mobx";
import { fetchGetMyName } from "../http/auth-api";
import jwt_decode from "jwt-decode";
import { fetchGetCurrency } from "../models/good/good-api";
import { getCurrentDate } from "../../helpers/getCurrentDate";

export default class Store {
  constructor() {
    this._toasts = [];
    this._logged = false;
    this._user = {
      firstName: "",
      secondName: "",
      email: "",
      phone: "",
    };
    if (localStorage.getItem("user")) {
      this._user = JSON.parse(localStorage.getItem("user"));
    }
    this._basket = {
      goods: [],
      amount: 0,
      delivery: {
        type: "pickup",
        price: 0,
        address: {},
      },
      contacts: {
        firstName: "",
        secondName: "",
        email: "",
        phone: "",
      },
      payment: 1,
    };
    this._favourites = {
      goods: [],
      categories: [],
      brands: [],
      partners: [],
    };

    if (localStorage.getItem("favourites")) {
      this._favourites = JSON.parse(localStorage.getItem("favourites"));
    }

    if (localStorage.getItem("basket")) {
      this._basket = JSON.parse(localStorage.getItem("basket"));
    }

    this._currency = null
    this._selectedCurrency = localStorage.getItem('selectedCurrency') || 'kzt';

    this._language = localStorage.getItem('language') || 'ru';

    makeAutoObservable(this);
  }

  get currency() {
    return this._currency
  }

  get selectedCurrency() {
    return this._selectedCurrency
  }

  convertedCurrency(value, amount) {
    amount = amount || 1
    if (!this._currency) {
      return 0;
    }
    const { kzt, rub, cny } = this._currency;
    switch (this._selectedCurrency) {
      case 'kzt':
        return (value * amount) + " ₸";
      case 'usd':
        return (amount * Math.ceil(value * 1.0 / kzt)) + " $";
      case 'rub':
        return (amount * Math.ceil(rub * (value / kzt))) + " ₽";
      case 'cny':
        return (amount * Math.ceil(cny * (value / kzt))) + " ¥";
      default:
        return 0;
    }
  }

  convertedCurrencyOnlyNumber(value, amount) {
    if (!this._currency) {
      return 0;
    }
    const { kzt, rub, cny } = this._currency;
    switch (this._selectedCurrency) {
      case 'kzt':
        return (value * amount);
      case 'usd':
        return (amount * Math.ceil(value / kzt));
      case 'rub':
        return (amount * Math.ceil(rub * (value / kzt)));
      case 'cny':
        return (amount * Math.ceil(cny * (value / kzt)));
      default:
        return 0;
    }
  }

  customRound(number) {
    if (number % 10 >= 1 && number % 10 <= 3) {
      return Math.floor(number / 10) * 10;
    } else if (number % 10 >= 8) {
      return Math.ceil(number / 10) * 10;
    } else {
      return Math.round(number / 10) * 10;
    }
  }

  setSelectedCurrency(value) {
    this._selectedCurrency = value
    localStorage.setItem('selectedCurrency', value)
  }

  setCurrency(currency) {
    this._currency = currency
  }

  get toasts() {
    return this._toasts;
  }

  setToast(toast) {
    if (this._toasts.filter(m => m.goodId == toast.goodId && m.type == toast.type).length > 0) {
      return
    }
    this._toasts.push(toast);
  }

  unSetToast(id, type) {
    this._toasts = this._toasts.filter((m) => !(m.goodId == id && m.type == type));
  }

  refreshLogin() {
    if (localStorage.getItem("token")) {
      fetchGetMyName().then((response) => {
        if (response.status == 200) {
          this._logged = true;
        }
      });
    }
  }

  setLogged(bool) {
    this._logged = bool;
  }

  get logged() {
    return this._logged;
  }

  get user() {
    return this._user;
  }

  userHasRole(roleName) {
    return jwt_decode(localStorage.getItem("token"))[
      "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
    ].includes(roleName);
  }

  isInBasket(good) {
    for (var item of this._basket.goods) {
      if (item.id == good.id && item.shopId == good.shopId) {
        return true;
      }
    }
    return false;
  }

  isNotEmptyBasket() {
    return this._basket && this._basket.amount && this._basket.amount > 0;
  }

  setBasket(good, amount) {
    try {
      if (this.isInBasket(good)) {
        for (var item of this._basket.goods) {
          if (item.id == good.id && item.shopId == good.shopId) {
            item.amount = amount;
            this._basket.amount = this.getBasketAmount();
          }
        }
      } else {
        this._basket.goods.push({
          ...good,
          amount,
        });
        this._basket.amount = this.getBasketAmount();
      }
      localStorage.setItem("basket", JSON.stringify(this._basket));
    } catch (e) {
      console.log(e);
    }
  }

  getBasketAmount() {
    let sum = 0;
    for (var item of this._basket.goods) {
      sum += item.amount;
    }
    return sum;
  }

  getBasketSum() {
    let sum = 0;
    for (var item of this._basket.goods) {
      sum += this.convertedCurrencyOnlyNumber(item.price, item.amount);
    }
    return sum;
  }

  unsetBasket(good) {
    try {
      this._basket.goods = this._basket.goods.filter(
        (m) => !(parseInt(m.id) === good.id && m.shopId === good.shopId)
      );
      this._basket.amount = this.getBasketAmount();
      localStorage.setItem("basket", JSON.stringify(this._basket));
    } catch (e) {
      console.log(e);
    }
  }

  get basket() {
    return this._basket;
  }
  resetBakset() {
    localStorage.removeItem("basket");
    this._basket = {
      goods: [],
      amount: 0,
      delivery: {
        type: "pickup",
        price: 0,
        address: {},
      },
      contacts: {
        firstName: "",
        secondName: "",
        email: "",
        phone: "",
      },
      payment: 1,
    };
  }

  setDelivery(data) {
    this._basket.delivery = data;
    localStorage.setItem("basket", JSON.stringify(this._basket));
  }

  setBaketContacts(data) {
    this._basket.contacts = data;
    localStorage.setItem("basket", JSON.stringify(this._basket));
  }

  setPayment(id) {
    this._basket.payment = id;
    localStorage.setItem("basket", JSON.stringify(this._basket));
  }

  setFavourite(key, item) {
    try {
      this._favourites[key].push(item);
      localStorage.setItem("favourites", JSON.stringify(this._favourites));
    } catch (e) {
      console.log(e);
    }
  }

  unsetFavourite(key, id) {
    try {
      this._favourites[key] = this._favourites[key].filter((m) => m.id != id);
      localStorage.setItem("favourites", JSON.stringify(this._favourites));
    } catch (e) {
      console.log(e);
    }
  }

  inFavourite(key, id) {
    try {
      return this._favourites[key].filter((m) => m.id == id)[0];
    } catch (e) {
      console.log(e);
    }
  }

  get favourites() {
    return this._favourites;
  }

  hasFavourites() {
    return (
      this._favourites.goods.length > 0 &&
      this._favourites.categories.length > 0 &&
      this._favourites.brands.length > 0 &&
      this._favourites.partners.length > 0
    );
  }

  get language() {
    return this._language
  }

  setLanguage(lng) {
    this._language = lng
    localStorage.setItem('language', lng)
  }
}
