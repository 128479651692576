import { getRandomNumber } from "../../../helpers/getRandomNumber";
import { API_URL } from "../../http/axios-init";

export default class Brand {
  constructor(data) {
    if (data) {
      this.id = data.id;
      this.name = data.name;
      this.image = API_URL + data.image;
      this.description = data.description;
    } else {
      this.id = getRandomNumber();
      this.name = "Apple";
      this.image =
        "https://appletrack.com/wp-content/uploads/2020/11/apple-logo-thumby.jpg";
      this.description = "Лучший производитель электроники";
    }
  }
}
