import { $authHost, $host } from "./axios-init";

export const fetchRegistration = async (fd) => {
  try {
    const response = await $host.post("/api/auth/register", fd);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchLogin = async (fd) => {
  try {
    const response = await $host.post("/api/auth/login", fd);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGetMyName = async ()=>{
    try {
        const response = await $authHost.get("/api/auth/getMyName");
        return response;
      } catch (e) {
        return e.response;
      }
}

export const fetchUpdateUserName = async (fd)=>{
  try{
    const response = await $authHost.put('/api/auth/updateUserName',fd)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchGetUserLocations = async ()=>{
  try {
    const response = await $authHost.get('/api/auth/getUserLocations')
    return response
  } catch(e){
    return e.response
  }
}

export const fetchCreateUserLocation = async (fd)=>{
  try {
    const response = await $authHost.post('/api/auth/createLocation',fd)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchUpdateUserLocation = async (fd)=>{
  try {
    const response = await $authHost.put('/api/auth/updateLocation',fd)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchDeleteUserLocation = async (id)=>{
  try {
    const response = await $authHost.delete('/api/auth/deleteLocation?id='+id)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchRoles = async ()=>{
  try{
    const response = await $authHost.get('/api/role')
    return response
  } catch(e){
    return e.response
  }
}

export const fetchRole = async (id)=>{
  try{
    const response = await $authHost.get('/api/role/'+id)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchRoleCreate = async (fd)=>{
  try{
    const response = await $authHost.post('/api/role',fd)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchRoleUpdate = async (fd)=>{
  try{
    const response = await $authHost.put('/api/role',fd)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchRoleDelete = async (id)=>{
  try{
    const response = await $authHost.delete('/api/role/'+id)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchUsers = async (filter)=>{
  try{
    const response = await $authHost.post('/api/user/GetUsers',filter)
    return response
  } catch(e){
    return e.response
  }
}


export const fetchGetUserRoles = async (email)=>{
  try{
    const response = await $authHost.get('/api/User/GetUserRoles?email='+email)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchAddRole = async (fd)=>{
  try{
    const response = await $authHost.post('/api/role/addRole',fd)
    return response
  } catch(e){
    return e.response
  }
}

export const fetchDeleteRole = async (fd)=>{
  try{
    const response = await $authHost.post('/api/role/deleteRole',fd)
    return response
  } catch(e){
    return e.response
  }
}