import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../..";
import { priceToString } from "../../../helpers/priceToString";
import { Button, Dropdown, Spinner } from "react-bootstrap";
import { toJS } from "mobx";
import { fetchCreateOrder } from "../../../data/models/basket-api";
import BasketOrderCompleteModal from "./BasketOrderCompleteModal";

function BasketTotalSticky() {
  const { store } = useContext(Context);
  const [submitText, setSubmitText] = useState("Оформить заказ");

  const getDeliveryTypeName = (type) => {
    if (type === "pickup") {
      return "Самовывоз";
    } else if (type == "delivery-city") {
      return "Доставка по городу";
    } else {
      return "Доставка по Казахстану";
    }
  };

  const getDeliverySum = (sum) => {
    if (sum === 0) {
      return "Бесплатно";
    } else if (sum === -1) {
      return "Договорная";
    } else {
      return priceToString(store.convertedCurrency(sum));
    }
  };

  const getPaymentName = (id) => {
    if (id === 1) {
      return "Каспи";
    } else if (id === 2) {
      return "Наличными";
    }
  };

  const [updateComplete, setUpdateComplete] = useState({
    updateComplete: (id) => { }
  })

  const handleMakeOrder = () => {
    if (store.basket.contacts.phone === "") {
      alert("Пожалуйста, укажите контактные данные и нажмите кнопку \"Подтвердить\"!")
      return;
    }
    setSubmitText(
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Оформление...</span>
      </Spinner>
    );

    fetchCreateOrder(toJS(store.basket)).then((response) => {
      if (response.status === 200) {
        setSubmitText("Заказ оформлен");
        updateComplete.updateComplete(response.data)
      }
    });
  };


  return (
    <div className="basket-total-sticky">
      <BasketOrderCompleteModal setUpdate={setUpdateComplete} />
      <h4 className="p-3">Оформление заказа</h4>
      <ul className="list-group">
        {store.basket.contacts.firstName.length > 0 && (
          <li className="list-group-item">
            <span>Клиент:</span> {store.basket.contacts.firstName} (
            {store.basket.contacts.phone})
          </li>
        )}

        <li className="list-group-item">
          <span>Кол-во товаров:</span> {store.basket.amount} шт
        </li>
        <li className="list-group-item">
          <h5>Итого:</h5>

          <div className="d-flex justify-content-between">
            <p className="fw-bold">Сумма:</p>
            <p>{priceToString(store.getBasketSum())} {store.selectedCurrency === 'kzt' ? ' ₸' : ' $'}</p>
          </div>

          <div className="d-flex justify-content-between">
            <p className="fw-bold">
              {getDeliveryTypeName(store.basket.delivery.type)}:
            </p>
            <p>{getDeliverySum(store.basket.delivery.price)}</p>
          </div>

          <div className="line"></div>

          <div className="d-flex justify-content-between">
            <p className="fw-bold">Итого к оплате:</p>
            <div className="text-end">
              <p className="fw-bold h6">
                {priceToString(
                  store.getBasketSum() +
                  (store.basket.delivery.price == -1
                    ? 0
                    : store.basket.delivery.price)
                )}{" "}
                {store.selectedCurrency == 'kzt' ? ' ₸' : ' $'}
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <p className="fw-bold">Оплата:</p>
            <p>{getPaymentName(store.basket.payment)}</p>
          </div>

          <Button
            className="mt-3 w-100 mb-2"
            onClick={() => handleMakeOrder()}
            disabled={submitText != "Оформить заказ"}
          >
            {submitText}
          </Button>
        </li>
      </ul>
    </div>
  );
}

export default observer(BasketTotalSticky);
