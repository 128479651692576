import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Thumbs } from "swiper";
import { fetchGoodImages } from "../../../../data/models/good/good-api";
import { API_URL } from "../../../../data/http/axios-init";

const SwiperImages = ({goodId,setUpdate}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  const [images, setImages] = useState([]);

  useEffect(()=>{
    updateImages(goodId)
    setUpdate((prev)=>({...prev,updateImages}))
  },[])

  const updateImages = (id)=>{
 
    fetchGoodImages(id).then((response) => {
      if (response.status == 200) {
        setImages(response.data);
      }
    });
  }

  return (
    <div className="good-image-swiper">
      <div className="no-mobile">
        <Swiper
          modules={[Thumbs]}
          watchSlidesProgress
          onSwiper={setThumbsSwiper}
          slidesPerView={4}
          spaceBetween={10}
          className={"thumb-slider"}
          direction={"vertical"}
        >
          {images.length > 0 &&
            images.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <img src={API_URL + item.path} />
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>

      <Swiper
        modules={[Thumbs]}
        thumbs={{ swiper: thumbsSwiper }}
        className={"main-slider"}
      >
        {images.length > 0 &&
          images.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <img src={API_URL + item.path} />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
}

export default SwiperImages;
