import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function MobileMenuCatalog() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="menu-li" onClick={()=>setShow((prev)=>(!prev))}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="bi bi-grid"
          viewBox="0 0 16 16"
        >
          <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z" />
        </svg>
        <p>{t("catalog")}</p>
      </div>

      <Offcanvas show={show} placement="bottom" className="h-100" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("catalog")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pb-5">
        <div className="catalog-grid-container">
              <div className="div1">
                <Link to="/catalog/category/1" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("smartphones")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div2">
                <Link to="/catalog/category/1" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("headphones")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div3">
                <Link to="/catalog/category/1" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("laptops")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div4">
                <Link to="/catalog/category/1" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("tablets")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div5">
                <Link to="/catalog/category/1" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("cables_and_charges")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div6">
                <Link to="/catalog/category/1" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("cases_and_safety_glasses")}</h3>
                  </div>
                </Link>
              </div>
            </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MobileMenuCatalog;
