// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslation from './locales/en.json';
import ruTranslation from './locales/ru.json';
import kzTranslation from './locales/kz.json';
import chTranslation from './locales/ch.json';

// the translations
const resources = {
  en: { translation: enTranslation },
  ru: { translation: ruTranslation },
  kz: { translation: kzTranslation },
  ch: { translation: chTranslation },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('language') || 'ru',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
