import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fetchGetUserRoles } from "../../../data/http/auth-api";
import UserRoleItem from "../../../components/admin/users/UserRoleItem";

function UserRoles({ user }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [list, setList] = useState([]);


  const updateList = ()=>{
    fetchGetUserRoles(user.email).then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  }

  useEffect(() => {
    updateList()
  }, []);

  return (
    <>
      <Dropdown.Item onClick={handleShow}>Роли пользователя</Dropdown.Item>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Роли пользователя</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-group">
            {list.length > 0 &&
              list.map((item) => {
                return (
                  <li
                    className="list-group-item d-flex align-items-center justify-content-between"
                    key={item.roleId}
                  >
                    <UserRoleItem item={item} id={user.id} updateList={updateList}/>
                  </li>
                );
              })}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default UserRoles;
