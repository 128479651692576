import React, { useState } from 'react'
import { fetchGetCurrency } from '../../../data/models/good/good-api'
import { getCurrentDate } from '../../../helpers/getCurrentDate'

function GoodSeriesPriceItem({ attrs }) {
    const [price, setPrice] = useState({
        kzt: '',
        usa: ''
    })
    const calculate = async (value, c) => {

        var curs = await fetchGetCurrency(getCurrentDate())
        curs = curs.data
        if (c == 'usa') {
            setPrice({
                usa: value,
                kzt: curs.kzt * value
            })
        } else if (c == "kzt") {
            setPrice({
                usa: Math.ceil(value / curs.kzt),
                kzt: value
            })
        }
    }


    return (
        <div className='d-flex' style={{gap:20}}>
            <input type="text" className='form-control' style={{maxWidth:130}} name="priceKZT"  placeholder='KZT' value={price.kzt} onChange={(e) => {
                calculate(+e.target.value, 'kzt')
            }} memoryId={attrs.memoryId} colorId={attrs.colorId} addPropId={attrs.addPropId} />
            <input type="text" className='form-control' style={{maxWidth:130}} name="price" placeholder='USD' value={price.usa} onChange={(e) => {
                calculate(+e.target.value, 'usa')
            }} memoryId={attrs.memoryId} colorId={attrs.colorId} addPropId={attrs.addPropId} />
        </div>
    )
}

export default GoodSeriesPriceItem