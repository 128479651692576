import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import { Swiper, SwiperSlide } from 'swiper/react';
import GoodShortItem from './GoodShortItem';

function SwiperGoodList({
    title,
    list
}) {

    return (
        <div className='swiper-good-list'>
            <h4 className='section-title'>{title}</h4>
            <div className='no-mobile'>
            <Swiper
                spaceBetween={10}
                slidesPerView={5}
            >
                {
                    list && list.length > 0 && list.map((item, index) => {
                        return <SwiperSlide key={index}>
                            <GoodShortItem item={item} />
                        </SwiperSlide>
                    })
                }
            </Swiper>
            </div>
            <div className='no-desktop'>
            <Swiper
                spaceBetween={10}
                slidesPerView={2}
            >
                {
                    list && list.length > 0 && list.map((item, index) => {
                        return <SwiperSlide key={index}>
                            <GoodShortItem item={item} />
                        </SwiperSlide>
                    })
                }
                </Swiper>
            </div>
        </div>
    )
}

export default SwiperGoodList