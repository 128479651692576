import React, {
  Fragment,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  fetchGetGoodByProperties,
  fetchGoodSeriesAdditionalProperties,
  fetchGoodSeriesColors,
  fetchGoodSeriesMemories,
} from "../../../../data/models/good/good-api";

const ModelLinks = ({ updateGood, goodId, show }) => {
  const [colors, setColors] = useState([]);
  const [memories, setMemories] = useState([]);
  const [additionalProperties, setAdditionalProperties] = useState([]);

  const [currentGoodProps, setCurrentGoodProps] = useState({
    goodId: goodId,
    memoryId: 0,
    colorId: 0,
    additionalPropertyId: 0,
  });

  const updateModelSeriesInfo = (id) => {
    fetchGoodSeriesColors(id).then((response) => {
      if (response.status == 200) {
        setColors(response.data);
        if (response.data.length > 0) {
          setCurrentGoodProps((prev) => ({
            ...prev,
            colorId: response.data.filter((m) => m.active)[0].id,
          }));
        }
      }
    });

    fetchGoodSeriesMemories(id).then((response) => {
      if (response.status == 200) {
        setMemories(response.data);
        if (response.data.length > 0) {
          setCurrentGoodProps((prev) => ({
            ...prev,
            memoryId: response.data.filter((m) => m.active)[0].id,
          }));
        }
      }
    });

    fetchGoodSeriesAdditionalProperties(id).then((response) => {
      if (response.status == 200) {
        setAdditionalProperties(response.data);
        if (response.data.length > 0) {
          setCurrentGoodProps((prev) => ({
            ...prev,
            additionalPropertyId: response.data.filter((m) => m.active)[0].id,
          }));
        }
      }
    });
  };

  const linkToGood = (id, type) => {
    fetchGetGoodByProperties({
      ...currentGoodProps,
      [type]: id,
    }).then((response) => {
      if (response.status == 200) {
        updateModelSeriesInfo(response.data);
        updateGood(response.data);
      }
    });
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Simple tooltip
    </Tooltip>
  );

  useEffect(() => {
    updateModelSeriesInfo(goodId);
  }, []);

  return (
    <>
      {colors.length > 0 && (
        <div className="colors">
          <h5>Цвета</h5>
          <div className="d-flex">
            {colors.map((item, index) => {
              return (
                <OverlayTrigger
                  key={index}
                  placement="bottom"
                  delay={{ show: 250, hide: 200 }}
                  overlay={renderTooltip}
                >
                  {item.active ? (
                    <>
                      <div
                        key={index}
                        className={
                          "color-item " + (item.active ? "active" : "")
                        }
                      >
                        <div
                          className="color-item-inner"
                          style={{ backgroundColor: item.hex }}
                        ></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <a
                        onClick={() => {
                          linkToGood(item.id, "colorId");
                        }}
                      >
                        <div
                          key={index}
                          className={
                            "color-item " + (item.active ? "active" : "")
                          }
                        >
                          <div
                            className="color-item-inner"
                            style={{ backgroundColor: item.hex }}
                          ></div>
                        </div>
                      </a>
                    </>
                  )}
                </OverlayTrigger>
              );
            })}
          </div>
        </div>
      )}


      {show && memories.length > 0 && (
        <div className="memories">
          <h5>Память</h5>
          <div className="d-flex">
            {memories.map((item, index) => {
              return (
                <Fragment key={index}>
                  {item.active ? (
                    <div
                      key={index}
                      className={"memory-item " + (item.active ? "active" : "")}
                    >
                      <h4>{item.value}</h4>
                    </div>
                  ) : (
                    <a
                      onClick={() => {
                        linkToGood(item.id, "memoryId");
                      }}
                    >
                      <div
                        key={index}
                        className={
                          "memory-item " + (item.active ? "active" : "")
                        }
                      >
                        <h4>{item.value}</h4>
                      </div>
                    </a>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}

      {show && additionalProperties.length > 0 && (
        <div className="additional-property-types">
          <h5>Дополнительно</h5>
          <div className="d-flex">
            {additionalProperties.map((item, index) => {
              return (
                <Fragment key={index}>
                  {item.active ? (
                    <div
                      key={index}
                      className={
                        "additional-property-item " +
                        (item.active ? "active" : "")
                      }
                    >
                      <h4>{item.value}</h4>
                    </div>
                  ) : (
                    <a
                      onClick={() => {
                        linkToGood(item.id, "additionalPropertyId");
                      }}
                    >
                      <div
                        key={index}
                        className={
                          "additional-property-item " +
                          (item.active ? "active" : "")
                        }
                      >
                        <h4>{item.value}</h4>
                      </div>
                    </a>
                  )}
                </Fragment>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ModelLinks;
