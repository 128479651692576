import React from "react";
import { Container } from "react-bootstrap";

function AboutCompany() {
  return (
    <Container className="mt-5">
      <div className="row">
        <div className="col-md-5">
          <img src="/images/logo-green.png" width={120} alt="" />
          <h1 className="fw-bold mt-3">AZATA </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat,
            ipsum molestias. Corrupti, accusantium molestiae rerum, pariatur
            quod a nam adipisci quisquam eum beatae optio! Et id officiis autem
            minus adipisci? Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sunt et dolores sed at dicta cumque pariatur veritatis!
            Repellendus commodi, iusto doloremque possimus quis laboriosam rerum
            accusamus odit asperiores, non aliquam!
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat,
            ipsum molestias. Corrupti, accusantium molestiae rerum, pariatur
            quod a nam adipisci quisquam eum beatae optio! Et id officiis autem
            minus adipisci? Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sunt et dolores sed at dicta cumque pariatur veritatis!
            Repellendus commodi, iusto doloremque possimus quis laboriosam rerum
            accusamus odit asperiores, non aliquam!
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Repellat,
            ipsum molestias. Corrupti, accusantium molestiae rerum, pariatur
            quod a nam adipisci quisquam eum beatae optio! Et id officiis autem
            minus adipisci? Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Sunt et dolores sed at dicta cumque pariatur veritatis!
            Repellendus commodi, iusto doloremque possimus quis laboriosam rerum
            accusamus odit asperiores, non aliquam!
          </p>
        </div>
        <div className="col-md-7 ps-4 mt-5 pt-5">
          <img src="http://www.smallbizdaily.com/wp-content/uploads/2014/12/178446676.jpg" width={'100%'} alt="" />
        </div>
      </div>
    </Container>
  );
}

export default AboutCompany;
