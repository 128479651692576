import React from "react";
import { Container } from "react-bootstrap";
import { priceToString } from "../../../helpers/priceToString";
import { useTranslation } from 'react-i18next';

function GiftCards() {
  const { t } = useTranslation();

  const card_with_sum = [
    {
      amount: 10000,
      img: "",
    },
    {
      amount: 20000,
      img: "",
    },
    {
      amount: 50000,
      img: "",
    },
    {
      amount: 100000,
      img: "",
    },
  ];

  const card_with_percent = [
    {
      amount: 20,
      img: "",
    },
    {
      amount: 30,
      img: "",
    },
    {
      amount: 50,
      img: "",
    },
  ];

  return (
    <Container className="pt-4" id="giftcards">
      <h3 className="section-title">{t('giftcards_title')}</h3>
      <h1>
        {t('giftcards_main_title')}
      </h1>
      <img src={'/images/giftcard-banner.jpg'} className="banner" alt="" />
      <p className="my-4">
        {t('giftcards_description')}
      </p>
      <h5 className="my-5">{t('giftcards_whatis')}</h5>
      <div className="row">
        <div className="col-md-3">
          <p>
            {t('giftcards_universal')}
          </p>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-7">
          <div className="row">
            {card_with_sum.map((item, index) => {
              return (
                <div className="col-md-6 m-0 p-1" key={index}>
                  <div className="giftcard-item">
                    <img src={item.img} width={"100%"} alt="" />
                    <h4>
                      {t('giftcards_card')}{" "}
                      <span>{priceToString(item.amount)}</span> тг
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <p className="my-5">
        {t('giftcards_additional')}
      </p>
      <div className="row">
        {card_with_percent.map((item, index) => {
          return (
            <div className="col-md-3 m-0 p-1" key={index}>
              <div className="giftcard-item">
                <img src={item.img} width={"100%"} alt="" />
                <h4>
                  {t('giftcards_discount')} <span>{item.amount}</span>% {t('giftcards_one_purchase')}
                </h4>
              </div>
            </div>
          );
        })}
      </div>

      <h5 className="my-5">{t('giftcards_why_popular')}</h5>

      <ul>
        <li>
          <span>{t('giftcards_individuality')}</span>: {t('giftcards_individuality_description')}
        </li>
        <li>
          <span>{t('giftcards_convenience')}</span>: {t('giftcards_convenience_description')}
        </li>
        <li>
          <span>{t('giftcards_wide_selection')}</span>: {t('giftcards_wide_selection_description')}
        </li>
        <li>
          <span>{t('giftcards_perfect_gift')}</span>: {t('giftcards_perfect_gift_description')}
        </li>
      </ul>

      <p className="fw-bold my-5">
        {t('giftcards_make_gifts_unforgettable')} <br /> {t('giftcards_give_joy')}
      </p>
    </Container>
  );
}

export default GiftCards;
