import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import GoodShort from "../../../data/models/good/good";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";
import { priceToString } from "../../../helpers/priceToString";
import { fetchGood } from "../../../data/models/good/good-api";
import { toJS } from "mobx";
import { useTranslation } from 'react-i18next';

function BasketGoodItem({ item }) {
  const [good, setGood] = useState(null);
  const [amount, setAmount] = useState(item.amount);
  const { store } = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {
    fetchGood(item.id).then((response) => {
      if (response.status == 200) {
        setGood({ ...new GoodShort(response.data), price: item.price, shopId: item.shopId })
      }
    })
  }, []);

  const handleAmountChange = (x) => {
    if (x == 0) {
      return;
    }
    setAmount(x);
    store.setBasket(item, x);
  };

  return (
    <>
      {good && (
        <li className="list-group-item">
          <div className="d-flex align-items-center" style={{ gap: 20 }}>
            <img src={good.image} alt="" />
            <div style={{ maxWidth: 230 }}>
              <p className="mb-0 text-muted">{t("article")}: {good.article}</p>
              <p className="h6 fw-bold">{good.name}</p>
            </div>
          </div>

          <div className="d-flex align-items-center" style={{ gap: 50 }}>
            <div className="basket-good-counter">
              <button
                onClick={() => {
                  handleAmountChange(amount - 1);
                }}
              >
                -
              </button>
              <input
                type="number"
                className="form-control"
                min="1"
                autoComplete="off"
                onChange={(e) => {
                  handleAmountChange(+e.target.value);
                }}
                value={amount}
              />
              <button
                onClick={() => {
                  handleAmountChange(amount + 1);
                }}
              >
                +
              </button>

              <button
                onClick={() => {
                  store.unsetBasket(good)
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-trash3"
                  viewBox="0 0 16 16"
                >
                  <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
              </button>
            </div>
            <div className="basket-good-price">
              {
                amount > 1 && <p><span>{priceToString(store.convertedCurrency(good.price, amount))}</span> x {amount} шт</p>
              }
              <h5>{priceToString(store.convertedCurrency(item.price, amount))}</h5>
            </div>
          </div>
        </li>
      )}
    </>
  );
}

export default observer(BasketGoodItem);
