import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Context } from "../..";
import { fetchGetMyShopInfo } from "../../data/models/partner/partner-api";
import { Dropdown } from "react-bootstrap";

function ShopHeader() {
  const [shop, setShop] = useState(null);
  const { store } = useContext(Context);

  useEffect(() => {
    fetchGetMyShopInfo().then((response) => {
      if (response.status == 200) {
        setShop(response.data);
      }
    });
  }, []);

  return (
    <header>
      <div className="d-flex justify-content-between align-items-center">
        <Link to="/" className="d-flex align-items-center">
          <h6 className="fw-bold mb-0">
            <img src={'/images/logo.png'} width={40} className="me-2" alt="" />
            Мой магазин
            </h6>
        </Link>
        <ul className="d-flex m-0 p-0 list-unstyled" style={{ gap: 20 }}>
          <li className="d-flex align-items-center">
            <Link to="">Помощь</Link>
          </li>
          <li className="d-flex align-items-center">
            <Link to="">Тех поддержка</Link>
          </li>
          <li className="d-flex align-items-center">
            <Link to="">{shop && <>{shop.name}</>}</Link>
          </li>
          <li className="border-start ps-4 ms-2">
            <Dropdown>
              <Dropdown.Toggle variant="success" id="dropdown-login">
                {store.user.firstName} {store.user.secondName}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">Сменить пароль</Dropdown.Item>
                <Dropdown.Item href="#/action-2">Выйти</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </header>
  );
}

export default ShopHeader;
