import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { PRIVACY } from "../../../constants/routes";

function PolicyOfMarketplace() {
  return (
    <Container className="pt-4" id="policy-of-marketplace">
      <h1 className="section-title mb-5">Политика маркетплейса</h1>

      <section>
        <h2>Общие правила и условия</h2>
        <h3>Регистрация аккаунта</h3>
        <p>
          Для использования большинства функций нашего маркетплейса вам
          потребуется зарегистрировать аккаунт. Мы рекомендуем предоставлять
          достоверную информацию при регистрации.
        </p>

        <h3>Конфиденциальность данных</h3>
        <p>
          Мы обеспечиваем конфиденциальность ваших данных и не передаем их
          третьим лицам без вашего согласия. Подробности о том, как мы
          обрабатываем ваши данные, содержатся в нашей{" "}
          <Link to={"/" + PRIVACY}>Политике конфиденциальности</Link>.
        </p>

        <h3>Запрещенные товары</h3>
        <p>
          На Azata.kz запрещено продавать товары, которые нарушают законы или
          правила. Мы оставляем за собой право удалить объявления о продаже
          запрещенных товаров и приостановить аккаунты нарушителей.
        </p>

        <h3>Поддержка и разрешение споров</h3>
        <p>
          Мы предоставляем механизмы для разрешения споров между покупателями и
          продавцами. Если у вас возникли проблемы с заказом, обратитесь в нашу
          службу поддержки для получения помощи.
        </p>
      </section>

      <section>
        <h2>Политика продаж</h2>
        <h3>Описание товаров</h3>
        <p>
          Продавцы обязаны предоставлять точное и подробное описание товаров,
          включая их состояние, характеристики и цену. Ложное или недостоверное
          описание может привести к негативным последствиям.
        </p>
        <h3>Цены и скидки</h3>
        <p>
          Мы поощряем продавцов предоставлять конкурентоспособные цены и скидки
          для наших покупателей. Честная ценообразование способствует доверию
          клиентов.
        </p>
        <h3>Отзывы и рейтинг</h3>
        <p>
          Мы поддерживаем систему отзывов и рейтингов для продавцов и товаров.
          Пожалуйста, оставляйте честные отзывы и рейтинги после завершения
          сделок.
        </p>
      </section>

      <section>
        <h2>Безопасность</h2>
        <h3>Обеспечение безопасных сделок</h3>
        <p>
          Мы предоставляем рекомендации по безопасным сделкам и рекомендуем
          использовать наши рекомендации для уменьшения рисков.
        </p>
        <h3> Спам и мошенничество</h3>
        <p>
          Мы боремся с мошенничеством и спамом на нашей платформе. Если вы
          столкнулись с подозрительной активностью, пожалуйста, сообщите нам об
          этом.
        </p>
        <h3>Соблюдение законов</h3>
        <p>
          Мы ожидаем, что все пользователи будут соблюдать законы и правила при
          использовании нашего маркетплейса. Нарушители будут подвергнуты
          соответствующим мерам.
        </p>
      </section>

      <section>
        <h3 >Вопросы и поддержка</h3>
        <p>
          Если у вас есть какие-либо вопросы или требуется дополнительная
          информация о нашей политике, не стесняйтесь обращаться к нашей службе
          поддержки.
        </p>
        <p>
          Спасибо, что выбрали Azata.kz. Мы стремимся создать безопасное и
          удобное пространство для покупок и продаж, и ваше сотрудничество в
          этом важно для нас.
        </p>
        <p>
          С уважением, <br />
          Команда Azata.kz
        </p>
      </section>
    </Container>
  );
}

export default PolicyOfMarketplace;
