import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import Banner from "../../components/public/home/index/banner";
import SwiperGoodList from "../../components/public/Good/SwiperGoodList";
import GoodShort from "../../data/models/good/good";
import { Container } from "react-bootstrap";
import BestCategoriesTabs from "../../components/public/Good/BestCategoriesTabs";
import BestBrandsList from "../../components/public/Good/BestBrandsList";
import WhyUs from "../../components/public/home/index/WhyUs";
import GridGoods from "../catalog/GridGoods";
import { fetchGoods } from "../../data/models/good/good-api";

function HomeIndex() {
  const { t } = useTranslation();

  const [popularGoodList, setPopularGoodList] = useState([]);
  const [newGoodList, setNewGoodList] = useState([]);

  useEffect(() => {
    fetchGoods({
      categoryId: 0,
      brandId: 0,
      minPrice: 0,
      maxPrice: 0,
      query: " ",
      page: 1,
      perPage: 10,
    }).then((response) => {
      if (response.status === 200) {
        var list = []
        for (var item of response.data.rows) {
          list.push(new GoodShort(item))
        }
        setPopularGoodList(list);
        setNewGoodList(list);
      }
    });
  }, []);

  return (
    <>
      <Banner />
      <Container className="my-5">
        {popularGoodList.length > 0 && (
          <SwiperGoodList title={t("home.popularGoods")} list={popularGoodList} />
        )}
        <BestCategoriesTabs title={t("home.bestCategories")} />
        <BestBrandsList />
        {newGoodList.length > 0 && (
          <SwiperGoodList title={t("home.newArrivals")} list={newGoodList} />
        )}
        <WhyUs />
        <GridGoods />
      </Container>
    </>
  );
}

export default HomeIndex;
