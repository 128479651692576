import React, { Fragment } from "react";
import { SHOP_GOODS, SHOP_ORDERS, SHOP_REPORTS_ORDERS } from "../../constants/routes";
import { Link } from "react-router-dom";

function ShopAside() {
  const menu = [
    {
      title: "Заказы",
      list: [
        {
          to: SHOP_ORDERS+"/new",
          title: "Новые",
        },
        {
          to: SHOP_ORDERS+"/pickup",
          title: "На самовывоз",
        },
        {
          to: SHOP_ORDERS+"/delivery-city",
          title: "На доставку",
        },
        {
          to: SHOP_ORDERS+"/delivery-country",
          title: "На доставку по стране",
        },
        {
          to: SHOP_ORDERS+"/archive",
          title: "Архив",
        },
      ],
    },
    {
      title:"Товары",
      list:[
        {
          to: SHOP_GOODS,
          title: "Все",
        }
      ]
    },
    {
      title:"Отчеты",
      list:[
        {
          to: SHOP_REPORTS_ORDERS,
          title: "Заказы",
        }
      ]
    }
  ];

  return (
    <aside>
      {menu.map((group, index) => {
        return (
          <Fragment key={index}>
            <p>{group.title}</p>
            <ul className="mb-4">
              {group.list.map((item, i_index) => {
                return <li key={i_index}>
                  <Link to={item.to}>
                    <div>
                    {item.title}
                    </div>
                  </Link>
                </li>;
              })}
            </ul>
          </Fragment>
        );
      })}
    </aside>
  );
}

export default ShopAside;
