import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  fetchGetOrderInfo,
  fetchGetStatusLogsForOrder,
  fetchSetStatusForOrder,
} from "../../../data/models/partner/partner-api";
import { priceToString } from "../../../helpers/priceToString";
import { API_URL } from "../../../data/http/axios-init";
import { Button } from "react-bootstrap";
import IssueGoods from "./IssueGoods";

function ShopOrderDetails() {
  const { id } = useParams();

  const [order, setOrder] = useState(null);
  const [logs, setLogs] = useState([]);

  const update = () => {
    fetchGetOrderInfo(id).then((response) => {
      if (response.status == 200) {
        setOrder(response.data);
        fetchGetStatusLogsForOrder(id).then((response_1) => {
          if (response_1.status == 200) {
            setLogs(response_1.data);
          }
        });
      }
    });
  };

  useEffect(() => {
    update();
  }, []);

  const getAddress = (delivery) => {
    if (delivery.address.address) {
      return delivery.address.cityName + ", " + delivery.address.address;
    } else {
      return (
        delivery.address.cityName +
        ", " +
        delivery.address.street +
        ", " +
        delivery.address.house +
        ", " +
        delivery.address.flat
      );
    }
  };

  const handleSetStatus = (statusId) => {
    fetchSetStatusForOrder({
      orderId: id,
      statusId,
    }).then((response) => {
      if (response.status == 200) {
        update();
      }
    });
  };

  return (
    <div className="shop-order-details">
      {order && (
        <>
          <h5 className="fw-bold">Заказ № {order.id}</h5>

          <h6>Информация о заказе</h6>

          <div className="order-info-item">
            <div className="item-left">Статус</div>
            <div className="item-right">{order.status.name}</div>
          </div>
          <div className="order-info-item">
            <div className="item-left">Дата поступления заказа</div>
            <div className="item-right">{order.creationDate}</div>
          </div>
          <div className="order-info-item">
            <div className="item-left">Сумма</div>
            <div className="item-right">
              {priceToString(
                order.goods.reduce((acc, obj) => acc + (obj.price*obj.amount), 0)
              )}{" "}
              тг
            </div>
          </div>
          <div className="order-info-item">
            <div className="item-left">Вид доставки</div>
            <div className="item-right">{order.deliveryType.name}</div>
          </div>
          {order.deliveryType.id != 1 && (
            <div className="order-info-item">
              <div className="item-left">Адрес доставки</div>
              <div className="item-right">{getAddress(order.delivery)}</div>
            </div>
          )}
          <div className="order-info-item">
            <div className="item-left">Вид оплаты</div>
            <div className="item-right">{order.paymentType.name}</div>
          </div>
          <h6>Информация о клиенте</h6>

          <div className="order-info-item">
            <div className="item-left">Имя</div>
            <div className="item-right">
              {order.contacts.firstName} {order.contacts.secondName}
            </div>
          </div>
          <div className="order-info-item">
            <div className="item-left">Номер телефона</div>
            <div className="item-right">{order.contacts.phone}</div>
          </div>
          <div className="order-info-item">
            <div className="item-left">Электронная почта</div>
            <div className="item-right">{order.contacts.email}</div>
          </div>

          <h6>Состав заказа</h6>
          <table className="table" style={{ maxWidth: 1000 }}>
            <thead>
              <tr>
                <th style={{ width: 100 }}>Картинка</th>
                <th>Название товара</th>
                <th>Артикул</th>
                <th>Кол</th>
                <th>Цена</th>
                <th>Выдача</th>
              </tr>
            </thead>
            <tbody>
              {order.goods.map((good) => {
                return (
                  <tr key={good.id}>
                    <td>
                      <img
                        style={{ aspectRatio: "1 / 1", objectFit: "contain" }}
                        src={API_URL + good.image}
                        width={60}
                        alt=""
                      />
                    </td>
                    <td>{good.name}</td>
                    <td>{good.article}</td>
                    <td>{good.amount}</td>
                    <td>{priceToString(good.price)}</td>
                    <td>{good.isIssued ? 'Выдан':'Еще не выдан'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <h6>История изменений</h6>
          <table className="table" style={{ maxWidth: 1000 }}>
            <thead>
              <tr>
                <th style={{ width: 120 }}>Дата</th>
                <th style={{ width: 200 }}>Время</th>
                <th>Статус</th>
                <th>Пользователь</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => {
                return (
                  <tr key={log.id}>
                    <td>{log.writeDate}</td>
                    <td>{log.writeTime}</td>
                    <td>{log.statusName}</td>
                    <td>{log.userName}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {order.status.id == 1 && (
            <>
              <Button className="me-3" onClick={() => handleSetStatus(2)}>
                Принять заказ
              </Button>
              <Button
                variant="btn-danger text-danger"
                onClick={() => handleSetStatus(7)}
              >
                Отменить заказ
              </Button>
            </>
          )}

          {order.status.id==2 && (
            <>
              <Button className="me-3" onClick={() => handleSetStatus(3)}>
                Подтвержден и согласован с клиентом
              </Button>
              <small>*</small>
            </>
          )}

          {order.status.id==3 && (
            <>
              <IssueGoods orderId={id} goods={order.goods} update={update}/>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default ShopOrderDetails;
