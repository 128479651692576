import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";

function BePartner() {

  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Здесь вы можете отправить данные формы на сервер или выполнить другие действия
  };

  return (
    <Container className="pt-4" id="be-partner">
      <h1 className="section-title">Стань партнером Azata.kz</h1>
      <img src="/images/be-partner.jpeg" className="banner my-4" alt="" />

      <p>
        Присоединяйтесь к нам и станьте частью нашего маркетплейса. Мы ценим
        партнерские отношения и предлагаем ряд преимуществ для тех, кто решит
        войти в нашу сеть.
      </p>

      <h3>Почему стоит стать нашим партнером:</h3>

      <div className="d-flex" style={{ gap: 30 }}>
        <div className="item-container">
          <div className="item">
            <img src="/images/be-partner-icon1.png" alt="" />
            <h4>Широкая аудитория</h4>
            <p>
              Наш маркетплейс имеет огромное количество посетителей, готовых
              купить вашу продукцию.
            </p>
          </div>
        </div>
        <div className="item-container">
          <div className="item">
            <img src="/images/be-partner-icon2.png" alt="" />
            <h4>Продвижение товаров</h4>
            <p>
              Мы поможем вам привлечь внимание к вашим товарам и услугам,
              обеспечивая их видимость на платформе.
            </p>
          </div>
        </div>
        <div className="item-container">
          <div className="item">
            <img src="/images/be-partner-icon3.png" alt="" />
            <h4>Низкая комиссия</h4>
            <p>
              Мы предлагаем конкурентные условия с низкой комиссией, что
              позволит вам максимизировать прибыль.
            </p>
          </div>
        </div>
        <div className="item-container">
          <div className="item">
            <img src="/images/be-partner-icon4.png" alt="" />
            <h4>Подробная аналитика</h4>
            <p>
              {" "}
              Получайте доступ к подробной статистике и отчетам о продажах,
              чтобы принимать информированные решения.
            </p>
          </div>
        </div>
        <div className="item-container">
          <div className="item">
            <img src="/images/be-partner-icon5.png" alt="" />
            <h4>Поддержка</h4>
            <p>
              Наша команда всегда готова помочь вам в любых вопросах и
              обеспечить лучший опыт партнерства.
            </p>
          </div>
        </div>
      </div>


      <Form onSubmit={handleSubmit}>
        <h3>
          Присоединяйтесь к нам и начните успешное партнерство уже сегодня.
        </h3>
        <p>
          Заполните форму ниже, чтобы зарегистрироваться как партнер:
        </p>

        <Form.Group controlId="name">
          <Form.Label>Имя</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="company">
          <Form.Label>Компания</Form.Label>
          <Form.Control
            type="text"
            name="company"
            value={formData.company}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="email">
          <Form.Label>Электронная почта</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="phone">
          <Form.Label>Телефон</Form.Label>
          <Form.Control
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </Form.Group>

        <Form.Group controlId="message">
          <Form.Label>Сообщение</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            name="message"
            value={formData.message}
            onChange={handleChange}
          />
        </Form.Group>

        <Button variant="primary" type="submit">
          Отправить
        </Button>
      </Form>

    </Container>
  );
}

export default BePartner;
