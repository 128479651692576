import React, { useEffect, useState } from "react";
import { Button, Col, Container, Nav, Row, Tab } from "react-bootstrap";
import { fetchCategoryTree } from "../../../data/models/category/category-api";
import { CATALOG_CATEGORY } from "../../../constants/routes";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NavbarCatalog() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "auto";
    document.body.style.height = show ? "100vh" : "auto";
  }, [show]);

  const [tree, setTree] = useState([]);

  useEffect(() => {
    fetchCategoryTree(0).then((response) => {
      setTree(response.data);
    });
  }, []);

  const getChilds = (item) => {
    let mas = [
      <li>
        <Link
          to={CATALOG_CATEGORY + "/" + item.id}
          onClick={() => setShow(false)}
        >
          {item.name}
        </Link>
      </li>,
    ];
    for (var c of item.childs) {
      mas.push(getChilds(c));
    }
    return mas;
  };
  const { t } = useTranslation();
  return (
    <>
      <Button
        onClick={() => setShow((prev) => !prev)}
        className={(show ? "active" : "") + " no-mobile"}
      >
        {show ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-x-square-fill"
            viewBox="0 0 16 16"
          >
            <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm3.354 4.646L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 1 1 .708-.708z" />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-grid-fill"
            viewBox="0 0 16 16"
          >
            <path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zm8 0A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm-8 8A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm8 0A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3z" />
          </svg>
        )}
        <span className="ms-2">{t("catalog")}</span>
      </Button>
      {show && (
        <div className="navbar-catalog py-3">
          <Container>
            {/* <Tab.Container id="left-tabs-example" defaultActiveKey="1">
              <Row>
                <Col sm={3}>
                  <Nav variant="pills" className="flex-column">
                    {tree.length > 0 &&
                      tree.map((item) => {
                        return <Nav.Item key={item.id}>
                          <Nav.Link eventKey={item.id}>{item.name}</Nav.Link>
                        </Nav.Item>;
                      })}
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    {
                        tree.length>0 &&
                        tree.map((item)=>{
                            return <Tab.Pane eventKey={item.id}>
                                <div className="list-container">
                                    {
                                        item.childs.length>0 && item.childs.map((child)=>{
                                            return <div key={child.id} className="pin mb-3" >
                                               <Link className='text-dark mb-2 fw-bold' onClick={()=>setShow(false)} to={CATALOG_CATEGORY+'/'+child.id}>{child.name}</Link>
                                                <ul className="list-group list-unstyled mt-2">
                                                    {
                                                        getChilds(child).map((li,item)=>{
                                                            if(item>0)
                                                                return li;
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        })
                                    }
                                </div>
                            </Tab.Pane>
                        })
                    }
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container> */}

            <div className="catalog-grid-container">
              <div className="div1">
                <Link to="/catalog/category/smartphones" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("smartphones")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div2">
                <Link to="/catalog/category/headphones" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("headphones")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div3">
                <Link to="/catalog/category/laptops" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("laptops")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div4">
                <Link to="/catalog/category/tablets" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("tablets")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div5">
                <Link to="/catalog/category/cables_and_charges" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("cables_and_charges")}</h3>
                  </div>
                </Link>
              </div>
              <div className="div6">
                <Link to="/catalog/category/cases_and_safety_glasses" onClick={()=>setShow(false)}>
                  <div className="filter">
                    <h3 className="title">{t("cases_and_safety_glasses")}</h3>
                  </div>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      )}
    </>
  );
}

export default NavbarCatalog;
