import { set } from "mobx";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import Brand from "../../../data/models/brand/brand";

function UpdateCity({ fetchOne, fetchUpdate, id, updateList }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSubmitBtn({
      variant: "primary",
      disabled: false,
      label: "Сохранить",
    })
  }
  const handleShow = () => {
    setShow(true);
    fetchOne(id).then((response) => {
      if (response.status == 200) {
        setModel(response.data);
      }
    });
  };

  const [model, setModel] = useState(null);
  const [submitBtn, setSubmitBtn] = useState({
    variant: "primary",
    disabled: false,
    label: "Сохранить",
  });

  const handleForm = (e) => {
    e.preventDefault();

    fetchUpdate(model).then((response) => {
      if (response.status == 200) {
        setSubmitBtn({
          variant: "success",
          disabled: true,
          label: "Готово!",
        });
        updateList();
        handleClose();
      } else {

        setSubmitBtn({
          variant: "danger",
          disabled: false,
          label: "Ошибка. Попробуйте еще раз",
        });
      }
    });
  };

  return (
    <>
      <Dropdown.Item onClick={handleShow}>Редактировать</Dropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование города</Modal.Title>
        </Modal.Header>
        {model && (
          <Form onSubmit={(e) => handleForm(e)}>
            <Modal.Body>
              <Form.Group className="mb-3" controlId="name">
                <Form.Label>Название города</Form.Label>
                <Form.Control
                  type="text"
                  placeholder=""
                  value={model.name}
                  onChange={(e) =>
                    setModel({
                      ...model,
                      name: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant={submitBtn.variant}
                type="submit"
                className={submitBtn.disabled ? "disabled" : ""}
              >
                {submitBtn.label}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Modal>
    </>
  );
}

export default UpdateCity;
