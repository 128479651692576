import React, { useContext, useEffect, useState } from "react";
import { fetchGoods } from "../../../data/models/good/good-api";
import { Link } from "react-router-dom";
import { CATALOG_GOOD, FINDER } from "../../../constants/routes";
import GoodShort from "../../../data/models/good/good";
import { priceToString } from "../../../helpers/priceToString";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';

function NavbarFinder() {
  const [value, setValue] = useState("");
  const [focus, setFocus] = useState(false);
  const [result, setResult] = useState([]);
  const { store } = useContext(Context)

  const handleInputChange = (v) => {
    setValue(v);
    if (v.length > 2) {
      fetchGoods({
        categoryId: 0,
        brandId: 0,
        minPrice: 0,
        maxPrice: 0,
        seriesId: 0,
        sort: "id",
        order: "desc",
        page: 1,
        perPage: 20,
        query: v,
      }).then((response) => {
        if (response.status == 200) {
          setResult(response.data.rows);
        }
      });
    }
  };

  document.addEventListener("keydown", function (e) {
    var inputElement = document.querySelector(".finder-container input");
    if (inputElement && e.key === "Escape") {
      setValue("");
      inputElement.blur();
    }
  });

  useEffect(() => {
    document.body.style.overflow = focus ? "hidden" : "auto";
    document.body.style.height = focus ? "100vh" : "auto";
  }, [focus]);

  const { t } = useTranslation();

  return (
    <div className="finder-container no-mobile">
      {focus && <div className="finder-background"></div>}
      <div className="finder-container-absolute">
        <div className="input">
          <input
            type="text"
            value={value}
            onFocus={(e) => setFocus(true)}
            onBlur={() => setFocus(false)}
            onChange={(e) => handleInputChange(e.target.value)}
            placeholder={t("my_interests")}
          />
          {focus ? (
            <>


              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-x-lg"
                viewBox="0 0 16 16"
              >
                <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
              </svg>
            </>
          ) : (
            <>
              <svg
                onClick={() => {
                  handleInputChange("");
                  document.querySelector(".finder-container input").focus();
                }}
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-search"
                viewBox="0 0 16 16"
              >
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
              </svg>
            </>
          )}
        </div>
        {result.length > 0 && (
          <div className="result">
            {result.length > 0 && <Link to={FINDER + "/" + value} onClick={() => {
              handleInputChange('')
              setResult([])
            }}><div className="my-2">{t("see_all")}</div></Link>}
            {result.map((goodDto) => {
              var good = new GoodShort(goodDto);
              return (
                <Link to={CATALOG_GOOD + "/" + good.id} onClick={() => {
                  handleInputChange('')
                  setResult([])
                }}>
                  <div className="result-item">
                    <img src={good.image} alt="" />
                    <div>
                      <p className="mb-0">{t("article")}: {good.article}</p>
                      <h6>{good.name}</h6>
                      <h6 className="price">{priceToString(store.convertedCurrency(good.price))}</h6>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default observer(NavbarFinder);
