import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  ADMIN_BRANDS,
  ADMIN_CATEGORIES,
  ADMIN_CITIES,
  ADMIN_GOODS,
  ADMIN_GOOD_SERIES,
  ADMIN_PARAMS_GROUP,
  ADMIN_ROLES,
  ADMIN_USERS,
} from "../../constants/routes";

const list = [
  {
    to: ADMIN_BRANDS,
    title: "Производители",
    svg: "",
  },
  {
    to: ADMIN_CATEGORIES,
    title: "Категории",
    svg: "",
  },
  {
    to: ADMIN_GOOD_SERIES,
    title: "Модельные серии",
    svg: "",
  },
  {
    to: ADMIN_GOODS,
    title: "Товары",
    svg: "",
  },
  {
    to: ADMIN_PARAMS_GROUP,
    title: "Параметры товара",
    svg: "",
  },
  {
    to: ADMIN_CITIES,
    title: "Города",
    svg: "",
  },
  {
    to: ADMIN_ROLES,
    title: "Роли",
    svg: "",
  },
  {
    to:ADMIN_USERS,
    title:"Пользователи",
    svg:""
  },
  {
    to:"/",
    title:"Назад в магазин",
    svg:""
  }
];

function Aside() {
  const [currentLocation, setCurrentLocation] = useState(
    window.location.pathname
  );
  return (
    <aside>
      <ul className="menu-list">
        {list.map((item, index) => {
          return (
            <li key={index}>
              <Link
                to={item.to}
                onClick={() => setCurrentLocation("/admin/" + item.to)}
              >
                <div
                  className={
                    "menu-list-item " +
                    (currentLocation == "/admin/" + item.to && "active")
                  }
                >
                  <p>{item.title}</p>
                </div>
              </Link>
            </li>
          );
        })}
      </ul>
    </aside>
  );
}

export default Aside;
