import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { fetchGoods } from "../../data/models/good/good-api";
import GoodShortItem from "../../components/public/Good/GoodShortItem";
import GoodShort from "../../data/models/good/good";

function Finder() {
  const { query } = useParams();
  const [goods, setGoods] = useState([]);
  const [serchQuery, setSearchQuery] = useState(query ?? "");

  const updateData = (query) => {
    fetchGoods({
      categoryId: 0,
      brandId: 0,
      minPrice: 0,
      maxPrice: 0,
      seriesId: 0,
      sort: "id",
      order: "desc",
      page: 1,
      perPage: 30,
      query,
    }).then((response) => {
      if (response.status == 200) {
        setGoods(response.data.rows);
      }
    });
  };

  useEffect(() => {
    updateData(query);
  }, [query]);

  const handleSearchQuery = (value) => {
    setSearchQuery(value);
    if (value.length > 2) {
      updateData(value);
    }
  };

  return (
    <Container className="pt-4">
      <input
        type="text"
        className="form-control mb-3"
        placeholder="начинайте вводить"
        value={serchQuery}
        onChange={(e) => handleSearchQuery(e.target.value)}
      />
      <div className="d-flex" style={{ gap: 11, flexWrap: "wrap" }}>
        {goods.length > 0 &&
          goods.map((good) => {
            return <GoodShortItem item={new GoodShort(good)} />;
          })}
      </div>
    </Container>
  );
}

export default observer(Finder);
