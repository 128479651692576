import React from "react";
import { Container } from "react-bootstrap";

function PromotionsAndServices() {
  const promotions = [
    {
      title: "Скидка на технику Apple",
      description:
        "Сейчас у вас есть уникальная возможность приобрести продукцию Apple по специальной цене. Независимо от того, ищете ли вы новый iPhone, iPad, MacBook или аксессуары, вы найдете лучшие предложения у нас.",
    },
    {
      title: "Подарочные карты",
      description:
        "Покупайте подарочные карты на нашем маркетплейсе и получайте дополнительные бонусы. Подарочные карты доступны в разных номиналах, и они могут стать отличным подарком для ваших близких.",
    },
    {
      title: "Бесплатная доставка",
      description:
        "При заказе определенных товаров, вы можете воспользоваться бесплатной доставкой. Это отличная возможность сэкономить на доставке при покупке товаров на большую сумму.",
    },
  ];

  return (
    <Container className="pt-4" id="promotions-and-services">
      <h1 className="section-title">Акции и скидки</h1>
      <img src="/images/promo-banner.jpg" className="banner" alt="" />
      <p className="my-3">
        Дорогие клиенты, рады представить вам наши актуальные акции и скидки. Мы
        всегда стараемся удовлетворить потребности наших клиентов и предлагаем
        специальные предложения, чтобы сделать ваши покупки ещё более приятными.
      </p>
      <h4 className="my-5">Теущие акции</h4>
      {
        promotions.map((item,index)=>{
            return <div className="current_promo">
                <h5>{item.title}</h5>
                <p>{item.description}</p>
            </div>
        })
      }

      <h4 className="my-5">Следите за обновлениями:</h4>
      <p>
      Мы регулярно обновляем список акций и скидок, поэтому следите за нашими новостями и рассылками, чтобы не упустить выгодные предложения. У нас всегда есть что-то особенное для наших клиентов.
      </p>
      <p>
      Спасибо, что выбрали Azata.kz. Мы ценим вашу лояльность и готовы радовать вас лучшими предложениями и акциями. Если у вас есть какие-либо вопросы или требуется дополнительная информация, не стесняйтесь обращаться к нашей службе поддержки.
      </p>
      <p>С уважением, <br />Команда Azata.kz</p>
    </Container>
  );
}

export default PromotionsAndServices;
