import React, { useEffect, useState } from "react";
import { fetchGood } from "../../../data/models/good/good-api";
import GoodShort from "../../../data/models/good/good";
import { priceToString } from "../../../helpers/priceToString";

function BasketDeliveryPickupGoodItem({ goodId, goodPrice, showPrice }) {
  const [good, setGood] = useState(null);

  useEffect(() => {
    fetchGood(goodId).then((response) => {
      if (response.status == 200) {
        setGood(new GoodShort(response.data));
      }
    });
  }, []);

  return (
    <div className="d-flex align-items-center" style={{ gap: 20 }}>
      {good && (
        <>
          <img src={good.image} width={40} alt="" />
          <div className="d-flex justify-content-between flex-grow-1">
            <p>
              <b>{good.name}</b>
            </p>
            {showPrice && (
              <p>
                <b>{priceToString(goodPrice??good.price)} тг.</b>
              </p>
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default BasketDeliveryPickupGoodItem;
