import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fetchGetCityDistricts } from "../../../data/models/city/city-api";
import {
  fetchCreateUserLocation,
  fetchUpdateUserLocation,
} from "../../../data/http/auth-api";

function CabinetDeliveryPointsUpdateModal({ data, updateData }) {
  const [show, setShow] = useState(false);
  const [district, setDistricts] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [formData, setFormData] = useState(data);

  useEffect(() => {
    fetchGetCityDistricts({ cityId: 1, query: "" }).then((response) => {
      if (response.status == 200) {
        setDistricts(response.data);
      }
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchUpdateUserLocation(formData).then((response) => {
      if (response.status == 200) {
        updateData();
        setFormData({
          title: "",
          street: "",
          districtId: 0,
          flat: "",
          floor: "",
        });
        handleClose();
      }
    });
  };

  return (
    <>
      <Button className="me-2" size="sm" variant="warning" onClick={handleShow}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-pencil-fill"
          viewBox="0 0 16 16"
        >
          <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z" />
        </svg>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Редактирование точки доставки</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => handleSubmit(e)}>
            <Form.Group controlId="title" className="mb-3">
              <Form.Label>Название адреса</Form.Label>
              <Form.Control
                type="text"
                value={formData.title}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    title: e.target.value,
                  });
                }}
                placeholder="Придумайте короткое название адреса (дом, работа и т.д.)"
              />
            </Form.Group>

            <Form.Group controlId="title" className="mb-3">
              <Form.Label>Название адреса</Form.Label>
              <Form.Select
                value={formData.districtId}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    districtId: +e.target.value,
                  })
                }
              >
                <option value="">-Выберите район в Алматы-</option>
                {district.length > 0 &&
                  district.map((d) => {
                    return (
                      <option key={d.id} value={d.id}>
                        {d.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="title" className="mb-3">
              <Form.Label>Название улицы</Form.Label>
              <Form.Control
                type="text"
                value={formData.street}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    street: e.target.value,
                  });
                }}
                placeholder="улица Пушкина (проспект Абая)"
              />
            </Form.Group>

            <div className="d-flex align-items-end" style={{ gap: 20 }}>
              <Form.Group controlId="title" className="mb-3">
                <Form.Label>Номер дома (здания)</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.house}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      house: e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </Form.Group>
              <Form.Group controlId="title" className="mb-3">
                <Form.Label>Номер квартиры (офиса)</Form.Label>
                <Form.Control
                  type="text"
                  value={formData.flat}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      flat: e.target.value,
                    });
                  }}
                  placeholder=""
                />
              </Form.Group>
              <Form.Group controlId="title" className="mb-3">
                <Form.Label>Этаж </Form.Label>
                <Form.Control
                  type="text"
                  value={formData.floor}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      floor: e.target.value,
                    });
                  }}
                  placeholder="1"
                />
              </Form.Group>
            </div>
            <Button type="submit">Сохранит изменения</Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CabinetDeliveryPointsUpdateModal;
