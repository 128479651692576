import axios from 'axios';

export const API_URL = 'https://api.azatashop.kz'
// export const API_URL = 'https://localhost:7201/'

// Создание экземпляра Axios для хоста
export const $host = axios.create({
  baseURL: API_URL,
});


// Создание экземпляра Axios для авторизованного хоста
export const $authHost = axios.create({
  baseURL: API_URL,
  headers: {
    Authorization: 'Bearer ' + localStorage.getItem('token')
  }
});
