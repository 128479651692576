import React from "react";
import { Link } from "react-router-dom";
import { ADMIN_INDEX } from "../../constants/routes";
import AdminHeaderFinder from "./AdminHeaderFinder";

function AdminHeader() {
  return (
    <header>
      <ul
        className="d-flex list-unstyled m-0 p-0 h-100 align-items-center"
        style={{ gap: 50 }}
      >
        <li>
          <Link to={ADMIN_INDEX} className="brand">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="bi bi-sliders"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z"
              />
            </svg>
            AzataAdmin
          </Link>
        </li>
        <li className="flex-grow-1">
          <AdminHeaderFinder />
        </li>
        <li></li>
      </ul>
    </header>
  );
}

export default AdminHeader;
