import React from 'react'
import ShopHeader from '../components/shop/ShopHeader'
import ShopAside from '../components/shop/ShopAside'
import { Outlet } from 'react-router-dom'

function ShopLayout() {
  return (
    <>
    <div id="shop-layout">
      <ShopHeader />
      <div className="d-flex">
        <ShopAside />
        <div className="shop-layout-content">
          <div className="shop-layout-content-container">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default ShopLayout