import React, { useContext, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import RatingStars from '../RatingStars'
import { Context } from '../../../..'
import { fetchSetCreateGoodComment } from '../../../../data/models/good/good-api'

function AddCommentForm({goodId,updateComments}) {

    const {store} = useContext(Context)

    const [formValues,setFormValues] = useState({
        goodId:+goodId,
        recommended:true,
        customer: (store && store.logged? JSON.parse(localStorage.getItem('user')).firstName : ''),
        advantages:'',
        disadvantages:'',   
        comment:'',
        rating:5
    })

    const setRating = (rating) =>{
        setFormValues({
            ...formValues,
            rating
        })
    }

    const handleSubmit = (e)=>{
        e.preventDefault();
        fetchSetCreateGoodComment(formValues).then((response)=>{
            if(response.status==200) {
                updateComments(goodId)
                setFormValues({
                    goodId:+goodId,
                    recommended:true,
                    customer: (store && store.logged? JSON.parse(localStorage.getItem('user')).firstName : ''),
                    advantages:'',
                    disadvantages:'',   
                    comment:'',
                    rating:5
                })
            }
        })
    }

    return (
        <Form className='comment-form' onSubmit={(e)=>handleSubmit(e)}>
            <h4 className='mb-0'>Напишите свой отзыв</h4>
            <RatingStars rating={formValues.rating} setRating={setRating}/>
            <div className="d-flex flex-wrap mt-2 mb-3  align-items-start" style={{maxWidth:800}}>
                <Form.Group className="w-100 mb-3 mb-md-0  w-md-auto flex-grow-1"   controlId="exampleForm.ControlInput1">
                    <Form.Control type="text" placeholder="Ваше имя" value={formValues.customer} onChange={(e)=>setFormValues({
                        ...formValues,
                        customer:e.target.value
                    })} />
                </Form.Group>
                <button type="button" className={'recommend-btn ms-0 ms-md-3 ' + (formValues.recommended?'good-active':'')} 
                onClick={()=>setFormValues({
                    ...formValues,
                    recommended:true
                })}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                    </svg> <span className='no-mobile'>Рекомендую</span>
                </button>
                <button type="button" className={'recommend-btn ms-2 '+(!formValues.recommended?'bad-active':'')}
                onClick={()=>setFormValues({
                    ...formValues,
                    recommended:false
                })}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                    </svg> <span className="no-mobile">
                    Не рекомендую
                    </span>
                </button>
            </div>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Преимущества" value={formValues.advantages} onChange={(e)=>setFormValues({
                        ...formValues,
                        advantages:e.target.value
                    })}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control type="text" placeholder="Недостатки" value={formValues.disadvantages} onChange={(e)=>setFormValues({
                        ...formValues,
                        disadvantages:e.target.value
                    })}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Control as="textarea" rows={5} placeholder="Ваше мнение по данному товару" value={formValues.comment} onChange={(e)=>setFormValues({
                        ...formValues,
                        comment:e.target.value
                    })}/>
            </Form.Group>

            <Button type='submit'>Отправить отзыв</Button>
        </Form>
    )
}

export default AddCommentForm