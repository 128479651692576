import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../..";
import GoodShort from "../../../data/models/good/good";
import BasketGoodItem from "./BasketGoodItem";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

function BasketGoodList() {
  const { store } = useContext(Context);
  const { t } = useTranslation();

  useEffect(() => {});

  return (
    <>
      <h5 className="step-title">
        <div className="step-box">1</div>
        {t("goods")}
      </h5>
      <ul className="list-group basket-good-list mb-3">
        {store.basket.goods.length > 0 &&
          store.basket.goods.map((item) => {
            return <BasketGoodItem key={item.id+'-'+item.shopId} item={item} />;
          })}
      </ul>
    </>
  );
}

export default observer(BasketGoodList);
