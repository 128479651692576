import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import LoginAndRegistration from "../Auth/LoginAndRegistration";
import { fetchGetMyName } from "../../../data/http/auth-api";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

function NavbarCabinet() {
  const {store} = useContext(Context)
  const { t } = useTranslation();

  return (
    <>
      {store.logged ? (
        <Link to="/cabinet" className="no-mobile">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              fill="currentColor"
              className="bi bi-person-fill"
              viewBox="0 0 16 16"
            >
              <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
            </svg>
            {t("cabinet")}
          </div>
        </Link>
      ) : (
        <LoginAndRegistration/>
      )}
    </>
  );
}

export default observer(NavbarCabinet);
