import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import ManageTable from "../../../components/admin/ManageTable";
import CreateGoodSeries from "./CreateGoodSeries";
import {
  fetchCreateGoodSeries,
  fetchGetGoodSeries,
} from "../../../data/models/good/good-api";
import { Link } from "react-router-dom";
import { ADMIN_GOOD_SERIES_PROPERTIES } from "../../../constants/routes";
import GoodSeriesProduct from "./GoodSeriesProduct";
import GoodSeriesImages from "./GoodSeriesImages";
import GoodSeriesParameters from "./GoodSeriesParameters";
import EditGoodSeriesPrice from "./EditGoodSeriesPrice";

function GoodSeries() {
  const $fetch = {
    list: fetchGetGoodSeries,
    create: fetchCreateGoodSeries,
  };

  const [list, setList] = useState([]);

  const updateList = () => {
    $fetch.list().then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, []);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">Управление модельными сериями товаров</h2>
      <CreateGoodSeries fetchCreate={$fetch.create} updateList={updateList} />
      <ManageTable title={"Список производителей"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Название</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                          <Link className="dropdown-item"
                            to={
                              "/admin/" + ADMIN_GOOD_SERIES_PROPERTIES + "/" + item.id
                            }
                          >
                            <Button variant="link">К свойствам</Button>
                          </Link>
                        <Dropdown.Item>
                          <GoodSeriesProduct seriesId={item.id}/>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <GoodSeriesImages seriesId={item.id}/>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <GoodSeriesParameters seriesId={item.id}/>
                        </Dropdown.Item>
                        <Dropdown.Item>
                          <EditGoodSeriesPrice seriesId={item.id} seriesName={item.name}/>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ManageTable>
    </>
  );
}

export default GoodSeries;
