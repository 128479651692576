import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function DeleteCityDistrict({
    fetchDelete,
    id,
    name,
    updateList
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDelete = ()=>{
    fetchDelete(id).then((response)=>{
      if(response.status==200) {
        updateList()
        handleClose()
      }
    })
  }

  return (
    <>
      <Dropdown.Item onClick={handleShow} className="text-danger">
        Удалить
      </Dropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление района</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        <p>Вы уверены что хотите удалить "{name}" район</p>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" type="button" onClick={()=>handleDelete()}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteCityDistrict;
