import { useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import NavbarFinder from "./NavbarFinder";
import { useTranslation } from 'react-i18next';

function MobileMenuFinder() {
  const [show, setShow] = useState(false);
  const { t } = useTranslation();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div className="menu-li" onClick={()=>setShow((prev)=>(!prev))}>
      <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
</svg>
        <p>{t("search")}</p>
      </div>

      <Offcanvas show={show} placement="bottom" className="h-100" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{t("search_on_site")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <NavbarFinder/>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MobileMenuFinder;
