import { Fragment, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fetchGetGoodSeriesParameters, fetchGoodSeriesSetParameter } from "../../../data/models/good/good-api";

function GoodSeriesParameters({ seriesId }) {
  const [show, setShow] = useState(false);
  const [parameterGroups,setParameterGroups] = useState([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    fetchGetGoodSeriesParameters(seriesId).then((response)=>{
        if(response.status==200) {
            setParameterGroups(response.data)
        }
    })
  },[])

  const handleChange = (value,id)=>{
    fetchGoodSeriesSetParameter({
        id:0,
        seriesId,
        parameterId:id,
        value
    })
  }

  return (
    <>
      <Button variant="link" onClick={handleShow}>
        Добавить характеристики
      </Button>

      <Modal size={'lg'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Добавление характеристик для серии товаров</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <table className="table table-bordered">
            <thead>
              <tr>
                <th>Наименование параметра</th>
                <th>Значение</th>
              </tr>
            </thead>
            <tbody>
              {parameterGroups.length > 0 &&
                parameterGroups.map((item_1) => {
                  return (
                    <Fragment key={item_1.id}>
                      <tr >
                        <td colSpan={2} className="fw-bold bg-success bg-opacity-25">
                          {item_1.name}
                        </td>
                      </tr>
                      {item_1.parameters.length > 0 &&
                        item_1.parameters.map((item_2) => {
                          return (
                            <tr key={item_2.id}>
                              <td>{item_2.name}</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  defaultValue={item_2.value}
                                  onChange={(e)=>handleChange(e.target.value,item_2.id)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </Fragment>
                  );
                })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GoodSeriesParameters;
