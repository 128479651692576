// PublicOffer.jsx
import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function PublicOffer() {
  const { t } = useTranslation();

  return (
    <Container className="pt-4" id="public-offer">
      <h3 className="section-title">{t('public_offer_title')}</h3>
      <img src="/images/publicoffer-banner.jpg" className="banner" alt="" />
      <section>
        <h2>{t('general_terms_title')}</h2>
        <p>{t('general_terms_1.1')}</p>
        <p>{t('general_terms_1.2')}</p>
        <p>{t('general_terms_1.3')}</p>
      </section>

      <section>
        <h2>{t('goods_and_services_title')}</h2>
        <p>{t('goods_and_services_2.1')}</p>
        <p>{t('goods_and_services_2.2')}</p>
      </section>

      <section>
        <h2>{t('order_and_payment_title')}</h2>
        <p>{t('order_and_payment_3.1')}</p>
        <p>{t('order_and_payment_3.2')}</p>
      </section>
    </Container>
  );
}

export default PublicOffer;
