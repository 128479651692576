import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fetchCategories } from "../../../data/models/category/category-api";
import {
  fetchBrand,
  fetchBrandList,
} from "../../../data/models/brand/brand-api";
import {
  fetchGetGoodSeries,
  fetchGetGoodSeriesProperties,
} from "../../../data/models/good/good-api";

function Create({ fetchCreate, updateList }) {
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [series, setSeries] = useState([]);
  const [seriesProps, setSeriesProps] = useState({
    colors: [],
    memories: [],
    additionalProperties: [],
  });

  const handleClose = () => {
    setShow(false);
    setSubmitBtn({
      variant: "primary",
      disabled: false,
      label: "Сохранить",
    });
  };
  const handleShow = () => {
    fetchCategories({ query: "" }).then((response) => {
      if (response.status == 200) {
        setCategories(response.data);
      }
    });

    fetchBrandList({ query: "" }).then((response) => {
      if (response.status == 200) {
        setBrands(response.data.rows);
      }
    });

    fetchGetGoodSeries().then((response) => {
      if (response.status == 200) {
        setSeries(response.data);
      }
    });
    setShow(true);
  };

  const updateGoodSeriesProps = (goodSeriesId) => {
    fetchGetGoodSeriesProperties(goodSeriesId).then((response) => {
      if (response.status == 200) {
        setSeriesProps(response.data);
      }
    });
  };

  const [formModel, setFormModel] = useState({
    id: 0,
    article: "",
    name: "",
    description: "",
    categoryId: 0,
    brandId: 0,
    basePrice: 0,
    isActive: false,
    isDeleted: false,
    goodSeriesId: 0,
    memoryId: 0,
    colorId: 0,
    seriesAdditionalPropertyId: 0,
    isReplica: false,
    isService: false,
    isPackage: false,
  });

  const [submitBtn, setSubmitBtn] = useState({
    variant: "primary",
    disabled: false,
    label: "Сохранить",
  });

  const handleForm = (e) => {
    e.preventDefault();

    setSubmitBtn({
      variant: "secondary",
      disabled: true,
      label: "Сохранение...",
    });

    fetchCreate(formModel).then((response) => {
      if (response.status == 200) {
        //
        setSubmitBtn({
          variant: "success",
          disabled: true,
          label: "Готово!",
        });
        // setFormModel({
        //   id:0,
        //   article:'',
        //   name: "",
        //   description: "",
        //   categoryId:0,
        //   brandId:0,
        //   basePrice:0,
        //   isActive:true,
        //   isDeleted:false,
        //   goodSeriesId:0,
        //   memoryId:0,
        //   colorId:0,
        //   seriesAdditionalPropertyId:0,
        //   isReplica:false,
        //   isService:false,
        //   isPackage:false
        // });
        updateList();
        handleClose();
      } else {
        //

        setSubmitBtn({
          variant: "danger",
          disabled: false,
          label: "Ошибка. Попробуйте еще раз",
        });
      }
    });
  };

  return (
    <>
      <Button variant="primary" className="mb-3" onClick={handleShow}>
        + Добавить товар
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Добавление товара</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleForm(e)}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Категория товара</Form.Label>
              <Form.Select
                required={true}
                aria-label="Категория товара"
                value={formModel.categoryId}
                onChange={(e) => {
                  setFormModel({
                    ...formModel,
                    categoryId: +e.target.value,
                  });
                }}
              >
                <option value="">-Выберите категорию-</option>
                {categories.length > 0 &&
                  categories.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Производитель</Form.Label>
              <Form.Select
                aria-label="Категория товара"
                value={formModel.brandId}
                required={true}
                onChange={(e) => {
                  setFormModel({
                    ...formModel,
                    brandId: +e.target.value,
                  });
                }}
              >
                <option value="">-Выберите производителя-</option>
                {brands.length > 0 &&
                  brands.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Модельная серия</Form.Label>
              <Form.Select
                aria-label="Модельная серия"
                value={formModel.goodSeriesId}
                required={true}
                onChange={(e) => {
                  setFormModel({
                    ...formModel,
                    goodSeriesId: +e.target.value,
                  });

                  updateGoodSeriesProps(+e.target.value);
                }}
              >
                <option value={0}>-Без серии-</option>
                {series.length > 0 &&
                  series.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <div className="d-flex mb-3" style={{ gap: 20 }}>
              {seriesProps.colors.length > 0 && (
                <Form.Group className="flex-grow-1">
                  <Form.Label>Цвет</Form.Label>
                  <Form.Select
                    aria-label="Цвет"
                    value={formModel.colorId}
                    required={true}
                    onChange={(e) => {
                      setFormModel({
                        ...formModel,
                        colorId: +e.target.value,
                      });
                    }}
                  >
                    <option value={0}>-Без цвета-</option>
                    {seriesProps.colors.length > 0 &&
                      seriesProps.colors.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.colorName}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              )}

              {seriesProps.memories.length > 0 && (
                <Form.Group className="flex-grow-1">
                  <Form.Label>Память</Form.Label>
                  <Form.Select
                    aria-label="Цвет"
                    value={formModel.memoryId}
                    required={true}
                    onChange={(e) => {
                      setFormModel({
                        ...formModel,
                        memoryId: +e.target.value,
                      });
                    }}
                  >
                    <option value={0}>-Без цвета-</option>
                    {seriesProps.memories.length > 0 &&
                      seriesProps.memories.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.value}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              )}

              {seriesProps.additionalProperties.length > 0 && (
                <Form.Group className="flex-grow-1">
                  <Form.Label>Доп свойтсво</Form.Label>
                  <Form.Select
                    aria-label="Цвет"
                    value={formModel.seriesAdditionalPropertyId}
                    required={true}
                    onChange={(e) => {
                      setFormModel({
                        ...formModel,
                        seriesAdditionalPropertyId: +e.target.value,
                      });
                    }}
                  >
                    <option value={0}>-Без-</option>
                    {seriesProps.additionalProperties.length > 0 &&
                      seriesProps.additionalProperties.map((item) => {
                        return (
                          <option key={item.id} value={item.id}>
                            {item.value}
                          </option>
                        );
                      })}
                  </Form.Select>
                </Form.Group>
              )}
            </div>

            <Form.Group className="mb-3">
              <Form.Label>Артикул</Form.Label>
              <Form.Control
                type="text"
                value={formModel.article}
                required={true}
                onChange={(e) => {
                  setFormModel({
                    ...formModel,
                    article: e.target.value,
                  });
                }}
                placeholder="Например: номер штрихкода"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Название</Form.Label>
              <Form.Control
                type="text"
                value={formModel.name}
                onChange={(e) => {
                  setFormModel({
                    ...formModel,
                    name: e.target.value,
                  });
                }}
                placeholder="Например: IPhone 14 Pro Deep Perple 256 Gb"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Базовая цена</Form.Label>
              <Form.Control
                type="number"
                value={formModel.basePrice}
                required={true}
                onChange={(e) => {
                  setFormModel({
                    ...formModel,
                    basePrice: +e.target.value,
                  });
                }}
                placeholder="0"
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Описание</Form.Label>
              <Form.Control
                as="textarea"
                value={formModel.description}
                required={true}
                onChange={(e) => {
                  setFormModel({
                    ...formModel,
                    description: e.target.value,
                  });
                }}
                rows="6"
                placeholder="Краткое описание"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={submitBtn.variant}
              type="submit"
              className={submitBtn.disabled ? "disabled" : ""}
            >
              {submitBtn.label}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default Create;
