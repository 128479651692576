import React, { useEffect, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import {
  fetchBrand,
  fetchBrandCreate,
  fetchBrandList,
  fetchBrandUpdate,
} from "../../../data/models/brand/brand-api";
import ManageTable from "../../../components/admin/ManageTable";
import Create from "./Create";
import Brand from "../../../data/models/brand/brand";
import Update from "./Update";
import { fetchAdminGoods, fetchGoodCreate, fetchGoods, fetchToggleGoodActivity } from "../../../data/models/good/good-api";
import { priceToString } from "../../../helpers/priceToString";
import GoodShort from "../../../data/models/good/good";
import GoodImages from "./GoodImages";
import GoodParameters from "./GoodParameters";

function Goods() {
  const $fetch = {
    list: fetchAdminGoods,
    one: fetchBrand,
    create: fetchGoodCreate,
    update: fetchBrandUpdate
  };

  const [filter, setFilter] = useState({
    query: "",
    minPrice: 0,
    maxPrice: 0,
    categoryId: 0,
    brandId: 0,
    seriesId: 0,
    page: 1,
    perPage: 20,
    order: "desc",
    sort: "id"
  });
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const updateList = () => {
    $fetch.list(filter).then((response) => {
      if (response.status == 200) {
        let dto_list = [];
        for (let item of Array.from(response.data.rows)) {
          dto_list.push(new GoodShort(item));
        }
        setList(dto_list);
        setTotalCount(response.data.count);
      }
    });
  };


  const toggleActive = (goodId, active) => {
    fetchToggleGoodActivity({ goodId, active }).then((response) => {

    })
  }

  useEffect(() => {
    updateList();
  }, []);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">Управление товарами</h2>
      <Create fetchCreate={$fetch.create} updateList={updateList} />
      <ManageTable title={"Список производителей"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 140 }}>Картинка</th>
            <th style={{ width: 140 }}>Артикул</th>
            <th style={{ width: 300 }}>Название</th>
            <th style={{ width: 200 }}>Базовая цена</th>
            <th style={{ width: 200 }}>Отображать товар</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>
                    {item.image && <img src={item.image} width={120} alt="" />}
                  </td>
                  <td>{item.article}</td>
                  <td>{item.name}</td>
                  <td>{priceToString(item.price)} тг</td>
                  <td>
                    <div className="form-check">
                      <input className="form-check-input" type="checkbox" defaultChecked={item.isActive} onChange={(e) => {
                        toggleActive(item.id, e.target.checked)
                      }} />
                    </div>
                  </td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <GoodImages goodId={item.id} />
                        <GoodParameters goodId={item.id} />
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ManageTable>
    </>
  );
}

export default Goods;
