import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fetchCategories } from "../../../data/models/category/category-api";
import {
  fetchBrand,
  fetchBrandList,
} from "../../../data/models/brand/brand-api";
import { fetchGoods } from "../../../data/models/good/good-api";
import { fetchPartnerAppendGood } from "../../../data/models/partner/partner-api";

function ShopGoodAdd({ fetchCreate, updateList }) {
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [goods, setGoods] = useState([]);

  const handleClose = () => {
    setShow(false);
    setSubmitBtn({
      variant: "primary",
      disabled: false,
      label: "Сохранить",
    });
  };

  const handleShow = () => {
    fetchCategories({ query: "" }).then((response) => {
      if (response.status == 200) {
        setCategories(response.data);
      }
    });

    fetchBrandList({ query: "" }).then((response) => {
      if (response.status == 200) {
        setBrands(response.data.rows);
      }
    });
    setShow(true);
  };

  const [searchFormModel, setSearchFormModel] = useState({
    categoryId: 0,
    brandId: 0,
  });

  const [createFormModel, setCreateFormModel] = useState({
    partnerId: 0,
    goodId: 0,
    canPickup: false,
    pickupDays: 0,
    canDelivery: true,
    deliveryDays: 0,
    partnerPrice: 0,
    isActive: true,
  });

  const [submitBtn, setSubmitBtn] = useState({
    variant: "primary",
    disabled: false,
    label: "Сохранить",
  });

  const handleCreateForm = (e) => {
    e.preventDefault();
    fetchPartnerAppendGood(createFormModel).then((response) => {
      if (response.status == 200) {
        //
        setSubmitBtn({
          variant: "success",
          disabled: true,
          label: "Готово!",
        });
        setCreateFormModel({
          partnerId: 0,
          goodId: 0,
          canPickup: false,
          pickupDays: 0,
          canDelivery: true,
          deliveryDays: 0,
          partnerPrice: 0,
          isActive: true,
        });

        setSearchFormModel({
          categoryId: 0,
          brandId: 0,
        });
        setGoods([]);
        updateList();
        handleClose();
      } else {
        //

        setSubmitBtn({
          variant: "danger",
          disabled: false,
          label: "Ошибка. Попробуйте еще раз",
        });
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetchGoods({
      query: "",
      minPrice: 0,
      maxPrice: 0,
      categoryId: searchFormModel.categoryId,
      brandId: searchFormModel.brandId,
      seriesId: 0,
      page: 1,
      perPage: 20,
      order: "desc",
      sort: "id",
    }).then((response) => {
      if (response.status == 200) {
        setGoods(response.data.rows);
      }
    });
  };

  return (
    <>
      <Button variant="primary" className="mb-3" onClick={handleShow}>
        + Добавить товар
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Добавление товара</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Form onSubmit={(e) => handleSearch(e)}>
            <Form.Group className="mb-3">
              <Form.Label>Категория товара</Form.Label>
              <Form.Select
                required={true}
                aria-label="Категория товара"
                value={searchFormModel.categoryId}
                onChange={(e) => {
                  setSearchFormModel({
                    ...searchFormModel,
                    categoryId: +e.target.value,
                  });
                }}
              >
                <option value="">-Выберите категорию-</option>
                {categories.length > 0 &&
                  categories.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Производитель</Form.Label>
              <Form.Select
                aria-label="Категория товара"
                value={searchFormModel.brandId}
                required={true}
                onChange={(e) => {
                  setSearchFormModel({
                    ...searchFormModel,
                    brandId: +e.target.value,
                  });
                }}
              >
                <option value="">-Выберите производителя-</option>
                {brands.length > 0 &&
                  brands.map((item) => {
                    return (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
              </Form.Select>
            </Form.Group>
            <Button type="submit" className="mb-3">
              Получить список товаров
            </Button>
          </Form>

          {goods.length > 0 && (
            <Form onSubmit={(e) => handleCreateForm(e)}>
              <Form.Group className="mb-3">
                <Form.Label>Товар</Form.Label>
                <Form.Select
                  value={createFormModel.goodId}
                  required={true}
                  onChange={(e) => {
                    setCreateFormModel({
                      ...createFormModel,
                      goodId: +e.target.value,
                    });
                  }}
                >
                  <option value="">-Выберите товар-</option>
                  {goods.length > 0 &&
                    goods.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Ваша цена</Form.Label>
                <Form.Control
                  type="number"
                  value={createFormModel.partnerPrice}
                  required={true}
                  onChange={(e) => {
                    setCreateFormModel({
                      ...createFormModel,
                      partnerPrice: +e.target.value,
                    });
                  }}
                  placeholder="0"
                />
              </Form.Group>

              <Form.Group>
                <Form.Label>Доставка товара</Form.Label>
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Check
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={`Есть ли доставка на товар`}
                    onChange={(e) => {
                      setCreateFormModel({
                        ...createFormModel,
                        canDelivery: e.target.checked,
                      });
                    }}
                  />
                  <div>
                    <Form.Control
                      type="number"
                      size="sm"
                      style={{ width: 200 }}
                      placeholder="кол дней на доставку"
                      value={createFormModel.deliveryDays}
                      onChange={(e) => {
                        setCreateFormModel({
                          ...createFormModel,
                          deliveryDays: +e.target.value,
                        });
                      }}
                    />
                    <small>*если "сегодня" то 0</small>
                  </div>
                </div>
              </Form.Group>

              <Form.Group>
                <Form.Label>Самовывоз товара</Form.Label>
                <div className="d-flex align-items-center justify-content-between">
                  <Form.Check
                    type={"checkbox"}
                    id={`default-checkbox`}
                    label={`Можно ли самому забрать?`}
                    onChange={(e) => {
                      setCreateFormModel({
                        ...createFormModel,
                        canPickup: e.target.checked,
                      });
                    }}
                  />
                  <div>
                    <Form.Control
                      type="number"
                      size="sm"
                      style={{ width: 200 }}
                      placeholder="кол дней на подготовку"
                      value={createFormModel.pickupDays}
                      onChange={(e) => {
                        setCreateFormModel({
                          ...createFormModel,
                          pickupDays: +e.target.value,
                        });
                      }}
                    />
                    <small>*если "сегодня" то 0</small>
                  </div>
                </div>
              </Form.Group>
              <hr />
              <Button
                variant={submitBtn.variant}
                type="submit"
                className={submitBtn.disabled ? "disabled" : ""}
              >
                {submitBtn.label}
              </Button>
            </Form>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ShopGoodAdd;
