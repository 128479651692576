import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { fetchGoodParameters } from "../../../../data/models/good/good-api";

const Specifications = ({ setUpdate, goodId }) => {
  const [list, setList] = useState([]);

  const updateData = (goodId) => {
    fetchGoodParameters(goodId).then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };

  useEffect(() => {
    updateData(goodId);
    setUpdate((prev) => ({ ...prev, updateParameters: updateData }));
  }, []);

  return (
    <>
      {list.length > 0 &&
        list.map((s, s_index) => {
          return (
            <div className="specification-group" key={s_index}>
              <h5>{s.name}</h5>
              <table className="table">
                <tbody>
                  {s.parameters.length > 0 &&
                    s.parameters.map((si, si_index) => {
                      return (
                        <tr key={si_index}>
                          <td title={si.description}> {si.name}</td>
                          <td>{si.value}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          );
        })}
    </>
  );
};

export default Specifications;