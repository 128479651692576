import { $authHost, $host } from "../../http/axios-init";

export const fetchGoods = async (filter) => {
  try {
    const response = await $host.post(`/api/Good/GetGoods`, filter);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchCatalogGoods = async (filter) => {
  try {
    const response = await $host.post(`/api/Good/CatalogGetGoods`, filter);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchAdminGoods = async (filter) => {
  try {
    const response = await $host.post(`/api/Good/GetGoodsForAdmin`, filter);
    return response;
  } catch (e) {
    return e.response;
  }
};


export const fetchGood = async (id) => {
  try {
    const response = await $host.get("/api/good/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGoodImages = async (id) => {
  try {
    const response = await $host.get("/api/good/image/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGoodParameters = async (id) => {
  try {
    const response = await $host.get("/api/good/parameter/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGetPartnerOffers = async (goodId) => {
  try {
    const response = await $host.get(
      "/api/Partner/GetPartnersOfferForGood/" + goodId
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGoodSeriesColors = async (seriesId) => {
  try {
    const response = await $host.get("/api/Good/GetSeriesColors/" + seriesId);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGoodSeriesMemories = async (seriesId) => {
  try {
    const response = await $host.get("/api/Good/GetSeriesMemories/" + seriesId);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGoodSeriesAdditionalProperties = async (seriesId) => {
  try {
    const response = await $host.get(
      "/api/Good/GetSeriesAdditionalProperties/" + seriesId
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGetGoodByProperties = async ({
  goodId,
  memoryId,
  colorId,
  additionalPropertyId,
}) => {
  try {
    const response = await $host.get(
      `/api/Good/GetGoodByProperties/${goodId}/${colorId}/${memoryId}/${additionalPropertyId}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGoodsForGood = async (goodId) => {
  try {
    const response = await $host.get("/api/Good/GetGoodsForSeries/" + goodId);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGoodComments = async (goodId) => {
  try {
    const response = await $host.get("/api/Good/GetGoodComments/" + goodId);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchSetAnswerForGoodComment = async (fd) => {
  try {
    const response = await $authHost.post(
      "/api/Good/SetAnswerForGoodComments",
      fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchSetCreateGoodComment = async (fd) => {
  try {
    const response = await $authHost.post("/api/Good/CreateGoodComment", fd);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterGroups = async () => {
  try {
    const response = await $authHost.get("/api/parameter/group");
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterGroup = async (id) => {
  try {
    const response = await $authHost.get("/api/parameter/group/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterGroupCreate = async (fd) => {
  try {
    const response = await $authHost.post("/api/parameter/group", fd);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterGroupUpdate = async (fd) => {
  try {
    const response = await $authHost.put("/api/parameter/group", fd);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterGroupDelete = async (id) => {
  try {
    const response = await $authHost.delete("/api/parameter/group/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameters = async (id) => {
  try {
    const response = await $authHost.get(
      "/api/parameter?parameterGroupId=" + id
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterCreate = async (fd) => {
  try {
    const response = await $authHost.post("/api/parameter", fd);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchCategoryParameterGroups = async (id) => {
  try {
    const response = await $authHost.get(
      "/api/Parameter/categoryParameter?categoryId=" + id
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterGroupCategories = async (id) => {
  try {
    const response = await $authHost.get(
      "/api/Parameter/parameterCategories/" + id
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterSetCategory = async (fd) => {
  try {
    const response = await $authHost.post(
      "/api/parameter/categoryParameter",
      fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchParameterUnsetCategory = async ({
  categoryId,
  parameterGroupId,
}) => {
  try {
    const response = await $authHost.delete(
      `/api/parameter/categoryParameter/${categoryId}/${parameterGroupId}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
};


export const fetchGoodCreate = async (fd) => {
  try {
    const response = await $authHost.post(
      "/api/good",
      fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGoodImageDelete = async (id) => {
  try {
    const response = await $authHost.delete(
      `/api/good/image?id=` + id
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchGoodImageCreate = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/good/image`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchGetGoodParameterGroups = async (id) => {
  try {
    const response = await $authHost.get(
      "/api/Parameter/GetGoodParameterGroups?goodId=" + id
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchGoodSetParameter = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/good/parameter`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchGetGoodSeries = async () => {
  try {
    const response = await $authHost.get(
      "/api/good/GetGoodSeries"
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchGetGoodSeriesProperties = async (id) => {
  try {
    const response = await $authHost.get(
      "/api/good/GetGoodSeriesProperties/" + id
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCreateGoodSeries = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/good/CreateGoodSeries`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchUpdateGoodSeries = async (fd) => {
  try {
    const response = await $authHost.put(
      `/api/good/UpdateGoodSeries`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCreateGoodSeriesColor = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/good/CreateGoodSeriesColor`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCreateGoodSeriesMemory = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/good/CreateGoodSeriesMemory`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCreateGoodSeriesAdditionalProperty = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/good/CreateGoodSeriesAdditionalProperty`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchToggleGoodActivity = async ({ goodId, active }) => {
  try {
    const response = await $authHost.post(
      `/api/good/ToggleActive/${goodId}/${active}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCreateGoodSeriesProduct = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/good/CreateGoodSeriesProducts`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCreateGoodSeriesImages = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/goodseries/addimages`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchGetGoodSeriesParameters = async (id) => {
  try {
    const response = await $authHost.get('/api/goodseries/GetGoodSeriesParameters?seriesId=' + id)
    return response
  } catch (e) {
    return e.response
  }
}

export const fetchGoodSeriesSetParameter = async (fd) => {
  try {
    const response = await $authHost.post(
      `/api/goodseries/AddParameters`, fd
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchGetGoodSeriesByCategory = async (id) => {
  try {
    const response = await $host.get('/api/good/GetGoodSeriesByCategory?categoryId=' + id)
    return response
  } catch (e) {
    return e.response
  }
}

export const fetchGetBrandsByCategory = async (id) => {
  try {
    const response = await $host.get('/api/good/GetBrandsByCategory?brandId=' + id)
    return response
  } catch (e) {
    return e.response
  }
}

export const fetchSetPriceForSeries = async (seriesId, price) => {
  try {
    const response = await $authHost.post(`/api/good/SetPriceForSeries?seriesId=${seriesId}&price=${price}`)
    return response
  } catch (e) {
    return e.response
  }
}

export const fetchGetCurrency = async (date) => {
  try {
    const response = await $authHost.get('/api/good/GetCurrency/' + date)
    return response
  } catch (e) {
    return e.response
  }
}

export const fetchGetSeriesPrices = async (seriesId) => {
  try {
    const response = await $authHost.get('/api/good/GetSeriesPrice/' + seriesId)
    return response
  } catch (e) {
    return e.response
  }
}