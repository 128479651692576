import React, { useEffect, useState } from "react";
import { fetchGetPartnerGoods, fetchToggleGoodActive } from "../../../data/models/partner/partner-api";
import { API_URL } from "../../../data/http/axios-init";
import { priceToString } from "../../../helpers/priceToString";
import ShopGoodAdd from "./ShopGoodAdd";

function ShopGoods() {
  const [list, setList] = useState([]);
  useEffect(() => {
    updateList()
  },[]);

  const updateList = ()=>{
    fetchGetPartnerGoods().then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  }

  return (
    <>
      <h4 className="fw-bold">Товары</h4>
    <ShopGoodAdd fetchCreate={()=>{}} updateList={updateList}/>
      <table className="table">
        <thead>
          <tr>
            <th style={{width:100}}>Картинка</th>
            <th style={{width:130}}>Артикул</th>
            <th style={{width:400}}>Наименование</th>
            <th style={{width:200}}>Цена</th>
            <th>Есть в наличии</th>
          </tr>
        </thead>
        <tbody>
        {
          list.length>0 && list.map((item)=>{
            return <tr key={item.id}>
              <td>
                <img src={API_URL+item.image} style={{width:70,aspectRatio:'1 / 1',objectFit:'contain'}} alt="" />
              </td>
              <td>{item.article}</td>
              <td>
                {item.name}
              </td>
              <td>{priceToString(item.basePrice)} тг</td>
              <td>
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" 
                defaultChecked={item.isActive}
                onChange={(e)=>fetchToggleGoodActive({goodId:item.id,isActive:e.target.checked})}
                 role="switch" id="flexSwitchCheckDefault"/>
              </div>
              </td>
            </tr>
          })
        }
        </tbody>
      </table>
    </>
  );
}

export default ShopGoods;
