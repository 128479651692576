import React, { useEffect, useState } from "react";
import { fetchDeleteUserLocation, fetchGetUserLocations } from "../../../data/http/auth-api";
import { Button } from "react-bootstrap";
import CabinetDeliveryPointsAddModal from "./CabinetDeliveryPointsAddModal";
import CabinetDeliveryPointsUpdateModal from "./CabinetDeliveryPointsUpdateModal";

function CabinetDeliveryPoints() {
  const [list, setList] = useState([]);

  const updateData = ()=>{
    fetchGetUserLocations().then((response) => {
        if (response.status == 200) {
          setList(response.data);
        }
      });
  }
  useEffect(() => {
    updateData()
  }, []);

  return (
    <>
      <h5 className="fw-bold">Мои адреса доставки</h5>

      <div className="cabinet-delivery-points">
        {list.length > 0 &&
          list.map((point) => {
            return (
              <div className="item" key={point.id}>
                <h5>{point.title}</h5>
                <p>
                  {point.street}, {point.house}, {point.flat}
                </p>
                <CabinetDeliveryPointsUpdateModal updateData={updateData} data={point}/>
                <Button size="sm" variant="danger" onClick={()=>{
                    fetchDeleteUserLocation(point.id).then((response)=>{
                        if(response.status==200) {
                            updateData()
                        }
                    })
                }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-trash3-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                  </svg>
                </Button>
              </div>
            );
          })}
          <div className="item d-flex align-items-center">
            <CabinetDeliveryPointsAddModal updateData={updateData}/>
          </div>
      </div>
    </>
  );
}

export default CabinetDeliveryPoints;
