import React, { useEffect, useState } from 'react'
import ManageTable from '../../../components/admin/ManageTable';
import { Dropdown } from 'react-bootstrap';
import { fetchCityCreate, fetchCityDelete, fetchCityUpdate, fetchGetCities, fetchGetCity } from '../../../data/models/city/city-api';
import CreateCity from './CreateCity';
import UpdateCity from './UpdateCity';
import DeleteCity from './DeleteCity';
import { ADMIN_CITY_DISTRICTS } from '../../../constants/routes';
import { Link } from 'react-router-dom';

function Cities() {
  const $fetch = {
    list: fetchGetCities,
    one: fetchGetCity,
    create: fetchCityCreate,
    update: fetchCityUpdate,
    delete: fetchCityDelete,
  };

  const [filter, setFilter] = useState({
    query: "",
  });
  const [list, setList] = useState([]);

  const updateList = () => {
    $fetch.list(filter.query).then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, [filter]);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">Управление городами и районами</h2>
      <CreateCity fetchCreate={$fetch.create} updateList={updateList} />
      <ManageTable title={"Список городов"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Название</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {
                            item.id == 1 && <Dropdown.Item><Link to={ADMIN_CITY_DISTRICTS+"/"+item.id}>Районы города</Link></Dropdown.Item>
                        }
                         <UpdateCity    
                          fetchOne={$fetch.one}
                          fetchUpdate={$fetch.update}
                          id={item.id}
                          updateList={updateList}
                        />
                        <DeleteCity
                          fetchDelete={$fetch.delete}
                          id={item.id}
                          name={item.name}
                          updateList={updateList}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ManageTable>
    </>
  );
}

export default Cities