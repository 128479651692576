import React from "react";
import { Outlet } from "react-router-dom";
import Aside from "../components/admin/Aside";
import AdminHeader from "../components/admin/AdminHeader";

function AdminLayout() {
  return (
    <>
      <div id="admin">
        <AdminHeader />
        <div className="d-flex">
          <Aside />
          <div className="admin-content">
            <div className="admin-content-container">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminLayout;
