import React from "react";
import { Accordion, Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function FAQ() {
  const { t } = useTranslation();

  const list = [
    { questionKey: "faq.question1", answerKey: "faq.answer1" },
    { questionKey: "faq.question2", answerKey: "faq.answer2" },
    { questionKey: "faq.question3", answerKey: "faq.answer3" },
    { questionKey: "faq.question4", answerKey: "faq.answer4" },
    { questionKey: "faq.question5", answerKey: "faq.answer5" },
    { questionKey: "faq.question6", answerKey: "faq.answer6" },
    { questionKey: "faq.question7", answerKey: "faq.answer7" },
    { questionKey: "faq.question8", answerKey: "faq.answer8" },
    { questionKey: "faq.question9", answerKey: "faq.answer9" },
    { questionKey: "faq.question10", answerKey: "faq.answer10" },
  ];

  return (
    <Container className="pt-4" id="faq">
      <h1 className="section-title">{t("faq.title")}</h1>

      <Accordion defaultActiveKey="0">
        {list.map((item, index) => {
          return (
            <Accordion.Item eventKey={index} key={index}>
              <Accordion.Header>{t(item.questionKey)}</Accordion.Header>
              <Accordion.Body>{t(item.answerKey)}</Accordion.Body>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </Container>
  );
}

export default FAQ;
