import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fetchCheckSMSCode, fetchGetOrderInfo, fetchIssueGoodsForOrder, fetchSendSMSCode } from "../../../data/models/partner/partner-api";

function IssueGoods({orderId, update }) {
  const [show, setShow] = useState(false);
  const [goods,setGoods] = useState([])
  const [issueGoods,setIssueGoods] = useState([])
  const handleClose = () => setShow(false);
  const handleShow = () => {
    updateGoods()
    setShow(true);
  };

  const updateGoods = ()=>{
    fetchGetOrderInfo(orderId).then((response)=>{
      if(response.status==200) {
        setGoods(response.data.goods)
      }
    })
  }
  
  const handleForm = (e)=>{
    e.preventDefault();
    fetchSendSMSCode(orderId).then((response_1)=>{
      if(response_1.status==200) {
        let code = prompt("На номер клиента отправлен СМС с кодом.\n Введите этот код: ")
        fetchCheckSMSCode({orderId,code}).then((response_2)=>{
          if(response_2.status==200) {
            fetchIssueGoodsForOrder({
              orderId,
              goods:issueGoods
            }).then((response_3)=>{
              if(response_3.status==200) {
                alert('Товары выданы');
                update();
                handleClose();
              }
            })
          } else {
            alert('Код не совпадает попробуйте еще раз')
          }
        })
      }
    })
  }

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        Выдать товары клиенту
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Выдача товара</Modal.Title>
        </Modal.Header>
     <Form onSubmit={(e)=>handleForm(e)}>
     <Modal.Body>
          <p>Выберите товары которые сейчас будете выдавать</p>
          <ul className="list-group">
            {goods &&
              goods.length > 0 &&
              goods.map((item) => {
                if(item.isIssued) return <></>
                return (
                  <li key={item.id} className="list-group-item d-flex">
                    <Form.Check
                      type={"checkbox"}
                      label={item.name}
                      onChange={(e) => {
                        if(e.target.checked) {
                          setIssueGoods((prev)=>([...prev,item.id]))
                        } else {
                          setIssueGoods((prev)=>([...prev.filter(m=>m!=item.id)]))
                        }
                      }}
                    />
                  </li>
                );
              })}
          </ul>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="primary" type="submit">
            Выдать
          </Button>
        </Modal.Footer>
     </Form>
      </Modal>
    </>
  );
}

export default IssueGoods;
