import React, { useState } from 'react';

const RatingStars = ({rating,setRating}) => {

  const handleStarClick = (selectedRating) => {
    setRating(selectedRating);
  };

  return (
    <div>
      {[1, 2, 3, 4, 5].map((star) => (
        <Star
          key={star}
          filled={star <= rating}
          onClick={() => handleStarClick(star)}
        />
      ))}
    </div>
  );
};

const Star = ({ filled, onClick }) => {

  return (
    <span className={'rating-star ' + (filled ? 'selected':'')}  onClick={onClick}>
      &#9733;
    </span>
  );
};

export default RatingStars;