import React, { useContext } from "react";
import { Context } from "../../..";
import GoodShortItem from "../Good/GoodShortItem";
import { observer } from "mobx-react-lite";

function CabinetFavourites() {
  const { store } = useContext(Context);

  return (
    <>
     <h5 className="fw-bold">Избранное</h5>
     {
      store.favourites.goods.length==0 && <p>Тут пока нет избранных товаров</p>
     }
      {store.favourites.goods && store.favourites.goods.length > 0 && (
        <>
          <h5 className="fw-bold">Товары</h5>
          <div className="cabinet-favourite-goods">
            {store.favourites.goods.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <GoodShortItem key={index} item={item} />
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}

export default observer(CabinetFavourites);
