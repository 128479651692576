import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../..";
import { Button, Form, FormSelect } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { fetchGetCityDistricts } from "../../../data/models/city/city-api";
import { fetchGetUserLocations } from "../../../data/http/auth-api";
import { useTranslation } from 'react-i18next';

function BasketDeliveryCity() {
  const { store } = useContext(Context);
  const { t } = useTranslation();

  const [almatyDistricts, setAlmatyDistricts] = useState([]);
  const [userAddresses, setUserAddresses] = useState([]);
  const [selectedUserAddress, setSelectedUserAddress] = useState(0);

  useEffect(() => {
    fetchGetCityDistricts({ cityId: 1, query: "" }).then((response) => {
      if (response.status === 200) {
        setAlmatyDistricts(response.data);
      }
    });
    fetchGetUserLocations().then((response) => {
      if (response.status === 200) {
        setUserAddresses(response.data);
      }
    });
  }, []);

  const [deliveryCityAddress, setDeliveryCityAddress] = useState({
    district: 0,
    street: "",
    house: "",
    flat: "",
    floor: "",
  });

  const handleDeliveryCity = () => {
    store.setDelivery({
      type: "delivery-city",
      price: 0,
      address: deliveryCityAddress,
    });
  };

  return (
    <>
      {userAddresses.length > 0 && (
        <>
          <p className="mb-1">{t('basketDeliveryCity.savedAddresses')}</p>
          <div className="d-flex mb-3" style={{ gap: 6 }}>
            {userAddresses.map((item, index) => {
              return (
                <button
                  key={index}
                  className={
                    "my-address-button " +
                    (selectedUserAddress === item.id ? "active" : "")
                  }
                  onClick={() => {
                    setDeliveryCityAddress({
                      district: item.districtId,
                      street: item.street,
                      house: item.house,
                      flat: item.flat,
                      floor: item.floor,
                    });
                    setSelectedUserAddress(item.id);
                  }}
                >
                  <h5>{item.title}</h5>
                  <p>
                    {item.street}, {item.house}, {item.flat}
                  </p>
                </button>
              );
            })}
          </div>
        </>
      )}
      <Form.Group controlId="delivery-city-district" className="mb-3">
        <Form.Label>{t('basketDeliveryCity.district')}</Form.Label>
        <FormSelect
          value={deliveryCityAddress.district}
          onChange={(e) => {
            setSelectedUserAddress(0);
            setDeliveryCityAddress({
              ...deliveryCityAddress,
              district: +e.target.value,
            });
          }}
        >
          <option value="">-{t('basketDeliveryCity.selectAlmatyDistrict')}</option>
          {almatyDistricts.length > 0 &&
            almatyDistricts.map((item, index) => (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            ))}
        </FormSelect>
      </Form.Group>
      <div className="d-flex" style={{ gap: 20 }}>
        <Form.Group className="flex-grow-1" controlId="delivery-city-street">
          <Form.Label>{t('basketDeliveryCity.street')}</Form.Label>
          <Form.Control
            type="text"
            autoComplete="off"
            value={deliveryCityAddress.street}
            onChange={(e) => {
              setSelectedUserAddress(0);
              setDeliveryCityAddress({
                ...deliveryCityAddress,
                street: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group controlId="delivery-city-street">
          <Form.Label>{t('basketDeliveryCity.houseNumber')}</Form.Label>
          <Form.Control
            type="text"
            value={deliveryCityAddress.house}
            onChange={(e) => {
              setSelectedUserAddress(0);
              setDeliveryCityAddress({
                ...deliveryCityAddress,
                house: e.target.value,
              });
            }}
          />
        </Form.Group>
        <Form.Group controlId="delivery-city-street">
          <Form.Label>{t('basketDeliveryCity.flat')}</Form.Label>
          <Form.Control
            type="text"
            value={deliveryCityAddress.flat}
            onChange={(e) => {
              setSelectedUserAddress(0);
              setDeliveryCityAddress({
                ...deliveryCityAddress,
                flat: e.target.value,
              });
            }}
          />
          <small>*{t('basketDeliveryCity.indicateOneIfPrivateHouse')}</small>
        </Form.Group>
        <Form.Group style={{ width: 80 }} controlId="delivery-city-street">
          <Form.Label>{t('basketDeliveryCity.floor')}</Form.Label>
          <Form.Control
            type="text"
            value={deliveryCityAddress.floor}
            onChange={(e) => {
              setSelectedUserAddress(0);
              setDeliveryCityAddress({
                ...deliveryCityAddress,
                floor: e.target.value,
              });
            }}
          />
        </Form.Group>
      </div>
      {deliveryCityAddress.district > 0 &&
        deliveryCityAddress.street.length > 0 &&
        deliveryCityAddress.house.length > 0 && (
          <Button onClick={() => handleDeliveryCity()}>
            {t('basketDeliveryCity.confirmAddresses')}
          </Button>
        )}
    </>
  );
}

export default observer(BasketDeliveryCity);
