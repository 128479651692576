import React, { useContext } from 'react'
import { Container, Dropdown } from 'react-bootstrap'
import { ABOUT, BE_PARTNER, SUPPORT } from '../../../constants/routes'
import { Link } from 'react-router-dom'
import { Context } from '../../..'
import { observer } from 'mobx-react-lite'
import { useTranslation } from 'react-i18next'

function PublicHeader() {
    const { store } = useContext(Context)
    const { i18n, t } = useTranslation();

    const changeLanguage = (lng, e) => {
        e.preventDefault()
        store.setLanguage(lng)
        i18n.changeLanguage(lng);
        if (store.language == "kz" || store.language == "ch") {
            document.body.style.fontFamily = 'Segoe UI';
        } else {
            document.body.style.fontFamily = 'Manrope'
        }
    };
    return (
        <header id="public-header" >
            <Container>
                <div className="d-flex justify-content-between align-items-center">
                    <ul>
                        <li>
                            <Link to={SUPPORT}>{t("support")}</Link>
                        </li>
                        <li>
                            <a href="#" className={store.selectedCurrency == "kzt" ? 'active' : ''} onClick={(e) => {
                                e.preventDefault();
                                store.setSelectedCurrency('kzt')
                            }} title={t("show_all_price_in_tenge")}>
                                KZT
                            </a>
                        </li>
                        <li>
                            <a href="#" className={store.selectedCurrency == "usd" ? 'active' : ''} onClick={(e) => {
                                e.preventDefault();
                                store.setSelectedCurrency('usd')
                            }} title={t("show_all_price_in_dollar")}>
                                USD
                            </a>
                        </li>
                        <li>
                            <a href="#" className={store.selectedCurrency == "rub" ? 'active' : ''} onClick={(e) => {
                                e.preventDefault();
                                store.setSelectedCurrency('rub')
                            }} title={t("show_all_price_in_rub")}>
                                RUB
                            </a>
                        </li>
                        <li>
                            <a href="#" className={store.selectedCurrency == "cny" ? 'active' : ''} onClick={(e) => {
                                e.preventDefault();
                                store.setSelectedCurrency('cny')
                            }} title={t("show_all_price_in_cny")}>
                                CNY
                            </a>
                        </li>
                        {/* <li>
                            <Link to={BE_PARTNER}>Стать партнером</Link>
                        </li> */}
                    </ul>
                    <ul className='no-mobile'>
                        <li>
                            <a className='phone' href="tel:+77479404418"><span className='no-mobile'>
                                {t("hot_line")}:</span> <span>+7 (747) 940-44-18</span></a>
                        </li>
                        <li>
                            <Link to={ABOUT}>{t("about_shop")}</Link>
                        </li>
                        <li className='d-flex' style={{ gap: 10 }}>
                            <Dropdown.Item className={store.language == 'kz' ? 'active' : ''} href="#" onClick={(e) => changeLanguage('kz', e)}>KZ</Dropdown.Item>
                            <Dropdown.Item className={store.language == 'ru' ? 'active' : ''} href="#" onClick={(e) => changeLanguage('ru', e)}>RU</Dropdown.Item>
                            <Dropdown.Item className={store.language == 'en' ? 'active' : ''} href="#" onClick={(e) => changeLanguage('en', e)}>EN</Dropdown.Item>
                            <Dropdown.Item className={store.language == 'ch' ? 'active' : ''} href="#" onClick={(e) => changeLanguage('ch', e)}>CH</Dropdown.Item>
                        </li>
                    </ul>
                </div>
            </Container>
        </header>
    )
}

export default observer(PublicHeader)