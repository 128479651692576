import { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  fetchGetGoodParameterGroups,
  fetchGoodImageCreate,
  fetchGoodImageDelete,
  fetchGoodImages,
  fetchGoodSetParameter,
} from "../../../data/models/good/good-api";
import { API_URL } from "../../../data/http/axios-init";

function GoodParameters({ goodId }) {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    update();
    setShow(true);
  };

  const update = () => {
    fetchGetGoodParameterGroups(goodId).then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };


  const handleChange = (value,id)=>{
    fetchGoodSetParameter({
        id:0,
        goodId,
        parameterId:id,
        value
    })
  }

  return (
    <>
      <Dropdown.Item onClick={handleShow}>Настройки параметров</Dropdown.Item>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Настройки параметров</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Наименование параметра</th>
                <th>Значение</th>
              </tr>
            </thead>
            <tbody>
              {list.length > 0 &&
                list.map((item_1) => {
                  return (
                    <>
                      <tr key={item_1.id}>
                        <td colSpan={2} className="fw-bold bg-success bg-opacity-25">
                          {item_1.name}
                        </td>
                      </tr>
                      {item_1.parameters.length > 0 &&
                        item_1.parameters.map((item_2) => {
                          return (
                            <tr key={item_2.id}>
                              <td>{item_2.name}</td>
                              <td>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  defaultValue={item_2.value}
                                  onChange={(e)=>handleChange(e.target.value,item_2.id)}
                                />
                              </td>
                            </tr>
                          );
                        })}
                    </>
                  );
                })}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GoodParameters;
