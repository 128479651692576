import { $authHost, $host } from "../../http/axios-init"

export const fetchGetPartnerById = async (id)=>{
    try {
        const response = await $host.get('/api/Partner/GetPartnerById/'+id)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchGetMyShopInfo = async ()=>{
    try {
        const response = await $authHost.get('/api/Partner/GetMyShopInfo')
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchGetOrdersForShop = async (fd)=>{
    try {
        const response = await $authHost.post('/api/Partner/GetOrdersForShop',fd)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchGetOrderInfo = async(id)=>{
    try {
        const response = await $authHost.get('/api/Partner/GetOrderInfoForShop/'+id)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchGetStatusLogsForOrder = async(id)=>{
    try {
        const response = await $authHost.get('/api/Partner/GetStatusLogsForOrder/'+id)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchSetStatusForOrder = async(fd)=>{
    try {
        const response = await $authHost.post('/api/Partner/SetStatusForOrder',fd)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchGetPartnerGoods = async()=>{
    try {
        const response = await $authHost.get('/api/Partner/GetPartnerGoods')
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchPartnerAppendGood = async(fd)=>{
    try {
        const response = await $authHost.post('/api/Partner/appendGood',fd)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchSendSMSCode = async(orderId)=>{
    try {
        const response = await $authHost.post('/api/Partner/SendSMSForConfirm?orderId='+orderId)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchCheckSMSCode = async(fd)=>{
    try {
        const response = await $authHost.post('/api/Partner/CheckSMSCode',fd)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchIssueGoodsForOrder = async(fd)=>{
    try {
        const response = await $authHost.post('/api/Partner/IssueGoodsForOrder',fd)
        return response
    } catch(e) {
        return e.response
    }
}


export const fetchToggleGoodActive = async({goodId,isActive})=>{
    try {
        const response = await $authHost.post('/api/Partner/ToggleGoodActive?goodId='+goodId+'&isActive='+isActive)
        return response
    } catch(e) {
        return e.response
    }
}

export const fetchGetOrdersReport = async(deliveryTypeId)=>{
    try {
        const response = await $authHost.get('/api/Partner/GetReportOrders?deliveryTypeId='+deliveryTypeId)
        return response
    } catch(e) {
        return e.response
    }
}