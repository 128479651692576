import React from 'react';
import { Formik, Form, Field } from 'formik';
import { Container, Button } from 'react-bootstrap';
import ReactInputMask from 'react-input-mask';
import { fetchSendSupportRequest } from '../../data/models/support-api';
import { phoneToString } from '../../helpers/phoneToString';

function Support() {


  const requestTypes = [
    { id: 1, name: 'Ошибка в работе сайта' },
    { id: 2, name: 'Предложения по улучшению' },
    { id: 3, name: 'Помощь с заказом' },
  ];
  
  const initialValues = {
    clientEmail: '',
    clientPhone: '',
    clientName: '',
    requestType: 0,
    comment: '',
  };

  const onSubmit = (values,{resetForm }) => {
    fetchSendSupportRequest({
      ...values,
      requestType:+values.requestType,
      clientPhone:phoneToString(values.clientPhone)
    }).then((response)=>{
      if(response.status==200) {
        resetForm()
      }
    })
  };
  

  return (
    <Container className="pt-3">
      <h2 className="section-title">
        Тех поддержка 
      </h2>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <Form className='support-form'>
        <label className='form-label' htmlhtmlFor="clientName">Имя пользователя</label>
        <Field type="text" id="clientName" name="clientName" placeholder="Ваше имя" className="form-control mb-3" />

        <label className='form-label' htmlhtmlFor="clientPhone">Номер пользователя</label>
        <Field name="clientPhone" >
          {({ field }) => (
            <ReactInputMask
              {...field}
              mask="+7 (999) 999-99-99"
              placeholder="+7 (___) ___-__-__"
              className="form-control mb-3"
            />
          )}
        </Field>

        <label className='form-label' htmlhtmlFor="clientEmail">Почта пользователя</label>
        <Field type="email" id="clientEmail" name="clientEmail" className="form-control mb-3" />

        <label className='form-label' htmlhtmlFor="requestType">Тип обращения</label>
        <Field as="select" id="requestType" name="requestType" className="form-control mb-3">
          <option value="">Выберите тип</option>
          {requestTypes.map((type) => (
            <option key={type.id} value={type.id}>
              {type.name}
            </option>
          ))}
        </Field>

        <label className='form-label' htmlhtmlFor="comment"  >Текст обращения</label>
        <Field
          as="textarea"
          id="comment"
          name="comment"
          rows="4"
          placeholder="Опишите подробнее о проблеме или прделожении"
          className="form-control mb-3"
        />

        <Button type="submit" variant="primary" className="mt-3">
          Отправить обращение
        </Button>
      </Form>
    </Formik>
    </Container>
  )
}

export default Support