import { $authHost, $host } from "../../http/axios-init";

export const fetchBrandList = async (filter) => {
  try {
    const response = await $host.get("/api/brand?query=" + filter.query);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchBrand = async (id) => {
  try {
    const response = await $host.get("/api/brand/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchBrandCreate = async (formData) => {
  try {
    const response = await $authHost.post("/api/brand", formData);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchBrandUpdate = async (formData) => {
  try {
    const response = await $authHost.put("/api/brand", formData);
    return response;
  } catch (e) {
    return e.response;
  }
};
export const fetchBrandDelete = async (id) => {
  try {
    const response = await $authHost.delete("/api/brand/" + id);
    return response;
  } catch (e) {
    return e.response;  
  }
};
