import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { fetchGetOrdersReport } from "../../../data/models/partner/partner-api";
import OrdersList from "./OrdersList";

function Orders() {
  const [report, setReport] = useState(null);

  const getReport = (id)=>{
    fetchGetOrdersReport(id).then((response)=>{
        if(response.status==200) {
            setReport(response.data)
        }
    })
  }

  useEffect(()=>{
    getReport(1)
  },[])

  return (
    <>
      <h5 className="fw-bold">Отчет по заказам</h5>
      <Tabs defaultActiveKey="1" id="shop-report-tabs" onSelect={(k) => getReport(+k)}>
        <Tab eventKey="1" title="Самовывоз" onClick={()=>getReport(1)}>
          {report && report.deliveryType.id == 1 && <OrdersList report={report}/>}
        </Tab>
        <Tab eventKey="2" title="Доставка по городу" onClick={()=>getReport(2)}>
          {report && report.deliveryType.id == 2 && <OrdersList report={report}/>}
        </Tab>
        <Tab eventKey="3" title="Доставка по стране" onClick={()=>getReport(3)}>
          {report && report.deliveryType.id == 3 && <OrdersList report={report}/>}
        </Tab>
      </Tabs>
    </>
  );
}

export default Orders;
