import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Delete({
    fetchDelete,
    id,
    updateList
}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Dropdown.Item onClick={handleShow} className="text-danger">
        Удалить
      </Dropdown.Item>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Удаление производителя</Modal.Title>
        </Modal.Header>
        <Modal.Body>

        

        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" type="submit">
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Delete;
