import React, { useEffect, useState } from "react";
import ManageTable from "../../../components/admin/ManageTable";
import { Dropdown } from "react-bootstrap";
import {
  fetchCityDistrictCreate,
  fetchCityDistrictDelete,
  fetchCityDistrictUpdate,
  fetchGetCity,
  fetchGetCityDistrict,
  fetchGetCityDistricts,
} from "../../../data/models/city/city-api";
import { useParams } from "react-router-dom";
import CreateCityDistrict from "./CreateCityDistrict";
import UpdateCityDistrict from "./UpdateCityDistrict";
import DeleteCityDistrict from "./DeleteCityDistrict";

function CityDistricts() {
  const { id } = useParams();

  const $fetch = {
    list: fetchGetCityDistricts,
    one: fetchGetCityDistrict,
    create: fetchCityDistrictCreate,
    update: fetchCityDistrictUpdate,
    delete: fetchCityDistrictDelete,
  };

  const [list, setList] = useState([]);
  const [city, setCity] = useState(null);
  const updateList = () => {
    fetchGetCity(id).then((response) => {
      setCity(response.data);
    });
    $fetch
      .list({
        cityId: id,
        query: "",
      })
      .then((response) => {
        if (response.status == 200) {
          setList(response.data);
        }
      });
  };

  useEffect(() => {
    updateList();
  }, []);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">Управление районами город {city && city.name}</h2>
      <CreateCityDistrict
        fetchCreate={$fetch.create}
        updateList={updateList}
        id={id}
      />
      <ManageTable title={"Список городов"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Название</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <UpdateCityDistrict
                          fetchOne={$fetch.one}
                          fetchUpdate={$fetch.update}
                          id={item.id}
                          updateList={updateList}
                        />
                        <DeleteCityDistrict
                          fetchDelete={$fetch.delete}
                          id={item.id}
                          name={item.name}
                          updateList={updateList}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ManageTable>
    </>
  );
}

export default CityDistricts;
