import { Fragment, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { fetchCategories } from '../../../data/models/category/category-api';
import { fetchBrandList } from '../../../data/models/brand/brand-api';
import { fetchCreateGoodSeriesProduct, fetchGetGoodSeriesProperties, fetchGoodSeriesMemories } from '../../../data/models/good/good-api';
import GoodSeriesProductPrice from './GoodSeriesProductPrice';
import GoodSeriesPriceItem from './GoodSeriesPriceItem';
function GoodSeriesProduct({ seriesId }) {
    const [show, setShow] = useState(false);
    const [categories, setCategories] = useState([])
    const [brands, setBrands] = useState([])
    const [memories, setMemories] = useState([])
    const [colors, setColors] = useState([])
    const [addProps, setAddProps] = useState([])

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [formModel, setFormModel] = useState({
        categoryId: 0,
        brandId: 0,
        seriesId,
        name: '',
        description: '',
        pricing: []
    })

    useEffect(() => {
        fetchCategories({ query: '' }).then((response) => {
            if (response.status == 200) {
                setCategories(response.data)
            }
        })

        fetchBrandList({ query: '' }).then((response) => {
            if (response.status == 200) {
                setBrands(response.data.rows)
            }
        })

        fetchGetGoodSeriesProperties(seriesId).then((response) => {
            if (response.status == 200) {
                setMemories(response.data.memories)
                setColors(response.data.colors)
                setAddProps(response.data.additionalProperties)
            }
        })
    }, [])

    const handleForm = () => {
        formModel.pricing = Array.from(document.getElementsByName('price'))
            .map((m) => {
                return {
                    price: +m.value,
                    MemoryId: +m.getAttribute('memoryId'),
                    ColorId: +m.getAttribute('colorId'),
                    RegionId: +m.getAttribute('addPropId')
                }
            }).filter(m => m.price > 0)


        if (formModel.pricing.length > 0) {
            fetchCreateGoodSeriesProduct(formModel).then((response) => {
                if (response.status == 200) {
                }
            })
        }

    }

    return (
        <>
            <div onClick={handleShow}>
                Добавить товары
            </div>

            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Добавление товара в серию</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className='pt-3'>
                            <Form.Label>Категория</Form.Label>
                            <Form.Select value={formModel.categoryId} onChange={(e) => {
                                setFormModel((prev) => ({
                                    ...prev,
                                    categoryId: +e.target.value
                                }))
                            }}>
                                <option value="">-выберите</option>
                                {categories.length > 0 && categories.map((c, i) => {
                                    return <option key={i} value={c.id}>{c.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='pt-3'>
                            <Form.Label>Производитель</Form.Label>
                            <Form.Select value={formModel.brandId} onChange={(e) => {
                                setFormModel((prev) => ({
                                    ...prev,
                                    brandId: +e.target.value
                                }))
                            }}>
                                <option value="">-выберите</option>
                                {brands.length > 0 && brands.map((c, i) => {
                                    return <option key={i} value={c.id}>{c.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className='pt-3'>
                            <Form.Label>Наименование (только начало, цвет и память допишется)</Form.Label>
                            <input type="text" className='form-control' onChange={(e) => {
                                setFormModel((prev) => ({
                                    ...prev,
                                    name: e.target.value
                                }))
                            }} />

                        </Form.Group>
                        <Form.Group className='pt-3'>
                            <Form.Label>Описание </Form.Label>
                            <Form.Control as={'textarea'} value={formModel.description} onChange={(e) => {
                                setFormModel((prev) => ({
                                    ...prev,
                                    description: e.target.value
                                }))
                            }} />
                        </Form.Group>

                        <ul className='list-group mt-3'>
                            {
                                memories.length > 0 && memories.map((m, m_i) => {
                                    return <Fragment key={m_i}>
                                        {
                                            colors.length > 0 && colors.map((c, c_i) => {
                                                return <Fragment key={c_i}>
                                                    {
                                                        addProps.length > 0 && addProps.map((ap, ap_i) => {
                                                            return <li className='list-group-item d-flex justify-content-between' key={ap_i}>
                                                                <div className='d-flex' style={{ gap: 20 }}>
                                                                    <p>{m.value}</p>
                                                                    <p>{c.colorName}</p>
                                                                    <p>{ap.value}</p>
                                                                </div>
                                                                <GoodSeriesPriceItem attrs={{
                                                                    memoryId: m.id,
                                                                    colorId: c.id,
                                                                    addPropId: ap.id
                                                                }} />
                                                            </li>
                                                        })
                                                    }
                                                </Fragment>
                                            })
                                        }
                                    </Fragment>
                                })
                            }
                        </ul>

                        <Button type="button" className="mt-3" onClick={() => handleForm()}>Добавить товары</Button>
                    </Form>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default GoodSeriesProduct