import React from 'react'
import { Outlet } from 'react-router-dom'
import PublicHeader from '../components/public/Layout/PublicHeader'
import PublicNavbar from '../components/public/Layout/PublicNavbar'
import PublicFooter from '../components/public/Layout/PublicFooter'
import MobileMenu from '../components/public/Layout/MobileMenu'

function Layout() {
  return (
    <>
      <MobileMenu/>
      <PublicHeader />
      <PublicNavbar />
      <div id="main-content">
        <Outlet />
      </div>
      <PublicFooter />
    </>
  )
}

export default Layout