import React, { useState } from 'react';
import { Pagination } from 'react-bootstrap';

function PaginationComponent({ totalPages, currentPage, onPageChange }) {
  if (totalPages <= 1) {
    return null;
  }

  const pagesToShow = [];
  let startPage;
  let endPage;

  if (totalPages <= 3) {
    // Если totalPages меньше или равно 3, показываем все страницы
    startPage = 1;
    endPage = totalPages;
  } else if (currentPage <= 2) {
    // Если currentPage находится в диапазоне первых двух страниц
    startPage = 1;
    endPage = 3;
  } else if (currentPage >= totalPages - 1) {
    // Если currentPage находится в диапазоне последних двух страниц
    startPage = totalPages - 2;
    endPage = totalPages;
  } else {
    // В остальных случаях, currentPage будет посередине
    startPage = currentPage - 1;
    endPage = currentPage + 1;
  }

  for (let i = startPage; i <= endPage; i++) {
    pagesToShow.push(
      <Pagination.Item
        key={i}
        active={i === currentPage}
        onClick={() => onPageChange(i)}
      >
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Pagination>
      {currentPage > 2 && (
        <>
          <Pagination.Prev onClick={() => onPageChange(currentPage - 1)} />
        </>
      )}
      {pagesToShow}
      {currentPage < totalPages - 1 && (
        <>
          <Pagination.Next onClick={() => onPageChange(currentPage + 1)} />
        </>
      )}
    </Pagination>
  );
}

export default PaginationComponent;
