import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { Context } from "../..";
import { fetchUpdateUserName } from "../../data/http/auth-api";
import { useParams } from "react-router-dom";
import CabinetFavourites from "../../components/public/cabinet/CabinetFavourites";
import CabinetOrders from "../../components/public/cabinet/CabinetOrders";
import CabinetDeliveryPoints from "../../components/public/cabinet/CabinetDeliveryPoints";
import { autorun } from "mobx";
import LoginAndRegistration from "../../components/public/Auth/LoginAndRegistration";

function Cabinet({ timestamp }) {
  const { tab } = useParams();
  const { store } = useContext(Context);
  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    store.setLogged(false);
  };

  const [userInfo, setUserInfo] = useState({
    email: "",
    phone: "",
    firstName: "",
    secondName: "",
  });
  let baseUserInfo = {
    email: "",
    phone: "",
    firstName: "",
    secondName: "",
  };
  useEffect(() => {
    baseUserInfo = JSON.parse(localStorage.getItem("user"));
    setUserInfo(baseUserInfo);
  }, [timestamp]);

  const [userInfoChanges, setUserInfoChanged] = useState(false);

  const [errorText, setErrorText] = useState(false);
  const [successText, setSuccessText] = useState(false);

  const handleChangeUserInfo = () => {
    setSuccessText("");
    setErrorText("");

    fetchUpdateUserName({
      ...userInfo,
      password: "",
    }).then((response) => {
      if (response.status == 200) {
        setSuccessText("Данные успешно сохранены!");
        setUserInfoChanged(false);
      } else {
        setErrorText(
          "Во время сохранения произошла ошибка. Пожалуйста попробуйте через несколько минут!"
        );
      }
    });
  };

  const [updateOrders, setUpdateOrders] = useState({ update: () => {} });

  return (
    <Container className="mt-4">
      <h4 className="section-title mb-3">Личный кабинет</h4>
      {store.logged ? (
        <>
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey={tab ?? "profile"}
          >
            <Row>
              <Col sm={3}>
                <Nav
                  variant="pills"
                  className="flex-column pill-column-tabs sticky-top"
                  style={{ top: 20 }}
                >
                  <Nav.Item>
                    <Nav.Link eventKey="profile">Мой профиль</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="orders"
                      onClick={() => updateOrders.update()}
                    >
                      Заказы
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="favourites">Избранное</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="delivery-points">
                      Адреса доставки
                    </Nav.Link>
                  </Nav.Item>

                  {store.userHasRole("admin") && (
                    <Nav.Item>
                      <Nav.Link href="/admin" className="text-primary">
                        Админ панель
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  {store.userHasRole("partner_user") && (
                    <Nav.Item>
                      <Nav.Link href="/shop" className="text-info">
                        Мой магазин
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  <Nav.Item>
                    <Nav.Link onClick={() => logOut()} className="text-danger">
                      Выйти
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9} className="cabinet-tab-content">
                <Tab.Content>
                  <Tab.Pane eventKey="profile">
                    {successText.length > 0 && (
                      <p className="text-success">{successText}</p>
                    )}
                    {errorText.length > 0 && (
                      <p className="text-danger">{errorText}</p>
                    )}
                    <div
                      className="d-flex mb-3 align-items-end"
                      style={{ gap: 20 }}
                    >
                      <Form.Group controlId="user.firstName">
                        <Form.Label>Имя</Form.Label>
                        <Form.Control
                          type="text"
                          value={userInfo.firstName}
                          onChange={(e) => {
                            if (e.target.value != baseUserInfo.firstName) {
                              setUserInfoChanged(true);
                            } else if (
                              userInfo.secondName == baseUserInfo.secondName
                            ) {
                              setUserInfoChanged(false);
                            }
                            setUserInfoChanged(true);
                            setUserInfo({
                              ...userInfo,
                              firstName: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      <Form.Group controlId="user.secondName">
                        <Form.Label>Фамилия</Form.Label>
                        <Form.Control
                          type="text"
                          value={userInfo.secondName}
                          onChange={(e) => {
                            if (e.target.value != baseUserInfo.secondName) {
                              setUserInfoChanged(true);
                            } else if (
                              baseUserInfo.firstName == userInfo.firstName
                            ) {
                              setUserInfoChanged(false);
                            }
                            setUserInfo({
                              ...userInfo,
                              secondName: e.target.value,
                            });
                          }}
                        />
                      </Form.Group>
                      {userInfoChanges && (
                        <Button onClick={() => handleChangeUserInfo()}>
                          Сохранить изменения
                        </Button>
                      )}
                    </div>
                    <Form.Group className="mb-3" controlId="user.email">
                      <Form.Label>Почта</Form.Label>
                      <Form.Control
                        type="text"
                        value={userInfo.email}
                        disabled
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="user.phone">
                      <Form.Label>Телефон</Form.Label>
                      <Form.Control
                        type="text"
                        value={userInfo.phone}
                        disabled
                      />
                    </Form.Group>
                  </Tab.Pane>
                  <Tab.Pane eventKey="orders">
                    <CabinetOrders setUpdateOrders={setUpdateOrders} />
                  </Tab.Pane>
                  <Tab.Pane eventKey="favourites">
                    <CabinetFavourites />
                  </Tab.Pane>
                  <Tab.Pane eventKey="delivery-points">
                    <CabinetDeliveryPoints />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </>
      ) : (
        <>
          <h5>
            Вы не авторизованы! Войдите в систему, чтобы получить доступ к
            лчиному кабинету.
          </h5>
          <LoginAndRegistration/>
        </>
      )}
    </Container>
  );
}

export default observer(Cabinet);
