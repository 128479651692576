import React, { useEffect, useState } from "react";
import { fetchGetUserOrders } from "../../../data/models/basket-api";
import { Accordion, Col, Row } from "react-bootstrap";
import BasketDeliveryPickupGoodItem from "../Basket/BasketDeliveryPickupGoodItem";
import { priceToString } from "../../../helpers/priceToString";
import { autorun } from "mobx";

function CabinetOrders({setUpdateOrders}) {
  const [list, setList] = useState([]);


    const updateOrders = ()=>{
      fetchGetUserOrders().then((response) => {
        if (response.status == 200) {
          setList(response.data);
        }
      });
    }

  useEffect(() => {
    updateOrders()
    setUpdateOrders({update:updateOrders})
  },[]);

  return (
    <>
      <h5 className="fw-bold mb-3">Мои заказы</h5>

      {list.length > 0 ? (
        <>
          <Accordion id="cabinet-orders-accordion" defaultActiveKey={0}>
            {list.map((item, index) => {
              return (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>
                    <div className="d-flex align-items-center w-100 pe-5 fw-bold">
                      <p className="mb-0">Заказ <span className="text-success">№{item.id}</span></p> 
                      <p className="mb-0 mx-5"> от <span className="text-success">{item.creationDate}</span></p>
                      <p className="mb-0"> на сумму <span className="text-success">{priceToString(item.goods.reduce((acc,obj)=>acc+obj.price,0))} тг
                      {
                        item.deliveryType.id==2 && <>
                        {"  +  "} 
                      {priceToString(item.delivery.price)} тг за доставку
                        </>
                      } 
                      </span></p>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={4}>
                        <p>
                          Статус: <b>{item.status.name}</b>
                        </p>
                      </Col>

                      <Col md={8}>
                        <p>
                          Тип оплаты: <b>{item.paymentType.name}</b>
                        </p>
                      </Col>
                      <Col md={4}>
                        <p>
                          Тип доставки: <b>{item.deliveryType.name}</b>
                        </p>
                      </Col>
                      {item.deliveryType.id == 2 ? (
                        <Col md={8}>
                          <p>
                            Адрес доставки:{" "}
                            <b>
                              {item.delivery.address.districtName} район,
                              {item.delivery.address.street}{" "}
                              {item.delivery.address.house},{" "}
                              {item.delivery.address.flat}
                            </b>
                          </p>
                        </Col>
                      ) : item.deliveryType.id == 3 ? (
                        <Col md={8}>
                          <p>
                            Адрес доставки:{" "}
                            <b>
                              {item.delivery.address.cityName}{" "}
                              {item.delivery.address.address} (
                              {item.delivery.address.postIndex})
                            </b>
                          </p>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <ul className="list-group">
                      {item.goods.map((good, g_index) => {
                        return (
                          <li className="list-group-item" key={g_index}>
                            <BasketDeliveryPickupGoodItem goodPrice={good.price} goodId={good.id} showPrice={true}/>
                          </li>
                        );
                      })}
                      {
                        item.deliveryType.id==2 && <li className="list-group-item d-flex justify-content-between align-items-center">
                        <p className="h6 fw-bold me-3 mb-0">Доставка: </p>
                        <p className="h6 fw-bold text-success mb-0">{priceToString(item.delivery.price)} тг</p>
                  </li>
                      }
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          </Accordion>
        </>
      ) : (
        <>
          <p style={{ fontSize: 16 }}>У вас пока нет заказов</p>
        </>
      )}
    </>
  );
}

export default CabinetOrders;
