import React, { useEffect, useState } from "react";
import { Container, Dropdown } from "react-bootstrap";
import ManageTable from "../../../components/admin/ManageTable";
import { fetchUsers } from "../../../data/http/auth-api";
import UserRoles from "./UserRoles";

function Users() {
  const $fetch = {
    list: fetchUsers,
    // one: fetchRole,
    // create: fetchRoleCreate,
    // update: fetchRoleUpdate,
    // delete: fetchRoleDelete,
  };

  const [filter, setFilter] = useState({
    query: "",
    page:1
  });
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const updateList = () => {
    $fetch.list(filter).then((response) => {
      if (response.status == 200) {
        setList(response.data.rows);
        setTotalCount(response.data.count);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, [filter]);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">Управление пользователями</h2>
      {/* <Create fetchCreate={$fetch.create} updateList={updateList} /> */}
      <ManageTable title={"Список пользователей"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Почта</th>
            <th style={{ width: 200 }}>Имя</th>
            <th style={{ width: 200 }}>Телефон</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.username}</td>
                  <td>{item.firstName} {item.secondName}</td>
                  <td>{item.phone}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <UserRoles user={item}/>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ManageTable>
    </>
  );
}

export default Users;
