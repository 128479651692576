import React, { useEffect, useState } from 'react'
import { fetchGetGoodSeries } from '../../data/models/good/good-api'
import { Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import GoodSeriesProduct from './good-series/GoodSeriesProduct'
import GoodSeriesImages from './good-series/GoodSeriesImages'
import EditGoodSeriesPrice from './good-series/EditGoodSeriesPrice'
import GoodSeriesProperties from './good-series/GoodSeriesProperties'
import GoodSeriesParameters from './good-series/GoodSeriesParameters'

function AdminIndex() {
  const [series, setSeries] = useState([])

  useEffect(() => {
    fetchGetGoodSeries().then((response) => {
      if (response.status == 200) {
        setSeries(response.data)
      }
    })
  }, [])

  return (
    <>
      <h4 className='fw-bold'>Управление маркетплейсом</h4>
      <section id="admin-index-series">
        <div className="admin-index-series-container">
          {
            series.length > 0 && series.map((s, i) => {
              return <Card>
                <Card.Header>
                  <h5>{s.name}</h5>
                </Card.Header>
                <ListGroup className='list-group-flush'>
                  <ListGroupItem><GoodSeriesProduct/></ListGroupItem>
                  <ListGroupItem><GoodSeriesImages/></ListGroupItem>
                  <ListGroupItem><EditGoodSeriesPrice/></ListGroupItem>
                  <ListGroupItem><GoodSeriesParameters/></ListGroupItem>
                </ListGroup>
                <Card.Footer>

                </Card.Footer>
              </Card>
            })
          }
        </div>
      </section>
    </>
  )
}

export default AdminIndex