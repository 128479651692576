import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import BasketDeliveryCity from "./BasketDeliveryCity";
import BasketDeliveryCountry from "./BasketDeliveryCountry";
import BasketDeliveryPickup from "./BasketDeliveryPickup";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';

function BasketDelivery() {
  const { t } = useTranslation();

  return (
    <>
      <h5 className="step-title">
        <div className="step-box">2</div>
        {t('basketDelivery.delivery')}
      </h5>

      <div className="basket-delivery mb-3">
        <Tabs defaultActiveKey={"pickup"} className="pill-tabs">
          <Tab eventKey={"pickup"} className="pt-3" title={t('basketDelivery.pickup')}>
            <BasketDeliveryPickup />
          </Tab>
          <Tab
            eventKey={"delivery-city"}
            className="pt-3"
            title={t('basketDelivery.deliveryInAlmaty')}
          >
            <BasketDeliveryCity />
          </Tab>
          <Tab eventKey={"delivery-country"} title={t('basketDelivery.deliveryInKazakhstan')}>
            <BasketDeliveryCountry />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default observer(BasketDelivery);
