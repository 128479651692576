import { getRandomNumber } from "../../../helpers/getRandomNumber";
import { API_URL } from "../../http/axios-init";

export default class GoodShort {
  constructor(data) {
    if (data) {
      this.id= data.id
      this.image = data.image?API_URL+data.image :''
      this.name = data.name
      this.article = data.article
      this.description = data.description
      this.brand = {
        id:data.brandId,
        name:''
      }
      this.categoryId = data.categoryId
      this.price = data.basePrice
      this.shopId = 1
      this.isActive = data.isActive
    } else {
      this.id = getRandomNumber()
      this.image =
        "https://marsietis.lt/335568-large_default/apple-iphone-14-pro-128gb-deep-purple-eu.jpg";
      this.name = "Apple IPhone 14 Pro";
      this.article = "1235231"
      this.brand = {
        id: 1,
        name: "Apple",
      };
      this.price = 380000;
      this.description = "<h3>IPhone 14 Pro</h3>";
      this.shopId = 1
      this.isActive = true
    }
    this.images = [];
    this.specifications = []
      this.accessories = [];
      this.comments = []
  }

  static getAverageRating(comments) {
    let sum = 0;
    if (comments.length == 0) {
      return 0;
    }
    for (var item of comments) {
      sum += item.rating;
    }
    return Math.ceil((sum /comments.length)* 10) /10 ;
  }

  static getExampleList() {
    let list = [];

    for (let i = 0; i < 6; i++) {
      list.push(new GoodShort());
    }
    return list;
  }
}
