import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

function ForgotPassword() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);

    const handleClose = () => {
        var parent = document.querySelectorAll('.modal')[0]
        if(parent) {
            parent.classList.add('show')
        }
        setShow(false);
    }

    const handleShow = () => {
        var parent = document.querySelectorAll('.modal')[0]
        if(parent){
            parent.classList.remove('show')
        }
        setShow(true);
    }
  
    return (
        <>
            <Button variant="link" onClick={handleShow}>
                {t('forgotPassword.forgotPassword')}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('forgotPassword.passwordRecovery')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                </Modal.Body>
            </Modal>
        </>
    );
}

export default ForgotPassword;
