import React from "react";
import { fetchAddRole, fetchDeleteRole } from "../../../data/http/auth-api";

function UserRoleItem({ item, id, updateList }) {

  const handleSetUserRole = (e) => {
    if (e.target.checked) {
      fetchAddRole({ userId: id, roleId: item.roleId }).then((response) => {
        if (response.status == 200) {
        }
      })
    } else {
      fetchDeleteRole({ userId: id, roleId: item.roleId }).then((response) => {
        if (response.status == 200) {
        }
      })
    }
    updateList();
  }

  return (
    <>
      <p className="mb-0">{item.roleName}</p>
      <div className="form-check mb-0 form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          defaultChecked={item.hasRole}
          id={"role-" + item.id}
          onChange={(e) => handleSetUserRole(e)}
        />
      </div>
    </>
  );
}

export default UserRoleItem;
