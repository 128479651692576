import React from "react";
import { Container } from "react-bootstrap";
import { useTranslation } from 'react-i18next';

function Privacy() {
  const { t } = useTranslation();

  return (
    <Container className="pt-4" id="privacy">
      <h1 className="section-title mb-3">{t('privacy_title')}</h1>
      <img src={t('privacy_banner')} className="banner" alt="" />

      <div style={{ maxWidth: 600 }}>
        <p className="my-5">{t('effective_date')}</p>

        <p>{t('privacy_intro')}</p>

        <h3>{t('data_collection')}</h3>
        <p>{t('data_collection_desc')}</p>

        <h3>{t('data_usage')}</h3>
        <p>{t('data_usage_desc')}</p>

        <h3>{t('data_disclosure')}</h3>
        <p>{t('data_disclosure_desc')}</p>

        <h3>{t('data_security')}</h3>
        <p>{t('data_security_desc')}</p>

        <h3>{t('data_management')}</h3>
        <p>{t('data_management_desc')}</p>

        <h3>{t('policy_consent')}</h3>
        <p>{t('policy_consent_desc')}</p>

        <p className="mt-5">{t('thank_you')}</p>
      </div>
    </Container>
  );
}

export default Privacy;
