import React, { useEffect, useState } from "react";
import { Button, Dropdown, Form } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { fetchGetOrdersForShop } from "../../../data/models/partner/partner-api";
import { priceToString } from "../../../helpers/priceToString";
import { SHOP_ORDER_DETAILS } from "../../../constants/routes";

function ShopOrders() {
  const { type } = useParams();

  const [filter, setFilter] = useState({
    page: 1,
    query: "",
    isNew: false,
    type: 1,
  });

  const [list, setList] = useState([]);

  const updateList = () => {
    var fd = { ...filter, isNew: type == "new" };
    if (type == "pickup") {
      fd = { ...fd, type: 1 };
    }
    if (type == "delivery-city") {
      fd = { ...fd, type: 2 };
    }
    if (type == "delivery-country") {
      fd = { ...fd, type: 3 };
    }
    fetchGetOrdersForShop(fd).then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, [type]);

  const handleForm = () => {};

  const getAddress = (delivery) => {
    if (delivery.address.address) {
      return delivery.address.cityName + ", " + delivery.address.address;
    } else {
      return (
        delivery.address.cityName +
        ", " +
        delivery.address.street +
        ", " +
        delivery.address.house +
        ", " +
        delivery.address.flat
      );
    }
  };

  return (
    <>
      <Form onSubmit={() => handleForm()}>
        <Form.Group
          className="mb-3 d-flex"
          style={{ gap: 20, maxWidth: 600 }}
          controlId="search_query"
        >
          <Form.Control
            type="text"
            placeholder="введите номер заказа или номер телефона клиента"
            value={filter.query}
            onChange={(e) => {
              setFilter({ ...filter, query: e.target.value });
            }}
          />
          <Button type="submit">Найти</Button>
        </Form.Group>
      </Form>

      <table className="table">
        <thead>
          <tr>
            <th style={{ width: 20 }}></th>
            <th>Номер заказа</th>
            <th>Статус заказа</th>
            <th>Покупатель</th>
            <th>Сумма</th>
            <th>Дата заказа</th>
            <th>Доставка</th>
            <th>Адрес доставки</th>
            <th>Оплата</th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 &&
            list.map((order) => {
              return (
                <tr key={order.id}>
                  <td>
                    <Link
                      to={"/shop/" + SHOP_ORDER_DETAILS + "/" + order.id}
                      className="btn btn-primary btn-sm"
                    >
                      Подробнее
                    </Link>
                  </td>
                  <td>{order.id}</td>
                  <td>{order.status.name}</td>
                  <td>
                    {order.contacts.firstName} {order.contacts.secondName}
                  </td>
                  <td>
                    {priceToString(
                      order.goods.reduce((acc, obj) => acc + (obj.price*obj.amount), 0)
                    )}{" "}
                    тг
                  </td>
                  <td>{order.creationDate}</td>
                  <td>{order.deliveryType.name}</td>
                  <td>
                    {order.deliveryType.name == "Самовывоз"
                      ? ""
                      : getAddress(order.delivery)}
                  </td>
                  <td>{order.paymentType.name}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

export default ShopOrders;
