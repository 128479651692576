import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { fetchGetGoodSeriesProperties, fetchGetSeriesPrices, fetchSetPriceForSeries } from '../../../data/models/good/good-api';
import GoodSeriesProductPrice from './GoodSeriesProductPrice';

function EditGoodSeriesPrice({ seriesId, seriesName }) {
    const [memories, setMemories] = useState([])
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    useEffect(() => {
        fetchGetSeriesPrices(seriesId).then((response) => {
            if (response.status == 200) {
                setMemories(response.data)
            }
        })
    }, [])

    const handleForm = () => {
        var pricing = Array.from(document.getElementsByName('price')).map((m) => { return { price: +m.value, id: +m.getAttribute('memoryid'), name: m.getAttribute('memoryname') } })
        for (var item of pricing) {
            fetchSetPriceForSeries(item.id, item.price).then((response) => {

            })
        }
    }

    return (
        <>
            <Button variant="link" onClick={handleShow}>
                Редактировать цены
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Корректировка цены</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{seriesName}</h4>
                    <ul className='list-group pt-3'>
                        {memories.length > 0 && memories.map((m, i) => {
                            return <GoodSeriesProductPrice key={i} m={m} />
                        })}
                    </ul>
                    <Button onClick={() => handleForm()}>Сохранить изменения</Button>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditGoodSeriesPrice