import { $host } from "../../http/axios-init";

export const fetchCategories = async (filter) => {
  try {
    const response = await $host.get("/api/Category" + (filter.query && filter.query.length > 0 ? '?query=' + filter.query : ''));
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchCategoryCreate = async (fd) => {
  try {
    const response = await $host.post("/api/Category", fd);
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCategoryUpdate = async (fd) => {
  try {
    const response = await $host.put("/api/Category", fd);
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCategoryDelete = async (id) => {
  try {
    const response = await $host.delete("/api/Category/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCategoryTree = async (id) => {
  try {
    const response = await $host.get("/api/Category/tree/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchCategory = async (id) => {
    const response = await $host.get("/api/Category/getone/" + id);
    return response;

};

export const fetchCategoryTreeForGood = async (id) => {
  try {
    const response = await $host.get('/api/category/treeForGood/' + id)
    return response
  }
  catch (e) {
    return e.response
  }
}