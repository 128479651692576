import React, { useContext, useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import NavbarCatalog from './NavbarCatalog'
import NavbarFinder from './NavbarFinder'
import { BASKET, FAVOURITES } from '../../../constants/routes'
import NavbarCabinet from './NavbarCabinet'
import { observer } from 'mobx-react-lite'
import { Context } from '../../..'
import { useTranslation } from 'react-i18next'

function PublicNavbar() {

    const {store} = useContext(Context)

    const [hasFavourite, setHasFavourite] = useState(false)
    const [hasBasket, setHasBasket] = useState(false)
    const { t } = useTranslation();
    return (
        <nav id="public-navbar">
            <Container>
                <div className="d-flex align-items-center justify-content-between justify-content-md-start" style={{ gap: 30 }}>
                    <Link className="brand" to="/">AZATA</Link>
                    <a className='phone no-desktop' href="tel:+77479404418"><span className='no-mobile'>{t("hot_line")}:</span> <span>+7 (747) 940-44-18</span></a>
                    <NavbarCatalog />
                    <NavbarFinder />
                    <ul className='navbar-buttons no-mobile'>
                        <li>
                            <Link to={FAVOURITES+"/favourites"}>
                                <div>
                                    {
                                        (store.hasFavourites() && store.logged) ?
                                        <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="bi bi-star" viewBox="0 0 16 16">
                                                <path d="M2.866 14.85c-.078.444.36.791.746.593l4.39-2.256 4.389 2.256c.386.198.824-.149.746-.592l-.83-4.73 3.522-3.356c.33-.314.16-.888-.282-.95l-4.898-.696L8.465.792a.513.513 0 0 0-.927 0L5.354 5.12l-4.898.696c-.441.062-.612.636-.283.95l3.523 3.356-.83 4.73zm4.905-2.767-3.686 1.894.694-3.957a.565.565 0 0 0-.163-.505L1.71 6.745l4.052-.576a.525.525 0 0 0 .393-.288L8 2.223l1.847 3.658a.525.525 0 0 0 .393.288l4.052.575-2.906 2.77a.565.565 0 0 0-.163.506l.694 3.957-3.686-1.894a.503.503 0 0 0-.461 0z" />
                                            </svg>
                                            
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" className="bi bi-star-fill" viewBox="0 0 16 16">
                                                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                                            </svg>
                                    }
                                    {t("favourites")}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to={BASKET}>
                                <div>
                                    {
                                        store.isNotEmptyBasket() ?
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-cart-fill" viewBox="0 0 16 16">
                                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                            </svg>
                                            :
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16">
                                                <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                            </svg>
                                    }
                                    {t("basket")}
                                </div>
                            </Link>
                        </li>
                        <li>
                            <NavbarCabinet />
                        </li>
                    </ul>
                </div>
            </Container>
        </nav>
    )
}

export default observer(PublicNavbar)