import { useState } from "react";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function CreateCityDistrict({ fetchCreate, updateList, id }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setSubmitBtn({
      variant: "primary",
      disabled: false,
      label: "Сохранить",
    })
  }
  const handleShow = () => setShow(true);

  const [formModel, setFormModel] = useState({
    name: ""
  });

  const [submitBtn, setSubmitBtn] = useState({
    variant: "primary",
    disabled: false,
    label: "Сохранить",
  });

  const handleForm = (e) => {
    e.preventDefault();

    setSubmitBtn({
      variant: "secondary",
      disabled: true,
      label: "Сохранение...",
    });
    fetchCreate({
      ...formModel,
      cityId: id
    }).then((response) => {
      if (response.status == 200) {
        //
        setSubmitBtn({
          variant: "success",
          disabled: true,
          label: "Готово!",
        });
        setFormModel({
          name: "",
          description: "",
          image: "",
        });
        updateList();
        handleClose();
      } else {
        //

        setSubmitBtn({
          variant: "danger",
          disabled: false,
          label: "Ошибка. Попробуйте еще раз",
        });
      }
    });
  };

  return (
    <>
      <Button variant="primary" className="mb-3" onClick={handleShow}>
        + Добавить район
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Добавление района</Modal.Title>
        </Modal.Header>
        <Form onSubmit={(e) => handleForm(e)}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Название района</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={formModel.name}
                onChange={(e) =>
                  setFormModel({
                    ...formModel,
                    name: e.target.value,
                  })
                }
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={submitBtn.variant}
              type="submit"
              className={submitBtn.disabled ? "disabled" : ""}
            >
              {submitBtn.label}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

export default CreateCityDistrict;
