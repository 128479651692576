import React from 'react'
import { Breadcrumb } from 'react-bootstrap'

function TreeComponent({ list }) {
    return (
        <Breadcrumb className='my-3'>
            {
                list.length > 0 && list.map((item, index) => {
                    return <Breadcrumb.Item key={index} href={item.link}>{item.title}</Breadcrumb.Item>
                })
            }
        </Breadcrumb>
    )
}

export default TreeComponent