import React, { Fragment, useEffect, useState } from "react";
import { Button, Container, Form, Pagination, Spinner } from "react-bootstrap";
import GoodShort from "../../data/models/good/good";
import { Link, useParams } from "react-router-dom";
import GoodShortItem from "../../components/public/Good/GoodShortItem";
import PaginationComponent from "../../components/public/Good/Pagination";
import { fetchCatalogGoods, fetchGetBrandsByCategory, fetchGetGoodSeriesByCategory, fetchGood, fetchGoods } from "../../data/models/good/good-api";
import {
  fetchCategory,
  fetchCategoryTree,
} from "../../data/models/category/category-api";

import { useTranslation } from 'react-i18next';
import { observer } from "mobx-react-lite";

function CatalogCategory() {
  const { categoryTranslit } = useParams();

  const { t } = useTranslation();

  const [totalPages, setTotalPages] = useState(0);
  const [category, setCategory] = useState({ name: "" });
  const [goods, setGoods] = useState([]);
  const [loading, setLoading] = useState(false)

  const getCategoryId = () => {
    switch (categoryTranslit) {
      case 'smartphones': return 3
      case 'laptops': return 13
      case 'tablets': return 12
      case 'headphones': return 10
      case 'cables_and_charges': return 11
      case 'cases_and_safety_glasses': return 14
    }
  }

  const [filter, setFilter] = useState({
    categoryId: getCategoryId(),
    brandIds: [],
    goodSeriesIds: [],
    minPrice: 0,
    maxPrice: 0,
    sort: "id",
    order: "desc",
    page: 1,
    shopIds: []
  });

  function calculateTotalPages(totalItems, itemsPerPage) {
    return Math.ceil(totalItems / itemsPerPage);
  }

  const changePagination = (page) => {
    setFilter({
      ...filter,
      page,
    });
  };

  const update = () => {

    setGoods([])
    setLoading(true)
    fetchCategory(getCategoryId()).then((response) => {
      setCategory(response.data)
    }).catch((err) => console.log(err))

    fetchCatalogGoods({
      ...filter,
      categoryId: getCategoryId(),
    }).then((response) => {
      setLoading(false)
      if (response.status == 200) {
        setGoods(response.data.rows.map((m) => new GoodShort(m)));
        setTotalPages(calculateTotalPages(response.data.count, 20));
      }
    }).catch((e) => {
      console.log(e)
      setLoading(false)
    })
  };

  useEffect(() => {
    update()
  }, [categoryTranslit, filter]);


  return (
    <Container>
      <h4 className="section-title my-3">{category.name}</h4>
      <div className="catalog-category-container">
        {
          loading ? <div className="p-4">
            <Spinner>
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
            : <div className="list">
              <div className="sort">
                <Button
                  variant="light"
                  className={filter.sort == "id" ? "active" : ""}
                  onClick={() =>
                    setFilter({
                      ...filter,
                      sort: "id",
                      order: "desc",
                    })
                  }
                >
                  {t('catalogCategory.sortByNew')}
                </Button>
                <Button
                  variant="light"
                  className={
                    (filter.sort == "price") & (filter.order == "asc")
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    setFilter({
                      ...filter,
                      sort: "price",
                      order: "asc",
                    })
                  }
                >
                  {t('catalogCategory.sortByPriceAsc')}
                </Button>
                <Button
                  variant="light"
                  className={
                    (filter.sort == "price") & (filter.order == "desc")
                      ? "active"
                      : ""
                  }
                  onClick={() =>
                    setFilter({
                      ...filter,
                      sort: "price",
                      order: "desc",
                    })
                  }
                >
                  {t('catalogCategory.sortByPriceDesc')}
                </Button>
              </div>

              <div className="goods">
                {goods.length > 0 &&
                  goods.map((item, index) => {
                    return (
                      <div className="item" key={index}>
                        <GoodShortItem key={index} item={item} />
                      </div>
                    );
                  })}
              </div>

              <PaginationComponent
                totalPages={totalPages}
                currentPage={filter.page}
                onPageChange={changePagination}
              />
            </div>
        }
      </div>
    </Container>
  );
}

export default observer(CatalogCategory);
