import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  fetchBrand,
  fetchBrandDelete,
  fetchBrandUpdate,
} from "../../../data/models/brand/brand-api";
import ManageTable from "../../../components/admin/ManageTable";
import {
  fetchParameterCreate,
  fetchParameterGroup,
  fetchParameters,
} from "../../../data/models/good/good-api";
import { Link, useParams } from "react-router-dom";
import CreateParameter from "./CreateParameter";

function Parameters() {
  const { groupId } = useParams();

  const $fetch = {
    list: fetchParameters,
    one: fetchBrand,
    create: fetchParameterCreate,
    update: fetchBrandUpdate,
    delete: fetchBrandDelete,
  };

  const [list, setList] = useState([]);
  const [group, setGroup] = useState({
    id: groupId,
    name: "",
  });

  const updateList = () => {
    $fetch.list(groupId).then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };

  useEffect(() => {
    fetchParameterGroup(groupId).then((response) => {
      if (response.status == 200) {
        setGroup(response.data);
      }
    });
    updateList();
  }, []);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">
        Управление параметрами группы "{group.name}"
      </h2>
      <Link to={-1} className="btn btn-secondary mb-3 me-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          classname="me-2 bi bi-arrow-left"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
          />
        </svg>
        Назад
      </Link>
      <CreateParameter
        fetchCreate={$fetch.create}
        updateList={updateList}
        parameterGroupId={groupId}
      />
      <ManageTable title={"Список параметров"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Название</th>
            <th>Описание</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu></Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ManageTable>
    </>
  );
}

export default Parameters;
