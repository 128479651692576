import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { fetchCreateGoodSeriesImages, fetchGetGoodSeriesProperties } from '../../../data/models/good/good-api';


function GoodSeriesImages({ seriesId }) {

  const [show, setShow] = useState(false);
  const [colors, setColors] = useState([]);

  const [formModel, setFormModel] = useState({
    images: [],
    colorId: 0
  })

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    fetchGetGoodSeriesProperties(seriesId).then((response) => {
      if (response.status == 200) {
        setColors(response.data.colors)
      }
    })
  }, [])

  const handleForm = () => {
    var fd = new FormData()
    fd.append('colorId', formModel.colorId)
    var files = document.getElementById('files').files
    for (var item of files) {
      fd.append('images', item)
    }
    fetchCreateGoodSeriesImages(fd).then((response) => {
      if (response.status == 200) {
        alert('Все файлы загружены')

      }
    })

  }

  return (
    <>
      <div onClick={handleShow}>
        Добавить картинки
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Картинки серии</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className='mb-3'>
            <Form.Label>Категория</Form.Label>
            <Form.Select value={formModel.colorId} required onChange={(e) => {
              setFormModel((prev) => ({
                ...prev,
                colorId: +e.target.value
              }))
            }}>
              <option value="">-выберите</option>
              {colors.length > 0 && colors.map((c, i) => {
                return <option key={i} value={c.id}>{c.colorName}</option>
              })}
            </Form.Select>
          </Form.Group>
          <input type='file' required className='form-control mb-3' id="files" multiple />
          <Button onClick={() => handleForm()}>Загрузить</Button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default GoodSeriesImages