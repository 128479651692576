import React, { useContext } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import BasketGoodList from "../../components/public/Basket/BasketGoodList";
import { observer } from "mobx-react-lite";
import { Context } from "../..";
import { CATALOG, CATALOG_CATEGORY } from "../../constants/routes";
import { Link } from "react-router-dom";
import BasketTotalSticky from "../../components/public/Basket/BasketTotalSticky";
import BasketDelivery from "../../components/public/Basket/BasketDelivery";
import BasketPayment from "../../components/public/Basket/BasketPayment";
import BasketContacts from "../../components/public/Basket/BasketContacts";

function Basket() {
  const { store } = useContext(Context);

  return (
    <Container className="mt-3" id="basket">
      <h4 className="section-title">Корзина</h4>
      {store.isNotEmptyBasket() ? (
        <>
          <Row>
            <Col lg={3}>
              <BasketTotalSticky />
            </Col>
            <Col className="basket-right">
              <BasketGoodList />
              <BasketDelivery/>
              <BasketPayment/>
              <BasketContacts/>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <h6 className="mt-4">
            Ваша корзина еще пустая! Давайте подберем что-нибудь для вас!{" "}
          </h6>
          <Link className="btn btn-primary" to={"/" + CATALOG_CATEGORY+"/1"}>
            Перейти в магазин
          </Link>
        </>
      )}
    </Container>
  );
}

export default observer(Basket);
