import React from "react";

function ManageTable({ title, children, className }) {
  return (
    <div className={"manage-table "+className}>
      <h4>{title}</h4>
      <table className="table table-hover table-striped">{children}</table>
    </div>
  );
}

export default ManageTable;
