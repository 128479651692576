import React, { useEffect, useState } from "react";
import GoodShortItem from "../GoodShortItem";
import { Tab, Tabs } from "react-bootstrap";
import GoodShort from "../../../../data/models/good/good";
import { fetchGoodsForGood } from "../../../../data/models/good/good-api";

function Accessories({ goodId, setUpdate }) {
  const [list, setList] = useState([]);

  const updateAccessories = (id) => {
    fetchGoodsForGood(id).then((response) => {
      if (response.status === 200) {
        setList(response.data.map((m) => {
          return {
            id: m.id,
            title: m.categoryName,
            goods: m.goods.map((g) => {
              return new GoodShort(g)
            })
          }
        }))
      }
    })
  };

  useEffect(() => {
    updateAccessories(goodId);
    setUpdate((prev) => ({ ...prev, updateAccessories }))
  }, []);

  return (
    <Tabs
      defaultActiveKey="accessories-0"
      id="good-accessories-tabs"
      className="pill-tabs pill-tabs-sm"
    >
      {list.map((item, index) => {
        return (
          <Tab key={index} eventKey={"accessories-" + index} title={item.title}>
            <div className="goods">
              {item.goods.length > 0 &&
                item.goods.map((g, g_index) => {
                  return (
                    <div className="item" key={g_index}>
                      <GoodShortItem item={g} />
                    </div>
                  );
                })}
            </div>
          </Tab>
        );
      })}
    </Tabs>
  );
}

export default Accessories;
