import React from 'react'
import { Container } from 'react-bootstrap'

function News() {
  return (
    <Container className='pt-4' id="news">
        <h1 className='section-title'>Новостная лента</h1>

        <p>Пока нет новостей</p>
    </Container>
  )
}

export default News