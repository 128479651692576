import React, { useEffect, useState } from 'react'
import { fetchGetCurrency } from '../../../data/models/good/good-api'
import { getCurrentDate } from '../../../helpers/getCurrentDate'

function GoodSeriesProductPrice({ m }) {
    const [price, setPrice] = useState({
        kzt: '',
        usa: '',
        rub: ''
    })

    const calculate = async (value, c) => {

        var curs = await fetchGetCurrency(getCurrentDate())
        curs = curs.data
        if (c == 'usa') {
            setPrice({
                usa: value,
                kzt: curs.kzt * value,
                rub: curs.rub * value
            })
        } else if (c == "kzt") {
            setPrice({
                usa: Math.ceil(value / curs.kzt),
                kzt: value,
                rub: Math.ceil((value / curs.kzt) * curs.rub)
            })
        } else {
            setPrice({
                usa: Math.ceil(value / curs.rub),
                kzt: Math.ceil((value / curs.rub) * curs.kzt),
                rub: value
            })
        }
    }

    useEffect(() => {
        
        if(m.price) {
            fetchGetCurrency(getCurrentDate()).then((response)=>{
                setPrice({
                    usa: Math.ceil(m.price / response.data.kzt),
                    kzt: m.price,
                    rub: Math.ceil((m.price / response.data.kzt) * response.data.rub)
                })
            })    
        }
    }, [])

    return (
        <li className='list-group-item d-flex justify-content-between align-items-center ' style={{ gap: 10 }}>
            <p style={{ width: 200 }}>{m.name?m.name:m.value}</p>
            <input type='text' className='form-control' name='price' value={price.kzt} onChange={(e) => {
                calculate(+e.target.value, 'kzt')
            }} memoryid={m.id} memoryname={m.value} placeholder='KZT' />
            <input type='text' className='form-control' name='priceUSA' value={price.usa} onChange={(e) => {
                calculate(+e.target.value, 'usa')
            }} memoryid={m.id} memoryname={m.value} placeholder='USA' />
            <input type='text' className='form-control' name='priceRUS' value={price.rub} onChange={(e) => {
                calculate(+e.target.value, 'rub')
            }} memoryid={m.id} memoryname={m.value} placeholder='RUB' />
        </li>
    )
}

export default GoodSeriesProductPrice