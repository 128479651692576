import React from "react";
import { Container } from "react-bootstrap";

function DeliveryAndPayment() {
  return (
    <Container className="pt-4" id="delivery-and-payment">
      <h1 className="section-title">Доставка и оплата</h1>
      <img src="/images/deliveryandpayment.jpg" className="banner" alt="" />
      <p className="my-5">
        Наши условия доставки и оплаты разработаны с учетом вашего комфорта и
        удобства. Мы стремимся предоставить вам легкость и надежность во всех
        этапах вашей покупки.
      </p>
      <section>
        <h2>Доставка:</h2>
        <h3>Способы доставки</h3>
        <p>
          Мы предлагаем различные способы доставки, чтобы соответствовать вашим
          потребностям:
        </p>
        <ul>
          <li>
            Курьерская доставка: Наши профессиональные курьеры доставят ваш
            заказ прямо к вашей двери в удобное для вас время.
          </li>
          <li>
            Самовывоз: Вы можете забрать свой заказ из ближайшего пункта
            самовывоза.
          </li>
        </ul>
        <h3>Сроки доставки</h3>
        <p>
          Сроки доставки зависят от вашего местоположения и выбранного способа
          доставки. Обычно мы стараемся доставить заказ как можно быстрее, и
          сроки обычно указаны при оформлении заказа.
        </p>
        <h3>Стоимость доставки</h3>
        <p>
          Стоимость доставки рассчитывается в зависимости от веса, размера
          заказа и выбранного способа доставки. При оформлении заказа вам будет
          предоставлена информация о стоимости доставки.
        </p>
      </section>

      <section>
        <h2>Оплата:</h2>
        <h3>Способы оплаты</h3>
        <p>
          Мы предоставляем разнообразные способы оплаты для вашего удобства:
        </p>
        <ul>
          <li>
            Оплата банковской картой: Вы можете оплатить заказ онлайн с помощью
            банковской карты.
          </li>
          <li>
            Электронные кошельки: Мы принимаем оплату через популярные
            электронные кошельки.
          </li>
          <li>
            Наличными при получении: Вы можете оплатить заказ наличными курьеру
            при получении.
          </li>
        </ul>
        <h3>Безопасность платежей</h3>
        <p>
          Мы гарантируем безопасность ваших платежей. Все данные об оплате
          передаются через защищенное соединение, и ваша конфиденциальность
          остается под надежной защитой.
        </p>
      </section>

      <section>
        <h2>Гарантии и возврат:</h2>
        <p>
          Мы ценим ваше доверие, и поэтому предоставляем гарантии на все наши
          товары. Если у вас возникнут вопросы или проблемы с заказом,
          пожалуйста, свяжитесь с нашей службой поддержки, и мы поможем вам
          решить их.
        </p>
      </section>
      <section>
        <p>
          Спасибо за покупку на Azata.kz. Мы всегда готовы обеспечить вас
          наилучшими условиями доставки и оплаты. Если у вас есть какие-либо
          вопросы или нужна дополнительная информация, не стесняйтесь обращаться
          к нашей службе поддержки.
        </p>
        <p>
          С уважением, <br />
          Команда Azata.kz
        </p>
      </section>
    </Container>
  );
}

export default DeliveryAndPayment;
