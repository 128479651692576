import { useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fetchCategories } from "../../../data/models/category/category-api";
import { fetchCategoryParameterGroups, fetchParameterGroupCategories, fetchParameterSetCategory, fetchParameterUnsetCategory } from "../../../data/models/good/good-api";

function ParameterGroupCategoryLinks({ parameterGroupId }) {
  const [show, setShow] = useState(false);
  const [categories, setCategories] = useState([]);

  const handleClose = () => {
    update()
    setShow(false);
  }
  const handleShow = () => {
    update()
    setShow(true);
  };

  const update = ()=>{
    let dto = [];
    fetchCategories({
      query: "",
    }).then((res_1) => {
      if (res_1.status == 200) {
        fetchParameterGroupCategories(parameterGroupId).then((res_2) => {
          if (res_2.status == 200) {
            for (var item_1 of res_1.data) {
              let has = false;
              for (var item_2 of res_2.data) {
                if (item_1.id == item_2.id) {
                  has = true;
                  break;
                }
              }
              dto.push({
                id: item_1.id,
                name: item_1.name,
                has: has,
              });
            }
            setCategories(dto);
          }
        });
      }
    });
  }

  const handleToggle = (categoryId,checked) =>{
    if(checked) {
        fetchParameterSetCategory({categoryId,parameterGroupId})
    } else {
        fetchParameterUnsetCategory({categoryId,parameterGroupId})
    }
  }

  return (
    <>
      <Dropdown.Item onClick={handleShow}>Категории</Dropdown.Item>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Настройка категорий привязанных к группе</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-group">
            {categories.length > 0 &&
              categories.map((m) => (
                <li
                  className="list-group-item d-flex justify-content-between"
                  key={m.id}
                >
                  <p className="mb-0">{m.name}</p>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      defaultChecked={m.has}
                      onChange={(e)=>handleToggle(m.id,e.target.checked)}
                      id={"category-switch-"+m.id}
                    />
                  </div>
                </li>
              ))}
          </ul>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ParameterGroupCategoryLinks;
