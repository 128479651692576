import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import BestCategoriesGoodList from './BestCategoriesGoodList';
import GoodShort from '../../../data/models/good/good';
import { Link } from 'react-router-dom';
import { CATALOG_CATEGORY } from '../../../constants/routes';
import { fetchGoods } from '../../../data/models/good/good-api';
import { useTranslation } from 'react-i18next';

function BestCategoriesTabs({ title }) {
  const { t } = useTranslation();

  const [list, setList] = useState([]);

  useEffect(() => {
    // Fetch data for category with id 3
    fetchGoods({
      categoryId: 3,
      brandId: 0,
      minPrice: 0,
      maxPrice: 0,
      query: " ",
      page: 1,
      perPage: 10,
    }).then((response) => {
      if (response.status === 200) {
        const newList = response.data.rows.map((item) => new GoodShort(item));
        setList((prev) => [...prev, { id: 3, title: t('categories.smartphones'), list: newList }]);
      }
    });

    // Fetch data for category with id 5
    fetchGoods({
      categoryId:11,
      brandId: 0,
      minPrice: 0,
      maxPrice: 0,
      query: " ",
      page: 1,
      perPage: 10,
    }).then((response) => {
      if (response.status === 200) {
        const newList = response.data.rows.map((item) => new GoodShort(item));
        setList((prev) => [...prev, { id: 5, title: t('cables_and_charges'), list: newList }]);
      }
    });
  }, [t]);

  return (
    <div className='my-5'>
      <h4 className='section-title'>{t('home.bestCategories')}</h4>
      <Tabs
        defaultActiveKey="tab-0"
        id="best-categories-tabs"
        className="mb-3 pill-tabs"
      >
        {list.length > 0 &&
          list.map((item, index) => (
            <Tab key={index} eventKey={'tab-' + index} title={item.title}>
              <BestCategoriesGoodList list={item.list} />
              <div className='text-end mt-2'>
                <Link to={CATALOG_CATEGORY + '/' + item.id}>
                  {t('home.goToCategory')}{' '}
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
                  </svg>
                </Link>
              </div>
            </Tab>
          ))}
      </Tabs>
    </div>
  );
}

export default BestCategoriesTabs;
