import { useState } from "react";
import { Dropdown, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { fetchGoodImageCreate, fetchGoodImageDelete, fetchGoodImages } from "../../../data/models/good/good-api";
import { API_URL } from "../../../data/http/axios-init";

function GoodImages({ goodId }) {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);


  const handleClose = () => setShow(false);
  const handleShow = () => {
    update();
    setShow(true);
  };

  const update = () => {
    fetchGoodImages(goodId).then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };

  const [files, setFiles] = useState([])

  const handleDelete = (id) => {
    let ask = window.confirm('Вы точно хотите удалить картинку? \nПосле удаления картинки, ее нельзя восстановить, но можно добавить заново.')
    if (ask) {
      fetchGoodImageDelete(id).then((response) => {
        if (response.status == 200) {
          update()
        }
      })
    }
  }

  const handleForm = (e) => {
    e.preventDefault();

    for (var i = 0; i < files.length; i++) {
      let fd = new FormData();
      fd.append('GoodId', goodId)
      fd.append('Image', files[i])
      fd.append('Position', i + 1)
      fetchGoodImageCreate(fd)
    }

  }

  return (
    <>
      <Dropdown.Item onClick={handleShow}>Настройки картинок</Dropdown.Item>

      <Modal show={show} size="lg" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Настройки картинок</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {list.length > 0 && list.map((item) => {
              return <div className="col-md-2 text-center mb-3">
                <img src={API_URL + item.path} className="border rounded-3" style={{ width: '100%', aspectRatio: '1 / 1', objectFit: 'contain' }} />
                <Button size="sm" className="mt-2" variant="danger" onClick={(e) => {
                  handleDelete(item.id)
                }}>Удалить</Button>
              </div>
            })}
          </div>

          <Form onSubmit={(e) => handleForm(e)}>
            <Form.Group>
              <Form.Label>Добавить картинки</Form.Label>
              <Form.Control type="file" required multiple={true} onChange={(e) => {
                setFiles(e.target.files)
              }} />
            </Form.Group>

            <Button className="mt-3" type="submit">Добавить картинки</Button>
          </Form>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default GoodImages;
