import { $authHost, $host } from "../http/axios-init";

export const fetchCreateOrder = async (fd) => {
  try {
    const response = await $host.post(`/api/Basket/CreateOrder`, fd);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGetUserOrders = async () => {
    try {
      const response = await $authHost.get(`/api/Basket/GetOrdersByUser`);
      return response;
    } catch (e) {
      return e.response;
    }
  };