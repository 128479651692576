import React, { createContext } from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import './styles/main.css';
import App from './App';
import Store from './data/store/store';

const root = ReactDOM.createRoot(document.getElementById('root'));

export const Context = createContext(null)

root.render(
  <Context.Provider value={{
    store:new Store()
  }}>
    <App />
  </Context.Provider>
);
