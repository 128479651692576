import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../..";
import { fetchGetCities } from "../../../data/models/city/city-api";
import { Button, Form, FormSelect } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useTranslation } from 'react-i18next';

function BasketDeliveryCountry() {
  const { store } = useContext(Context);
  const { t } = useTranslation();

  const [cities, setCities] = useState([]);

  useEffect(() => {
    fetchGetCities("").then((response) => {
      if (response.status === 200) {
        setCities(response.data);
      }
    });
  }, []);

  const [deliveryCountryAddress, setDeliveryCountryAddress] = useState({
    cityId: 0,
    address: "",
    postIndex: "",
  });

  const handleDeliveryCountry = () => {
    store.setDelivery({
      type: "delivery-country",
      price: -1,
      address: deliveryCountryAddress,
    });
  };

  return (
    <>
      <div className="d-flex align-items-start mt-3" style={{ gap: 20 }}>
        <Form.Group controlId="delivery-country-city">
          <Form.Label>{t('basketDeliveryCountry.city')}</Form.Label>
          <FormSelect
            value={deliveryCountryAddress.cityId}
            onChange={(e) => {
              setDeliveryCountryAddress({
                ...deliveryCountryAddress,
                cityId: +e.target.value,
              });
            }}
          >
            <option value="">-{t('basketDeliveryCountry.selectCity')}</option>
            {cities.length > 0 &&
              cities.map((item, index) => (
                <option key={index} value={item.id}>
                  {item.name}
                </option>
              ))}
          </FormSelect>
          <small>
            *{t('basketDeliveryCountry.cityNote')}
          </small>
        </Form.Group>
        <Form.Group controlId="delivery-country-postIndex">
          <Form.Label>{t('basketDeliveryCountry.postIndex')}</Form.Label>
          <Form.Control
            type="text"
            value={deliveryCountryAddress.postIndex}
            onChange={(e) =>
              setDeliveryCountryAddress({
                ...deliveryCountryAddress,
                postIndex: e.target.value,
              })
            }
          />
        </Form.Group>
      </div>
      <Form.Group className="mt-3" controlId="delivery-country-address">
        <Form.Label>{t('basketDeliveryCountry.fullAddress')}</Form.Label>
        <Form.Control
          as="textarea"
          value={deliveryCountryAddress.address}
          onChange={(e) =>
            setDeliveryCountryAddress({
              ...deliveryCountryAddress,
              address: e.target.value,
            })
          }
        />
      </Form.Group>

      {deliveryCountryAddress.cityId > 0 &&
        deliveryCountryAddress.address.length > 0 &&
        deliveryCountryAddress.postIndex.length > 0 && (
          <Button className="mt-3" onClick={() => handleDeliveryCountry()}>
            {t('basketDeliveryCountry.confirmAddress')}
          </Button>
        )}
    </>
  );
}

export default observer(BasketDeliveryCountry);
