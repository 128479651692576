import React from 'react'
import GoodShortItem from './GoodShortItem'

function BestCategoriesGoodList({list}) {
    
  return (
    <div className='d-flex best-categories-list' style={{gap:10}}>
        {
            list && list.length>0 && list.slice(0,5).map((item,index)=>{
                return <GoodShortItem key={index} item={item}/>
            })
        }
    </div>
  )
}

export default BestCategoriesGoodList