import React, { useEffect, useState } from "react";
import { Button, Dropdown } from "react-bootstrap";
import ManageTable from "../../../components/admin/ManageTable";
import CreateGoodSeries from "./CreateGoodSeries";
import {
  fetchCreateGoodSeries,
  fetchCreateGoodSeriesAdditionalProperty,
  fetchCreateGoodSeriesColor,
  fetchCreateGoodSeriesMemory,
  fetchGetGoodSeries,
  fetchGetGoodSeriesProperties,
} from "../../../data/models/good/good-api";
import { useParams } from "react-router-dom";

function GoodSeriesProperties() {
  const { id } = useParams();

  const [memories, setMemories] = useState([]);
  const [colors, setColors] = useState([]);
  const [additionalProps, setAdditionalProps] = useState([]);

  const [newColor,setNewColor] = useState({colorName:'',hex:''})
  const [newMemory,setNewMemory] = useState('')
  const [newAP,setNewAP] = useState('')
  
  const handleCreateColor = ()=>{
    if(newColor.colorName=='') {
      alert('Пожалуйста введите название цвета')
      return;
    }
    if(newColor.hex=='') {
      alert('Пожалуйста выберите цвет в палитре')
      return;
    }
    fetchCreateGoodSeriesColor({goodSeriesId:+id,colorName:newColor.colorName,hex:newColor.hex,active:true}).then((response)=>{
      if(response.status==200)
        updateList()
    })
  }

  const handleCreateMemory = ()=>{
    if(newMemory=='') {
      alert('Пожалуйста введите значение памяти')
      return;
    }
    fetchCreateGoodSeriesMemory({goodSeriesId:+id,value:newMemory,active:true}).then((response)=>{
      if(response.status==200)
        updateList()
    })
  }

  const handleCreateAdditionalProperty = ()=>{
    if(newAP=='') {
      alert('Пожалуйста введите значение свойства')
      return;
    }
    fetchCreateGoodSeriesAdditionalProperty({goodSeriesId:+id,value:newAP,active:true}).then((response)=>{
      if(response.status==200) {
        updateList()
      }
    })
  }

  const updateList = () => {
    fetchGetGoodSeriesProperties(id).then((response) => {
      if (response.status == 200) {
        setMemories(response.data.memories);
        setColors(response.data.colors);
        setAdditionalProps(response.data.additionalProperties);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, []);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">
        Свойста модельной серии (цвета, память и доп свойство)
      </h2>
      <ManageTable className="mb-3" title={"Цвета модельного ряда"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Название</th>
            <th style={{ width: 100 }}>Цвет</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {colors.length > 0 &&
            colors.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.colorName}</td>
                  <td>
                    <div
                      style={{
                        borderRadius: "50%",
                        width: 20,
                        height: 20,
                        backgroundColor: item.hex,
                        border: "1px solid black",
                      }}
                    ></div>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td><input type="text" placeholder="введите название цвета" className="form-control" value={newColor.colorName} onChange={(e)=>setNewColor({
                ...newColor,
                colorName:e.target.value
              })} /></td>
              <td><input type="color" className="form-control form-control-color" value={newColor.hex} onChange={(e)=>setNewColor({
                ...newColor,
                hex:e.target.value
              })} /></td>
              <td> <Button onClick={()=>handleCreateColor()}>Добавить</Button> </td>
              <td></td>
            </tr>
        </tbody>
      </ManageTable>

      <ManageTable className={'mb-3'} title={"Памяти модельного ряда"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Название</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {memories.length > 0 &&
            memories.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.value}</td>
                 <td></td>
                  <td></td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td>
              <input type="text" placeholder="введите название памяти" className="form-control" value={newMemory} onChange={(e)=>setNewMemory(e.target.value)} />
              </td>
              <td>
                <Button onClick={()=>handleCreateMemory()}>Добавить</Button>
              </td>
              <td></td>
            </tr>
        </tbody>
      </ManageTable>

      <ManageTable title={"Значения доп свойства"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Название</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {additionalProps.length > 0 &&
            additionalProps.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.value}</td>
                  <td></td>
                  <td></td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td>
              <input type="text" placeholder="введите значение" className="form-control" value={newAP} onChange={(e)=>setNewAP(e.target.value)} />
              </td>
              <td>
                <Button onClick={()=>handleCreateAdditionalProperty()}>Добавить</Button>
              </td>
              <td></td>
            </tr>
        </tbody>
      </ManageTable>
    </>
  );
}

export default GoodSeriesProperties;
