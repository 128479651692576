import React from 'react'
import { Container } from 'react-bootstrap'

import { useTranslation } from 'react-i18next';

function Contacts() {
  const { t } = useTranslation();

  return (
    <Container className='pt-4' id="contacts">
      <h1 className='section-title'>{t('contacts')}</h1>
      <img src="/images/contacts-banner.jpg" className='banner' alt="" />
      <p className='mt-5'>{t('contact_description')}</p>

      <h3 className='mt-3'>{t('phones')}</h3>
      <p>{t('general_inquiries')}: <span>7878</span></p>
      <p>{t('for_partners')}: <span>+7 (747) 940-44-18</span></p>

      <h3 className='mt-3'>{t('email')}</h3>
      <p>{t('general_email')}: <span>info@azata.kz</span></p>
      <p>{t('technical_support')}: <span>support@azata.kz</span></p>
    </Container>
  );
}

export default Contacts;