import { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Form } from "react-bootstrap";
import InputMask from "react-input-mask";
import ForgotPassword from "./ForgotPassword";
import { Link } from "react-router-dom";
import { phoneToString } from "../../../helpers/phoneToString";
import { isValidEmail } from "../../../helpers/isValidEmail";
import { fetchLogin, fetchRegistration } from "../../../data/http/auth-api";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";
import { $authHost, API_URL } from "../../../data/http/axios-init";
import axios from "axios";
import { useTranslation } from "react-i18next";

function LoginAndRegistration() {

  const {store} = useContext(Context)

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setRegisterFormValues({
      phone: "",
      firstName: "",
      secondName: "",
      email: "",
      password: "",
      confirmPassword: "",
    });
    setRegistrationCompleted(false);
    setShow(false);
  }
  const { t } = useTranslation();
  const handleShow = () => setShow(true);
  const [loginSubmitBtn,setLoginSubmitBtn] = useState(t("login")) 
  const [registrationSubmitBtn,setRegistrationSubmitBtn] = useState(t("go_registration")) 

  const loginSubmit = (e) => {
    e.preventDefault();
    setLoginSubmitBtn(t('login_load'))
    fetchLogin({
      phone: phoneToString(loginFormValues.phone),
      password: loginFormValues.password,
    }).then((response) => {
      if (response.status == 200) {
        localStorage.setItem('token',response.data.token)
        localStorage.setItem('user',JSON.stringify(response.data.user))
        store.setLogged(true)
        window.location.reload()
      }
    });
  };

  const [loginFormValues, setLoginFormValues] = useState({
    phone: "",
    password: "",
  });

  const [loginCompleted, setLoginCompleted] = useState(false);

  const [registerFormValues, setRegisterFormValues] = useState({
    phone: "",
    firstName: "",
    secondName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [registrationError, setRegistrationError] = useState("");
  const [registrationCompleted, setRegistrationCompleted] = useState(false);

  const handleRegistration = (e) => {
    e.preventDefault();
    if (registerFormValues.password != registerFormValues.confirmPassword) {
      setRegistrationError(t("passwords_miss_match"));
      return false;
    }
    if (!isValidEmail(registerFormValues.email)) {
      setRegistrationError(t("invalid_email"));
      return false;
    }
    if (registerFormValues.phone.length != 16) {
      setRegistrationError(t("invalid_phone"));
      return false;
    }
    setRegistrationSubmitBtn(t('registration_load'))
    fetchRegistration({
      email: registerFormValues.email,
      firstName: registerFormValues.firstName,
      secondName: registerFormValues.secondName,
      phone: phoneToString(registerFormValues.phone),
      password: registerFormValues.password,
    }).then((response) => {
      if (response.status == 200) {
        setRegistrationCompleted(true);
      }
    });
  };

  const handleConfirmPassword = (confirmPassword) => {
    setRegisterFormValues({
      ...registerFormValues,
      confirmPassword,
    });
  };

  return (
    <>
      <button id="navbar-cabinet" className="no-mobile" onClick={() => handleShow()}>
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-person"
            viewBox="0 0 16 16"
          >
            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z" />
          </svg>
          {t("login")}
        </div>
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="border-bottom-0">
          <Modal.Title>{t("welcome")}</Modal.Title>
        </Modal.Header>
        <Modal.Body id="login-registration-modal">
          <Tabs
            defaultActiveKey="login"
            id="fill-tab-example"
            className="mb-0"
            fill
          >
            <Tab eventKey="login" className="p-0" title="Вход">
              <div className="card card-body">
                {
                    loginCompleted ? 
                    <>
                    <h5 className="text-success fw-bold text-center my-5">
                      {t("welcome")}!
                    </h5>
                    </>:
                    <Form onSubmit={(e) => loginSubmit(e)}>
                    <Form.Group className="mb-3" controlId="login.phone">
                      <Form.Label>{t('phone_number')}</Form.Label>
                      <InputMask
                        value={loginFormValues.phone}
                        onChange={(e) =>
                          setLoginFormValues({
                            ...loginFormValues,
                            phone: e.target.value,
                          })
                        }
                        mask="+7(799) 999-9999"
                        maskChar="_"
                        className={
                          "form-control " +
                          (loginFormValues.phone.length == 16 &&
                            loginFormValues.phone.includes("_"))
                        }
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="login.password">
                      <Form.Label>{t('password')}</Form.Label>
                      <Form.Control
                        type="password"
                        value={loginFormValues.password}
                        onChange={(e) =>
                          setLoginFormValues({
                            ...loginFormValues,
                            password: e.target.value,
                          })
                        }
                      />
                    </Form.Group>
  
                    <div className="d-flex justify-content-between align-items-end">
                      <ForgotPassword />
                      <Button type="submit">{loginSubmitBtn}</Button>
                    </div>
                  </Form>
                }
              </div>
            </Tab>
            <Tab eventKey="registration" title="Регистрация">
              <div className="card card-body border-top-0 rounded-0">
                {registrationCompleted ? (
                  <>
                    <h5 className="text-success fw-bold text-center my-5">
                      {t("registration_done")}!
                    </h5>
                  </>
                ) : (
                  <Form onSubmit={(e) => handleRegistration(e)}>
                    <Form.Group className="mb-3" controlId="registration.phone">
                      <Form.Label>{t('phone_number')}</Form.Label>
                      <InputMask
                        value={registerFormValues.phone}
                        onChange={(e) =>
                          setRegisterFormValues({
                            ...registerFormValues,
                            phone: e.target.value,
                          })
                        }
                        mask="+7(799) 999-9999"
                        maskChar="_"
                        className={
                          "form-control " +
                          (registerFormValues.phone.length == 16 &&
                          !registerFormValues.phone.includes("_")
                            ? "is-valid"
                            : "")
                        }
                        required={true}
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="registration.firstName"
                    >
                      <Form.Label>{t('your_first_name')}</Form.Label>
                      <Form.Control
                        type="text"
                        required={true}
                        value={registerFormValues.firstName}
                        isValid={registerFormValues.firstName.length > 0}
                        onChange={(e) =>
                          setRegisterFormValues({
                            ...registerFormValues,
                            firstName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group
                      className="mb-3"
                      controlId="registration.secondName"
                    >
                      <Form.Label>{t('your_second_name')}</Form.Label>
                      <Form.Control
                        type="text"
                        value={registerFormValues.secondName}
                        onChange={(e) =>
                          setRegisterFormValues({
                            ...registerFormValues,
                            secondName: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="registration.email">
                      <Form.Label>{t('email')}</Form.Label>
                      <Form.Control
                        type="email"
                        required={true}
                        value={registerFormValues.email}
                        isValid={isValidEmail(registerFormValues.email)}
                        onChange={(e) =>
                          setRegisterFormValues({
                            ...registerFormValues,
                            email: e.target.value,
                          })
                        }
                      />
                    </Form.Group>

                    <div className="d-flex flex-wrap" style={{ gap: 20 }}>
                      <Form.Group
                        className="mb-3"
                        controlId="registration.password"
                      >
                        <Form.Label>{t('password')}</Form.Label>
                        <Form.Control
                          type="password"
                          required={true}
                          value={registerFormValues.password}
                          onChange={(e) =>
                            setRegisterFormValues({
                              ...registerFormValues,
                              password: e.target.value,
                            })
                          }
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="registration.passwordConfirm"
                      >
                        <Form.Label>{t('confirm_password')}</Form.Label>
                        <Form.Control
                          type="password"
                          required={true}
                          isInvalid={
                            registerFormValues.password !=
                              registerFormValues.confirmPassword &&
                            registerFormValues.confirmPassword.length > 0
                          }
                          isValid={
                            registerFormValues.password ==
                              registerFormValues.confirmPassword &&
                            registerFormValues.password.length > 0
                          }
                          value={registerFormValues.confirmPassword}
                          onChange={(e) =>
                            handleConfirmPassword(e.target.value)
                          }
                        />
                      </Form.Group>
                    </div>

                    <div className="mb-3">
                      <Form.Check type={"checkbox"} id={`registration.agree`}>
                        <Form.Check.Input type={"checkbox"} required={true} />
                        <Form.Check.Label>
                          {t("i_agree_registration")}
                          <Link to="/privacy">{t("i_agree_registration_privacy")}</Link>
                        </Form.Check.Label>
                        {/* <Form.Control.Feedback type="valid">
                                          You did it!
                                      </Form.Control.Feedback> */}
                      </Form.Check>
                    </div>

                    {registrationError.length > 0 && (
                      <p className="my-1 text-danger">{registrationError}</p>
                    )}

                    <div className="d-flex justify-content-end">
                      <Button type="submit">{registrationSubmitBtn}</Button>
                    </div>
                  </Form>
                )}
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default observer(LoginAndRegistration);
