import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Container, Form, Tab, Tabs } from "react-bootstrap";
import { useParams } from "react-router-dom";
import GoodShort from "../../data/models/good/good";
import { HOME_INDEX } from "../../constants/routes";
import TreeComponent from "../../components/public/TreeComponent";
import { priceToString } from "../../helpers/priceToString";
import Comments from "../../components/public/Good/good-info/Comments";
import Accessories from "../../components/public/Good/good-info/Accessories";
import Specifications from "../../components/public/Good/good-info/Specifications";
import ShopOffers from "../../components/public/Good/good-info/ShopOffers";
import ModelLinks from "../../components/public/Good/good-info/ModelLinks";
import SwiperImages from "../../components/public/Good/good-info/SwiperImages";
import { fetchGood } from "../../data/models/good/good-api";
import { Context } from "../..";
import { observer } from "mobx-react-lite";
import { fetchCategoryTreeForGood } from "../../data/models/category/category-api";

function CatalogGood() {
  const { id } = useParams();
  const { store } = useContext(Context)
  const [good, setGood] = useState(null);
  const [updateChilds, setUpdateChilds] = useState({
    updateImages: (goodId) => { },
    updateParameters: (goodId) => { },
    updateAccessories: (goodId) => { },
    updateShopOffers: (goodId) => { },
    updateComments: (goodId) => { }
  })

  useEffect(() => {
    updateGood(id);
  }, [id]);

  const [tree, setTree] = useState([
    {
      title: "Главная",
      link: HOME_INDEX,
    }
  ])

  const updateTree = (categoryId) => {
    fetchCategoryTreeForGood(categoryId).then((response) => {
      if (response.status == 200) {
        setTree(response.data.reverse().map(item => {
          return {
            title: item.name,
            link: `/catalog/${item.id}`
          }
        }))
      }
    })
  }

  const updateGood = (goodId) => {
    fetchGood(goodId).then((response) => {
      if (response.status == 200) {
        setGood(new GoodShort(response.data));
        for (var f in updateChilds) {
          updateChilds[f](goodId)
        }
        updateTree(response.data.categoryId)
      }
    });
  };

  return (
    <Container>
      <TreeComponent list={tree} />
      {good && (
        <>
          <div id="good-info">
            <div className="main-info">
              <div className="row">
                <div className="col-md-6">
                  <SwiperImages setUpdate={setUpdateChilds} goodId={id} />
                </div>
                <div className="col-md-6">
                  <div className="content">
                    <p className="mb-2">
                      Артикул: <span>{good.article}</span>
                    </p>
                    <h4>{good.name}</h4>
                    <h3>{priceToString(store.convertedCurrency(good.price))}</h3>

                    <ModelLinks
                      goodId={id}
                      updateGood={updateGood}
                      show={[3,12, 13].includes(good.categoryId)}
                    />
                  </div>
                </div>
              </div>
            </div>

            <ShopOffers setUpdate={setUpdateChilds} goodId={id} />

            <div className="good-tabs">
              <Tabs
                defaultActiveKey="specifications"
                id="good-tabs"
                className="pill-tabs"
              >
                <Tab eventKey="specifications" title="Характеристики">
                  <Specifications setUpdate={setUpdateChilds} goodId={id} />
                </Tab>
                <Tab eventKey="description" title="Описание">
                  <div
                    dangerouslySetInnerHTML={{ __html: good.description }}
                  ></div>
                </Tab>
                <Tab eventKey="accessories" title="Аксессуары">
                  <Accessories setUpdate={setUpdateChilds} goodId={id} />
                </Tab>

                <Tab eventKey="comments" title="Отзывы">
                  <Comments setUpdate={setUpdateChilds} goodId={id} />
                </Tab>
              </Tabs>
            </div>
          </div>
        </>
      )}
    </Container>
  );
}

export default observer(CatalogGood);
