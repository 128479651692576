import { $authHost, $host } from "../../http/axios-init";

export const fetchGetCities = async (query) => {
  try {
    const response = await $host.get("/api/city/GetCities?query=" + query);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGetCity = async (id) => {
  try {
    const response = await $host.get("/api/city/GetCity/" + id);
    return response;
  } catch (e) {
    return e.response;
  }
};


export const fetchCityCreate = async (fd) => {
  try {
    const response = await $authHost.post("/api/city/CreateCity",fd);
    return response;
  } catch (e) {
    return e.response;
  }
};


export const fetchCityUpdate = async (fd) => {
  try {
    const response = await $authHost.put("/api/city/UpdateCity",fd);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchCityDelete = async (id) => {
  try {
    const response = await $authHost.delete("/api/city/DeleteCity/"+id);
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGetCityDistricts = async ({ cityId, query }) => {
  try {
    const response = await $host.get(
      `/api/city/GetDistricts?cityId=${cityId}&query=${query}`
    );
    return response;
  } catch (e) {
    return e.response;
  }
};

export const fetchGetCityDistrict = async (id) =>{
  try {
    const response = await $host.get(
      `/api/city/GetDistrict/`+id
    );
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCityDistrictCreate = async (fd) =>{
  try {
    const response = await $authHost.post(`/api/city/CreateDistrict`,fd);
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCityDistrictUpdate = async (fd) =>{
  try {
    const response = await $authHost.put(`/api/city/UpdateDistrict`,fd);
    return response;
  } catch (e) {
    return e.response;
  }
}

export const fetchCityDistrictDelete = async (id) =>{
  try {
    const response = await $authHost.delete(`/api/city/DeleteDistrict/`+id);
    return response;
  } catch (e) {
    return e.response;
  }
}