import React, { useContext, useState } from "react";
import { Context } from "../../../..";
import { observer } from "mobx-react-lite";
import { Button, Form } from "react-bootstrap";
import { fetchSetAnswerForGoodComment } from "../../../../data/models/good/good-api";

function AddAnswerForCommentForm({ goodCommentId, updateComments, goodId }) {
  const { store } = useContext(Context);

  const [text, setText] = useState("");
  const handleAnswerToComment = () => {
    fetchSetAnswerForGoodComment({
      goodCommentId,
      text,
    }).then((response) => {
      if (response.status == 200) {
        updateComments(goodId);
        setText("");
      }
    });
  };

  return (
    <>
      {store.logged && (
        <>
          <h5>Ваш ответ:</h5>
          <div className="d-flex" style={{ gap: 20 }}>
            <Form.Control
              type="text"
              className="flex-grow-1"
              size="sm"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Ваш ответ на комментарий"
            />
            <Button onClick={() => handleAnswerToComment()}>
              Ответить
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default observer(AddAnswerForCommentForm);
