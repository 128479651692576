import React, { useContext } from "react";
import { Button, Tab, Tabs } from "react-bootstrap";
import BasketPaymentKaspi from "./BasketPaymentKaspi";
import BasketPaymentCash from "./BasketPaymentCash";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";

function BasketPayment() {

  const {store} = useContext(Context)

  return (
    <>
      <h5 className="step-title">
        <div className="step-box">3</div>
        Оплата
      </h5>

      <div className="basket-payment mb-3">

        <Button onClick={()=>store.setPayment(1)} className={"me-3 "} variant={(store.basket.payment == 1 ?'primary':'outline-primary')}>Каспи</Button>
        <Button onClick={()=>store.setPayment(2)} variant={(store.basket.payment == 2 ?'primary':'outline-primary')}>Наличными</Button>

       
      </div>
    </>
  );
}

export default observer(BasketPayment);
