import React, { useContext, useEffect, useState } from "react";
import AddCommentForm from "./AddCommentForm";
import GoodShort from "../../../../data/models/good/good";
import { fetchGoodComments, fetchSetAnswerForGoodComment } from "../../../../data/models/good/good-api";
import { Context } from "../../../..";
import { observer } from "mobx-react-lite";
import { Button, Form } from "react-bootstrap";
import AddAnswerForCommentForm from "./AddAnswerForCommentForm";

function Comments({ goodId, setUpdate }) {
  const [comments, setComments] = useState([]);
  const [currentGoodId,setCurrentGoodId] = useState(goodId)
  const [totalRating,setTotalRating] = useState(0)

  const updateComments = (id) => {
    fetchGoodComments(id).then((response) => {
      if (response.status == 200) {
        setComments(response.data);
        setTotalRating(GoodShort.getAverageRating(response.data))
        setCurrentGoodId(id)
      }
    });
  };

  const {store} = useContext(Context)

  useEffect(() => {
    updateComments(goodId);
    setUpdate((prev) => ({ ...prev, updateComments }));
  }, []);



  return (
    <>
      {comments.length == 0 ? (
        <h4>У товара пока нет отзывов, будьте первым!</h4>
      ) : (
        <>
          <h4 className="mb-3">
            Общий рейтинг: {totalRating}
          </h4>

          {comments.map((item, index) => {
            return (
              <div key={index} className="comment-item">
                <div
                  className={"header " + (item.recommended ? "good" : "bad")}
                >
                  <div className="d-flex">
                    <div
                      style={{ minWidth: 300 }}
                      className="d-flex align-items-center"
                    >
                      {item.recommended ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-check-circle-fill me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-x-circle-fill me-2"
                          viewBox="0 0 16 16"
                        >
                          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                        </svg>
                      )}
                      {item.customer}{" "}
                      {item.recommended ? "рекомендует" : "не рекомендует"}
                    </div>
                    <div className="d-flex align-items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-star-fill me-2"
                        viewBox="0 0 16 16"
                      >
                        <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
                      </svg>
                      {item.rating}
                    </div>
                  </div>
                  <span>{item.date}</span>
                </div>
                <div className="body">
                  {item.comment && item.comment.length > 0 && (
                    <>
                      <h5>Комментарий:</h5>
                      <p>{item.comment}</p>
                    </>
                  )}
                  {item.advantages && item.advantages.length > 0 && (
                    <>
                      <h5>Достойнства:</h5>
                      <p>{item.advantages}</p>
                    </>
                  )}
                  {item.disadvantages && item.disadvantages.length > 0 && (
                    <>
                      <h5>Недостатки:</h5>
                      <p>{item.disadvantages}</p>
                    </>
                  )}

                  {item.answers.length > 0 && (
                    <>
                      <h5>Ответы на отзыв</h5>
                      {item.answers.map((answer, a_index) => {
                        return (
                          <div key={a_index} className="comment-answer-item">
                            <p className="fw-bold mb-2">
                              {answer.customer} - {answer.date}
                            </p>
                            <p className="ps-3">{answer.text}</p>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <AddAnswerForCommentForm goodCommentId={item.id} goodId={currentGoodId} updateComments={updateComments}/> 
                </div>
                <div className="footer d-none">
                  <p>
                    Оцените отзыв:
                    <button className="good mx-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-check-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                      </svg>{" "}
                      {item.likes}
                    </button>
                    <button className="bad">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        className="bi bi-x-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                      </svg>{" "}
                      {item.dislikes}
                    </button>
                  </p>
                </div>
              </div>
            );
          })}
        </>
      )}

      <AddCommentForm goodId={goodId} updateComments={updateComments}/>
    </>
  );
}

export default observer(Comments);
