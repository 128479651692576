import React, { useEffect, useState } from 'react'
import { fetchCategories, fetchCategory, fetchCategoryCreate, fetchCategoryDelete, fetchCategoryUpdate } from '../../../data/models/category/category-api';
import CategoryModel from '../../../data/models/category/category';
import ManageTable from '../../../components/admin/ManageTable';
import { Dropdown } from 'react-bootstrap';

function Category() {
  const $fetch = {
    list: fetchCategories,
    one: fetchCategory,
    create: fetchCategoryCreate,
    update: fetchCategoryUpdate,
    delete: fetchCategoryDelete,
  };

  const [filter, setFilter] = useState({
    query: "",
  });
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const updateList = () => {
    $fetch.list(filter).then((response) => {
      if (response.status == 200) {
        let dto_list = [];
        for (let item of Array.from(response.data)) {
          dto_list.push(new CategoryModel(item));
        }
        setList(dto_list);
        setTotalCount(response.data.count);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, [filter]);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">Управление производителями</h2>
      {/* <Create fetchCreate={$fetch.create} updateList={updateList} /> */}
      <ManageTable title={"Список категорий"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 200 }}>Название</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list &&
            list.length > 0 &&
            list.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        {/* <Update
                          fetchOne={$fetch.one}
                          fetchUpdate={$fetch.update}
                          id={item.id}
                          updateList={updateList}
                        />
                        <Delete
                          fetchDelete={$fetch.delete}
                          id={item.id}
                          updateList={updateList}
                        /> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ManageTable>
    </>
  );
}

export default Category