export const ABOUT = "about";
export const CONTACTS = "contacts";
export const DELIVERY_AND_PURCHASE = "deliveryAndPurchase";
export const HOW_MAKE_ORDER = "howMakeOrder";
export const PRIVACY = "privacy";
export const RETURN_OR_EXCHANGE = "returnOrExchange";
export const SUPPORT = "support";
export const WHOSALES = "whosales";
export const BE_PARTNER = "bePartner";
export const FINDER = "finder";
export const BASKET = "basket";
export const CATALOG = "catalog";
export const CATALOG_BRAND = "catalog/brand";
export const CATALOG_CATEGORY = "catalog/category";
export const CATALOG_GOOD = "catalog/good";
export const FAVOURITES = "cabinet";
export const CABINET = "cabinet";
export const HOME_INDEX = "/";
export const GIFT_CARDS = "giftcards"
export const PUBLIC_OFFER = "publicoffer"
export const HOME_FAQ = "faq"
export const PROMOTIONS_AND_SERVICES = "promoandservice"
export const DELIVERY_AND_PAYMENT = "deliveryandpayment"
export const AZATA_SERVICE = "http://localhost:3011/"
export const AZATA_WHOSELES = "http://localhost:3012/"
export const FOR_SHOPS = "forshops"
export const POLICY_OF_MARKETPLACE = "policyofmarketplace"
export const POLICY_OF_PRIVACY = "policyofprivacy"
export const NEWS = "news"
export const VACANCY = "vacancy"

export const ADMIN_BRANDS = "brands";
export const ADMIN_INDEX = "";
export const ADMIN_CATEGORIES = "categories";
export const ADMIN_GOODS = "goods";
export const ADMIN_CITIES = "cities";
export const ADMIN_CITY_DISTRICTS = "cityDistricts";
export const ADMIN_ROLES = "roles";
export const ADMIN_USERS = "users";
export const ADMIN_PARAMS_GROUP = "param-groups"
export const ADMIN_PARAMS_GROUP_PARAMS = "param-groups/params"
export const ADMIN_GOOD_SERIES = "good-series"
export const ADMIN_GOOD_SERIES_PROPERTIES = "good-series/props"


export const SHOP_INDEX = "";
export const SHOP_ORDERS = "orders";
export const SHOP_ORDER_DETAILS = "order";
export const SHOP_GOODS = "goods";
export const SHOP_REPORTS_ORDERS = "reports/orders";