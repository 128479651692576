import React from "react";
import { Container } from "react-bootstrap";
import { BE_PARTNER } from "../../../constants/routes";
import { Link } from "react-router-dom";

function ForShops() {
  return (
    <Container className="pt-4" id="for-shops">
      <h1 className="section-title mb-4">
        Для магазинов, которые хотят продавать на Azata.kz
      </h1>

      <img src="/images/for-shops.jpeg" className="banner" alt="" />

      <p className="my-5">
        Вы ищете идеальное место, чтобы продавать электронику и аксессуары к
        ней? <span>Azata.kz - ваш идеальный выбор!</span>
      </p>

      <h2>Почему Azata.kz?</h2>

      <div className="row">
        <div className="col-md-4">
          <div className="item">
            <h3>Узкое направление</h3>
            <p>
              Мы специализируемся только на электронике и аксессуарах к ней. Это
              означает, что ваш магазин будет виден именно тем покупателям,
              которые ищут такие товары. Наши клиенты - это энтузиасты
              технологий, которые ценят качество и надежность.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="item">
            <h3>Низкая комиссия</h3>
            <p>
              Мы предлагаем одну из самых низких комиссий в индустрии. Вы будете
              платить только за успешные продажи, что поможет вам сэкономить
              деньги и увеличить прибыль.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="item">
            <h3>Подробная аналитика и отчеты</h3>
            <p>
              Мы предоставляем вам доступ к подробной аналитике и отчетам о
              продажах. Это позволит вам лучше понимать потребности вашей
              аудитории и принимать более обоснованные решения для вашего
              бизнеса.
            </p>
          </div>
        </div>
      </div>

      <h2 className="mt-5">Как начать продавать:</h2>

      <ol>
        <li>
          <span>Регистрация: </span> Создайте аккаунт на Azata.kz и заполните
          информацию о вашем магазине.{" "}
          <Link to={"/"+BE_PARTNER}>К регистрации</Link>
        </li>
        <li>
          <span>Добавление товаров: </span> Загрузите свой ассортимент товаров
          на наш платформу. <br /> Вы можете легко управлять списками продуктов
          и их описаниями.
        </li>
        <li>
          <span>Продажа и доставка: </span> Продавайте ваши товары и выбирайте
          удобные способы доставки для клиентов.
        </li>
        <li>
          <span>Анализ и оптимизация: </span> Используйте нашу аналитику и
          отчеты, чтобы оптимизировать свой бизнес и увеличивать прибыль.
        </li>
      </ol>

      <p className="fw-bold mt-5">
        Присоединяйтесь к нашему маркетплейсу и начните увеличивать вашу
        клиентскую базу и продажи уже сегодня!
      </p>
      <p>
        Если у вас есть какие-либо вопросы или вам нужна дополнительная
        информация, не стесняйтесь связаться с нашей командой поддержки.
      </p>
      <p>
        С уважением, <br />
        Команда Azata.kz
      </p>
    </Container>
  );
}

export default ForShops;
