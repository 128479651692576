import React from "react";
import { Container } from "react-bootstrap";

function Vacancy() {
  return (
    <Container className="pt-4" id="vacancy">
      <h1 className="section-title">Вакансии и карьера в Azata</h1>

      <p>Пока нет вакансий</p>
    </Container>
  );
}

export default Vacancy;
