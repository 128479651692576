import { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Context } from "../../..";
import { observer } from "mobx-react-lite";

function BasketOrderCompleteModal({ setUpdate }) {
  const [show, setShow] = useState(false);
  const [orderId, setOrderId] = useState(0);

  const {store} = useContext(Context);

  const handleClose = () => {
    setShow(false);
    store.resetBakset()
    
  }
  const handleShow = () => setShow(true);

  const handleNext = () => {
    store.resetBakset()
    window.location.href='/'
  };

  const updateData = (id) => {
    if (id) {
      setOrderId(id);
      handleShow();
    }
  };

  useEffect(() => {
    setUpdate((prev)=>({
        ...prev,
        updateComplete:updateData
    }));
  }, []);

  return (
    <>
      <Modal show={show} size={"md"} onHide={handleClose}>
        <Modal.Header closeButton className="border-bottom-0"></Modal.Header>
        <Modal.Body>
          <div className="thanks-for-order">
            <h2 className="fw-bold">Ваш заказ оформлен!</h2>
            <h5>
              Номер заказа:
            </h5>
            <h1>
                {orderId}
            </h1>

            <h5 className="my-5">
              Спасибо, что выбрали наш магазин. <br />
              Наши менеджера свяжутся с Вами <br /> для уточнения деталей по
              заказу.
            </h5>
            <Button onClick={() => handleNext()}>
              Перейти к новым покупкам
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default observer(BasketOrderCompleteModal);
