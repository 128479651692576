import React from 'react';
import { useTranslation } from 'react-i18next';

function WhyUs() {
  const { t } = useTranslation();

  const list = [
    {
      image: "https://object.pscloud.io/cms/cms/Banners/banner_200_magazinov_v_100_gorodah_5015_ru-Ru_desktop.png",
      title: t('whyUs.item1.title'),
      description: t('whyUs.item1.description'),
    },
    {
      image: "https://object.pscloud.io/cms/cms/Banners/banner_akcii_i_specialniye_predlozheniya_5018_ru-Ru_desktop.png",
      title: t('whyUs.item2.title'),
      description: t('whyUs.item2.description'),
    },
    {
      image: "https://object.pscloud.io/cms/cms/Banners/banner_viygodnaya_rassrochka_5016_ru-Ru_desktop.png",
      title: t('whyUs.item3.title'),
      description: t('whyUs.item3.description'),
    },
    {
      image: "https://object.pscloud.io/cms/cms/Banners/banner_biystraya_i_berezhnaya_dostavka_5017_ru-Ru_desktop.png",
      title: t('whyUs.item4.title'),
      description: t('whyUs.item4.description'),
    },
  ];

  return (
    <>
      <h4 className='section-title mt-5'>{t('whyUs.title')}</h4>
      <div className="why-us">
        {list.length > 0 &&
          list.map((item, index) => (
            <div key={index} className="why-us-item">
              <div>
                <img src={item.image} alt="" />
                <h5>{item.title}</h5>
                <p>{item.description}</p>
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default WhyUs;
