import { observer } from "mobx-react-lite";
import React, { useContext, useEffect } from "react";
import { Context } from "../../..";
import { ErrorMessage, Field, Formik } from "formik";
import { Alert, Button, Form, FormControl, InputGroup } from "react-bootstrap";
import ReactInputMask from "react-input-mask";
import { phoneToString } from "../../../helpers/phoneToString";
import { useTranslation } from 'react-i18next';

function BasketContacts() {
  const { store } = useContext(Context);
  const { t } = useTranslation();

  const validateEmail = (value) => {
    let error;
    if (!value) {
      error = t('basketContacts.emailRequired');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = t('basketContacts.invalidEmailFormat');
    }
    return error;
  };

  const initialValues = {
    firstName: store.user.firstName,
    secondName: store.user.secondName,
    email: store.user.email,
    phone: store.user.phone,
  };


  return (
    <>
      <h5 className="step-title">
        <div className="step-box">4</div>
        {t('basketContacts.contactDetails')}
      </h5>
      <div className="basket-contacts mb-3">
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            store.setBaketContacts({
                ...values,
                phone:phoneToString(values.phone)
            })
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Group controlId="firstName" className="mb-3 d-block">
                    <Form.Label>{t('basketContacts.yourName')}</Form.Label>
                    <Field name="firstName" className="w-100">
                      {({ field }) => (
                        <FormControl placeholder={t('basketContacts.firstName')} {...field} />
                      )}
                    </Field>
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Label>{t('basketContacts.yourSurname')}</Form.Label>
                    <Field name="secondName">
                      {({ field }) => (
                        <FormControl placeholder={t('basketContacts.surname')} {...field} />
                      )}
                    </Field>
                  </Form.Group>
                </div>
              </div>
              <Form.Group className="mb-3">
                <Form.Label>{t('basketContacts.yourEmail')}</Form.Label>
                <Field name="email" validate={validateEmail}>
                  {({ field }) => (
                    <div>
                      <FormControl
                        type="email"
                        placeholder={t('basketContacts.email')}
                        {...field}
                      />
                      <ErrorMessage name="email">
                        {(msg) => <Alert variant="danger">{msg}</Alert>}
                      </ErrorMessage>
                    </div>
                  )}
                </Field>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t('basketContacts.yourPhone')}</Form.Label>
                <Field name="phone">
                  {({ field }) => (
                    <div>
                      <ReactInputMask
                        mask="+7 (999) 999-99-99"
                        maskChar=" "
                        {...field}
                      >
                        {(inputProps) => (
                          <FormControl
                            type="text"
                            placeholder={t('basketContacts.phone')}
                            {...inputProps}
                          />
                        )}
                      </ReactInputMask>
                    </div>
                  )}
                </Field>
              </Form.Group>
              <Button type="submit" disabled={isSubmitting}>
                {t('basketContacts.confirm')}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
}

export default observer(BasketContacts);
