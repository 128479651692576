import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Button, Toast } from "react-bootstrap";
import { priceToString } from "../../../../helpers/priceToString";
import { fetchGetPartnerOffers } from "../../../../data/models/good/good-api";
import { Context } from "../../../..";
import { observer } from "mobx-react-lite";

const ShopOffers = ({ goodId, setUpdate }) => {
  const { store } = useContext(Context);

  const [list, setList] = useState([]);

  const updateShopOffers = (id) => {
    fetchGetPartnerOffers(id).then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };

  useEffect(() => {
    updateShopOffers(goodId);
    setUpdate((prev) => ({ ...prev, updateShopOffers }));
  }, []);

  return (
    <div className="prices">

      <h4>Предложения от магазинов</h4>
      <ul className="list-group">
        {list.length > 0 &&
          list.map((item, index) => {
            if (!item.isActive) return <></>;
            return (
              <li className="list-group-item" key={index}>
                <div className="shop-name">{item.partnerName}</div>
                <div className="shop-delivery">
                  Доставка: <b>{item.deliveryInfo}</b>
                </div>
                <div className="shop-pickup">
                  Самовывоз: <b>{item.pickupInfo}</b>
                </div>
                <div className="shop-price">{priceToString(store.convertedCurrency(item.price))} </div>
                <div>
                  <Button
                    onClick={() => {
                      store.setBasket(
                        {
                          id: goodId,
                          price: item.price,
                          shopId: item.partnerId,
                        },
                        1
                      );
                      store.setToast({
                        goodId: goodId,
                        title: 'Корзина',
                        text: 'Товар добавлени в корзину'
                      })
                    }}
                  >
                    Купить
                  </Button>
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default observer(ShopOffers);
