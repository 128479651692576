import CatalogGood from "../pages/catalog/CatalogGood";
import AboutCompany from "../pages/Home/AboutCompany";
import Contacts from "../pages/Home/Contacts";
import DeliveryAndPurchase from "../pages/Home/DeliveryAndPurchase";
import HowMakeOrder from "../pages/Home/HowMakeOrder";
import Privacy from "../pages/Home/Privacy";
import ReturnOrExchange from "../pages/Home/ReturnOrExchange";
import Support from "../pages/Home/Support";
import Whosales from "../pages/Home/Whosales";
import Finder from "../pages/catalog/Finder";
import Basket from "../pages/catalog/Basket";
import Catalog from "../pages/catalog/Catalog";
import CatalogBrand from "../pages/catalog/CatalogBrand";
import CatalogCategory from "../pages/catalog/CatalogCategory";
import BePartner from "../pages/partner/BePartner";
import {
  ABOUT,
  ADMIN_BRANDS,
  ADMIN_CATEGORIES,
  ADMIN_CITIES,
  ADMIN_CITY_DISTRICTS,
  ADMIN_GOODS,
  ADMIN_GOOD_SERIES,
  ADMIN_GOOD_SERIES_PROPERTIES,
  ADMIN_INDEX,
  ADMIN_PARAMS_GROUP,
  ADMIN_PARAMS_GROUP_PARAMS,
  ADMIN_ROLES,
  ADMIN_USERS,
  BASKET,
  BE_PARTNER,
  CABINET,
  CATALOG,
  CATALOG_BRAND,
  CATALOG_CATEGORY,
  CATALOG_GOOD,
  CONTACTS,
  DELIVERY_AND_PAYMENT,
  DELIVERY_AND_PURCHASE,
  FAVOURITES,
  FINDER,
  FOR_SHOPS,
  GIFT_CARDS,
  HOME_FAQ,
  HOME_INDEX,
  HOW_MAKE_ORDER,
  NEWS,
  POLICY_OF_MARKETPLACE,
  POLICY_OF_PRIVACY,
  PRIVACY,
  PROMOTIONS_AND_SERVICES,
  PUBLIC_OFFER,
  RETURN_OR_EXCHANGE,
  SHOP_GOODS,
  SHOP_INDEX,
  SHOP_ORDERS,
  SHOP_ORDER_DETAILS,
  SHOP_REPORTS_ORDERS,
  SUPPORT,
  VACANCY,
  WHOSALES,
} from "./routes";
import Cabinet from "../pages/Home/Cabinet";
import HomeIndex from "../pages/Home/HomeIndex";
import Brands from "../pages/admin/brand/Brands";
import AdminIndex from "../pages/admin/AdminIndex";
import Category from "../pages/admin/category/Category";
import Goods from "../pages/admin/good/Good";
import Cities from "../pages/admin/city-district/Cities";
import CityDistricts from "../pages/admin/city-district/CityDistricts";
import Roles from "../pages/admin/roles/Roles";
import Users from "../pages/admin/users/Users";
import ShopIndex from "../pages/shop/ShopIndex";
import ShopOrders from "../pages/shop/orders/ShopOrders";
import ShopOrderDetails from "../pages/shop/orders/ShopOrderDetails";
import ParameterGroups from "../pages/admin/parameter/ParameterGroups";
import Parameters from "../pages/admin/parameter/Parameters";
import ShopGoods from "../pages/shop/goods/ShopGoods";
import GoodSeries from "../pages/admin/good-series/GoodSeries";
import GiftCards from "../pages/Home/FooterLinks/GiftCards";
import PublicOffer from "../pages/Home/FooterLinks/PublicOffer";
import FAQ from "../pages/Home/FooterLinks/FAQ";
import PromotionsAndServices from "../pages/Home/FooterLinks/PromotionsAndServices";
import DeliveryAndPayment from "../pages/Home/FooterLinks/DeliveryAndPayment";
import ForShops from "../pages/Home/FooterLinks/ForShops";
import PolicyOfMarketplace from "../pages/Home/FooterLinks/PolicyOfMarketplace";
import News from "../pages/Home/FooterLinks/News";
import Vacancy from "../pages/Home/FooterLinks/Vacancy";
import ReportOrders from "../pages/shop/reports/Orders";
import GoodSeriesProperties from "../pages/admin/good-series/GoodSeriesProperties";
 
export const publicRoutes = [
  { path: ABOUT, element: <AboutCompany /> },
  { path: CONTACTS, element: <Contacts /> },
  { path: DELIVERY_AND_PURCHASE, element: <DeliveryAndPurchase /> },
  { path: HOW_MAKE_ORDER, element: <HowMakeOrder /> },
  { path: POLICY_OF_PRIVACY, element: <Privacy /> },
  { path: RETURN_OR_EXCHANGE, element: <ReturnOrExchange /> },
  { path: SUPPORT, element: <Support /> },
  { path: WHOSALES, element: <Whosales /> },
  { path: BE_PARTNER, element: <BePartner /> },
  { path: FINDER + "/:query", element: <Finder /> },
  { path: BASKET, element: <Basket /> },
  { path: CATALOG, element: <Catalog /> },
  { path: CATALOG_BRAND + "/:id", element: <CatalogBrand /> },
  { path: CATALOG_CATEGORY + "/:categoryTranslit", element: <CatalogCategory /> },
  { path: CATALOG_GOOD + "/:id", element: <CatalogGood /> },
  {
    path: FAVOURITES + "/:tab",
    element: <Cabinet timestamp={new Date().toString()} />,
  },
  { path: CABINET, element: <Cabinet timestamp={new Date().toString()} /> },
  { path: HOME_INDEX, element: <HomeIndex /> },
  { path: GIFT_CARDS, element: <GiftCards /> },
  { path: PUBLIC_OFFER, element: <PublicOffer /> },
  { path: HOME_FAQ, element: <FAQ /> },
  { path: PROMOTIONS_AND_SERVICES, element: <PromotionsAndServices /> },
  { path: DELIVERY_AND_PAYMENT, element: <DeliveryAndPayment /> },
  { path: FOR_SHOPS, element: <ForShops /> },
  { path: POLICY_OF_MARKETPLACE, element: <PolicyOfMarketplace /> },
  { path: NEWS, element: <News /> },
  { path: VACANCY, element: <Vacancy /> },
];

export const adminRoutes = [
  { path: ADMIN_BRANDS, element: <Brands /> },
  { path: ADMIN_INDEX, element: <AdminIndex /> },
  { path: ADMIN_CATEGORIES, element: <Category /> },
  { path: ADMIN_GOODS, element: <Goods /> },
  { path: ADMIN_GOOD_SERIES, element: <GoodSeries /> },
  { path: ADMIN_GOOD_SERIES_PROPERTIES+"/:id", element: <GoodSeriesProperties /> },
  { path: ADMIN_CITIES, element: <Cities /> },
  {
    path: "cities/" + ADMIN_CITY_DISTRICTS + "/:id",
    element: <CityDistricts />,
  },
  { path: ADMIN_ROLES, element: <Roles /> },
  { path: ADMIN_USERS, element: <Users /> },
  { path: ADMIN_PARAMS_GROUP, element: <ParameterGroups /> },
  { path: ADMIN_PARAMS_GROUP_PARAMS+"/:groupId", element: <Parameters /> },
];

export const shopRoutes = [
  { path: SHOP_INDEX, element: <ShopIndex /> },
  { path: SHOP_ORDERS + "/:type", element: <ShopOrders /> },
  { path: SHOP_ORDER_DETAILS + "/:id", element: <ShopOrderDetails /> },
  { path: SHOP_GOODS, element: <ShopGoods /> },
  { path: SHOP_REPORTS_ORDERS, element: <ReportOrders /> },
];
