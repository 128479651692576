import React from 'react';
import { Container } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CATALOG_GOOD } from '../../../../constants/routes';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function Banner() {
    const { t } = useTranslation();

    const slides = [
        {
            link: '/catalog/good/116',
            img: 'https://www.apple.com/v/home/be/images/heroes/iphone-15-pro/hero_iphone15pro__i70z9oz3hj2i_largetall.jpg',
            title: 'Новый Apple IPhone 15 Pro',
            description: 'Титан. Настолько сильным. Такой легкий. Итак, Про.',
            color: 'dark'
        },
        {
            link: '/catalog/good/85',
            img: 'https://www.apple.com/v/home/be/images/heroes/iphone-15/hero_iphone15_announce__uuemlcwczn6u_largetall.jpg',
            title: 'Новый Apple Iphone 15',
            description: 'Новая камера. Новый дизайн. Ньюфория.',
            color: 'light'
        }
    ];

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
        >
            {
                slides.length > 0 && slides.map((item, index) => {
                    return <SwiperSlide key={index}>
                        <div className={`home-index-banner-slider ${item.color}`}>
                            <img src={item.img} alt="" />
                            <div className="content">
                                <Container>
                                    <h2 className={item.color}>{item.title}</h2>
                                    <h5 className={item.color}>{item.description}</h5>
                                    <Link to={item.link}>{t('banner.learnMore')}</Link>
                                </Container>
                            </div>
                        </div>
                    </SwiperSlide>
                })
            }
        </Swiper>
    );
}

export default Banner;
