import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { CATALOG_BRAND } from '../../../constants/routes'
import {fetchBrandList} from '../../../data/models/brand/brand-api'
import Brand from '../../../data/models/brand/brand'

function BestBrandsList() {

    const [list, setList] = useState([
        {
            id: 1,
            title: '',
            image: '/images/loading.gif'
        },
        {
            id: 2,
            title: 'Samsung',
            image: '/images/loading.gif'
        },
        {
            id: 1,
            title: 'Apple',
            image: '/images/loading.gif'
        },
        {
            id: 1,
            title: 'Apple',
            image: '/images/loading.gif'
        },
        {
            id: 1,
            title: 'Apple',
            image: '/images/loading.gif'
        },
        {
            id: 1,
            title: 'Apple',
            image: '/images/loading.gif'
        },
        {
            id: 1,
            title: 'Apple',
            image: '/images/loading.gif'
        },
        {
            id: 1,
            title: 'Apple',
            image: '/images/loading.gif'
        }
    ])

    useState(()=>{
        fetchBrandList({
            query:''
        }).then((response)=>{
            if(response.status==200) {
                let dto_list = []
                for(let item of Array.from(response.data.rows.slice(0,8))) {
                    dto_list.push(new Brand(item))
                }
                setList(dto_list)
            }
        })
    },[])

    return (
        <div className='best-brands-list'>
            {
                list.length > 0 && list.map((item, index) => {
                    return <div key={index} className="brand-container">
                        <Link to={CATALOG_BRAND + "/" + item.id}>
                            <div className="brand-item">
                                <img className='rounded' src={item.image} />
                            </div>
                        </Link>
                    </div>
                })
            }
        </div>
    )
}

export default BestBrandsList