import React, { useContext, useEffect, useState } from "react";
import GoodShort from "../../data/models/good/good";
import { Button } from "react-bootstrap";
import { priceToString } from "../../helpers/priceToString";
import { fetchGoods } from "../../data/models/good/good-api";
import { Context } from "../..";
import { Link } from "react-router-dom";
import { CATALOG_GOOD } from "../../constants/routes";
import { useTranslation } from 'react-i18next';

function GridGoods() {
  const { store } = useContext(Context);
  const { t } = useTranslation();

  const [list, setList] = useState([]);
  useEffect(() => {
    fetchGoods({
      categoryId: 0,
      brandId: 0,
      minPrice: 0,
      maxPrice: 0,
      query: " ",
      page: 1,
      perPage: 10,
    }).then((response) => {
      if (response.status == 200) {
        var list = [];
        for (var item of response.data.rows.slice(0, 6)) {
          list.push(new GoodShort(item));
        }
        setList(list);
      }
    });
  }, []);

  return (
    <div className="no-mobile">
      <h4 className="section-title">{t('gridGoods.hits')}</h4>
      <div className="grid-goods">
        {list.length > 0 &&
          list.map((item, index) => {
            return (
              <div className={"block block" + (index + 1)} key={index}>
                <Link to={CATALOG_GOOD + "/" + item.id}>
                  <img src={item.image} />
                  <div className="info">
                    <p className="name">{item.name}</p>
                    <div className="d-flex">
                      <h5>{priceToString(item.price)} тг</h5>
                      <Button
                        size={"sm"}
                        onClick={() => {
                          store.setBasket(
                            {
                              id: item.id,
                              price: item.price,
                              shopId: item.shopId,
                            },
                            1
                          );
                          store.setToast({
                            goodId: item.id,
                            title: t('gridGoods.addToCart'),
                            text: t('gridGoods.addedToCart', { itemName: item.name }),
                          });
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-cart"
                          viewBox="0 0 16 16"
                        >
                          <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                        </svg>
                      </Button>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default GridGoods;
