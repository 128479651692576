import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../..";
import { Button } from "react-bootstrap";
import { fetchGetPartnerById } from "../../../data/models/partner/partner-api";
import GoodShort from "../../../data/models/good/good";
import BasketDeliveryPickupGoodItem from "./BasketDeliveryPickupGoodItem";
import { autorun } from "mobx";
import { useTranslation } from 'react-i18next';

function BasketDeliveryPickup() {
  const { store } = useContext(Context);
  const [pickupPoints, setPickupPoints] = useState([]);
  const { t } = useTranslation();

  const handleDeliveryPickup = () => {
    store.setDelivery({
      type: "pickup",
      price: 0,
      address: {},
    });
  };

  const updateData = () => {
    setPickupPoints([]);
    let shopIds = [];
    store.basket.goods.map((m) => {
      if (!shopIds.includes(m.shopId)) {
        shopIds.push(m.shopId);
      }
    });
    shopIds.map((shopId) => {
      fetchGetPartnerById(shopId).then((response) => {
        if (response.status === 200) {
          setPickupPoints((prev) => [
            ...prev,
            {
              ...response.data,
              goods: store.basket.goods
                .filter((good) => good.shopId === shopId)
            },
          ]);
        }
      });
    });
  };

  useEffect(() => {
    const disposer = autorun(() => {
      updateData();
    });
    return () => disposer();
  }, []);

  return (
    <>
      {pickupPoints.length > 0 &&
        pickupPoints.map((item, index) => {
          return (
            <div className="basket-pickup-item" key={index}>
              <h6>{item.name}</h6>
              <div className="d-flex mb-2" style={{ gap: 30 }}>
                <p>
                  <b>{t('basketDeliveryPickup.address')}:</b> {item.pickupPoints[0].address}
                </p>
                <p>
                  <b>{t('basketDeliveryPickup.workingHours')}:</b> {item.pickupPoints[0].workTimes}
                </p>
                <p>
                  <b>{t('basketDeliveryPickup.phone')}:</b> {item.phone}
                </p>
                <a href={item.pickupPoints[0].coords} target="_blank" rel="noopener noreferrer">
                  {t('basketDeliveryPickup.showOnMap')}
                </a>
              </div>

              <ul className="list-group">
                {item.goods.map((good, g_index) => {
                  return (
                    <li className="list-group-item" key={g_index}>
                      <BasketDeliveryPickupGoodItem goodId={good.id}/>
                    </li>
                  );
                })}
              </ul>
            </div>
          );
        })}

      {store.basket.delivery.type !== "pickup" && (
        <Button className="mt-3" onClick={() => handleDeliveryPickup()}>
          {t('basketDeliveryPickup.confirm')}
        </Button>
      )}
    </>
  );
}

export default observer(BasketDeliveryPickup);
