import React, { useEffect, useState } from "react";
import ManageTable from "../../../components/admin/ManageTable";
import {
  fetchParameterGroup,
  fetchParameterGroupCreate,
  fetchParameterGroupDelete,
  fetchParameterGroupUpdate,
  fetchParameterGroups,
} from "../../../data/models/good/good-api";
import CreateParameterGroup from "./CreateParameterGroup";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import ParameterGroupCategoryLinks from "./ParameterGroupCategoryLinks";

function ParameterGroups() {
  const $fetch = {
    list: fetchParameterGroups,
    one: fetchParameterGroup,
    create: fetchParameterGroupCreate,
    update: fetchParameterGroupUpdate,
    delete: fetchParameterGroupDelete,
  };

  const [filter, setFilter] = useState({
    query: "",
  });
  const [list, setList] = useState([]);

  const updateList = () => {
    $fetch.list().then((response) => {
      if (response.status == 200) {
        setList(response.data);
      }
    });
  };

  useEffect(() => {
    updateList();
  }, []);

  return (
    <>
      <h2 className="h4 fw-bold mb-3">Управление группами параметров</h2>
      <CreateParameterGroup
        fetchCreate={$fetch.create}
        updateList={updateList}
      />
      <ManageTable title={"Список производителей"}>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ID</th>
            <th style={{ width: 300 }}>Название</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.length > 0 &&
            list.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>
                  <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-three-dots-vertical"
                          viewBox="0 0 16 16"
                        >
                          <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item><Link to={"params/"+item.id}>К параметрам</Link></Dropdown.Item>
                        <ParameterGroupCategoryLinks parameterGroupId={item.id}/>
                        {/* <Update
                          fetchOne={$fetch.one}
                          fetchUpdate={$fetch.update}
                          id={item.id}
                          updateList={updateList}
                        />
                        <Delete
                          fetchDelete={$fetch.delete}
                          id={item.id}
                          updateList={updateList}
                        /> */}
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </ManageTable>
    </>
  );
}

export default ParameterGroups;
